import * as types from "./actionsTypes";

export function setAccountDatas(data) {
  return {
    type: types.ACCOUNT_SET_DATAS,
    data: data
  }
}
export function delAccountDatas() {
  return {
    type: types.ACCOUNT_DEL_DATAS
  }
}

export function setAccountFilters(filters) {
  return {
    type: types.SET_ACCOUNT_FILTERS,
    data: filters
  }
}

export function resetAccountFilters() {
  return {
    type: types.RESET_ACCOUNT_FILTERS
  }
}