import * as types from "./actionsTypes";

export function updateInfos(data) {
  return {
    type: types.CREATE_ACCOUNT_UPD_INFO,
    data: data
  }
}

export function deleteInfos() {
  return {
    type: types.CREATE_ACCOUNT_DEL_INFO
  }
}
