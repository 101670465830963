import React from 'react'
import images from '../../../ressources/images'

function Maintenance() {
  return (
    <div>
      <img src={images.maintenance} style={{margin:"0 auto",width:"100%"}} alt=''/>
    </div>
  )
}

export default Maintenance
