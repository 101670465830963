import React from "react";
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import './Notifications.scss'
import images from '../../ressources/images'
import { seenNotification } from '../../api/notifications';
import moment from 'moment';

function Notifications(props) {
  const {notifications} = props.notifications

  const setSeenNotification = (id) => {
    seenNotification(id).then(() => {
      props.refresh()
    })
  }

  const getPoints = (notif) => {
    let points;
    switch (notif.type) {
      case "BA_BIRTHDAY":
      case "SPONSORSHIP":
        points = 50;
        break;
      case "POINTS_THOUSAND":
        points = 1000;
        break;
      default:
        points = 0;
        break;
    }
    return points
  }

  return (
    <div className={"notifications_container " + props.class}>
      <header>
        <div className="title">
          NOTIFICATIONS
        </div>
        <div className="close" onClick={props.close}>
          <img src={images.closeCross} alt="Fermer"/>
        </div>
      </header>
      <div className="notifications_wrapper">
        {
          notifications.map(notif => (
            notif.redirectionSuffix ?
            <Link 
              key={notif.id+notif.title}
              to={{
              pathname: `${notif.redirectionSuffix}`,
              state: { transitionName: "slide_in"}
              }}
              className={notif.seen ? "notification seen" : "notification"}
              onClick={() => setSeenNotification(notif.id)}
            >
              <div className="left">
                <img src={process.env.REACT_APP_URL + '/backend' + notif.imagePath} alt=""/>
                {getPoints(notif) > 0 ?
                  <div className="show_points">
                    <span>+{getPoints(notif)}</span>
                  </div>
                : null}
              </div>
              <div className="right">
                <div>
                  <span className="title">{notif.title}</span>&nbsp;
                  <span className="content">{notif.content}</span>
                </div>
                <div className="time">
                  {moment(notif.date*1000).fromNow()}
                </div>
              </div>
            </Link>
            :
            <a 
              key={notif.id+notif.title}
              href={notif.catalogPath}
              className={notif.seen ? "notification seen" : "notification"}
              onClick={() => setSeenNotification(notif.id)}
            >
              <div className="left">
                <img src={process.env.REACT_APP_URL + '/backend' + notif.imagePath} alt=""/>
                {getPoints(notif) > 0 ?
                  <div className="show_points">
                    <span>+{getPoints(notif)}</span>
                  </div>
                : null}
              </div>
              <div className="right">
                <div>
                  <span className="title">{notif.title}</span>&nbsp;
                  <span className="content">{notif.content}</span>
                </div>
                <div className="time">
                  {moment(notif.date*1000).fromNow()}
                </div>
              </div>
            </a>
          ))
        }
      </div>
    </div>
  );

}
//extract data from the redux store
const mapStateToProps = (state) => ({
  notifications: state.notifications
})

export default (connect(mapStateToProps)(Notifications));
