import React, {useState, useEffect} from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import InsButton from '../../InsButton/InsButton'
import images from '../../../ressources/images'

const AddToCartModal = (props) => {
  const [contentHeight, setContentHeight] = useState(window.innerHeight)
  const {t} = props

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }
  
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, [contentHeight])

  let contentStyle = {height: contentHeight - 120}
  
  return (
    <div className="modal_add_to_cart" style={contentStyle}>
      <div className="content">
        {t('shop:product_added')}
      </div>

      <div className="img_cart">
        <img src={images.cart_ok} alt=""/>
      </div>
      
      <Link to='/shop'>
        <InsButton
          type="outline"
          text={t('shop:continue_shopping')}
        />
      </Link>

      <Link to='/shop/cart'>
        <InsButton
          type="white"
          text={t('shop:go_cart')}
        />
      </Link>
    </div>
  )
}

export default withTranslation()(AddToCartModal)
