import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import InsHeader from '../../../../../components/InsHeader/InsHeader'
import InsNavigation from '../../../../../components/InsNavigation/InsNavigation'
import InsCGUBar from '../../../../../components/InsCGUBar/InsCGUBar'
import { allGroups } from '../../../../../api/account'
import InsLoader from '../../../../../components/InsLoader/InsLoader'
import { Link } from 'react-router-dom'
import './ProfileGroups.scss'

function ProfileGroups(props) {
  const [groups, setGroups] = useState([])
  const [load, setLoad] = useState(true)
  const [contentHeight,setContentHeight] = useState(window.innerHeight);

  const getGroups = () => {
    allGroups().then(data => {
      setGroups(data) 
      setLoad(false)
    })
  }

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    getGroups()
  }, [])

  const {t} = props
  return (
    <>
      <InsHeader/>
        <div className="groups">
          <div className="groups_header">
            <div>
              {t('account:my_groups')}
            </div>
          </div>
          <div className="groups_container" style={{height: contentHeight - 220}}>
            {!load ?
              groups.map((group, index)  =>
                <Link to={{pathname: "/profile/groups/"+group.id+"/courses", state: { transitionName: "slide_out", groupName: group.name }}} className="group" key={index}>
                  <div className="square" style={{backgroundImage:'url('+group.picture+')',backgroundPosition:'center',backgroundSize:'cover'}}>
                    <div className="overlay"></div>
                    <div className="content">
                      {group.name}
                    </div>
                  </div>
                </Link>
              )
              :
              <InsLoader/>
            }
          </div>          
          <InsCGUBar/>
         
        </div>
      <InsNavigation/>
    </>
  )
}

export default withTranslation()(ProfileGroups)
