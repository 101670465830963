import * as types from "../actions/actionsTypes";

let initialState = {
  brands:[],
  themes:[]
}

const wallFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        brands: action.data.brands,
        themes: action.data.themes
      }
    case types.RESET_FILTERS:      
      return initialState
    default:
      return initialState
  }
}

export default wallFilterReducer;