import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import slugme from "slugme";
import "./FormationItem.scss";
import { withTranslation } from "react-i18next";
import LazyLoadBackground from "../LazyLoadBackground/LazyLoadBackground";

class FormationItem extends Component {
  static propTypes = {
    brand: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    formation: PropTypes.string,
    completed: PropTypes.bool,
    time: PropTypes.number,
    points: PropTypes.number,
    idFormation: PropTypes.number,
    pourcent: PropTypes.number,
  };

  render() {
    const { brand, image, title, formation, time, points, idFormation, t } =
      this.props;

    let completed = false;
    let pourcent = this.props.pourcent;
    if (100 === pourcent || this.props.nav == "validate") {
      pourcent = 100;
      completed = true;
    }
    const formationSlug = slugme(this.props.title) + "-" + idFormation;

    const loaded = `${process.env.REACT_APP_URL}${image}`;

    let progressBar = {
      width: pourcent + "%",
    };
    return (
      <Link
        to={{
          pathname: "/formations/" + formationSlug,
          state: { transitionName: "slide_in", return: this.props.nav },
        }}
      >
        <div className="formation_item">
          <LazyLoadBackground className="formation_container" src={loaded}>
            <div className="formation_item-top">
              <div
                className={`formation_points ${completed ? "completed" : ""}`}
              >
                +{points}
              </div>
            </div>
            <div className="formation_item-middle">
              <div className="formation_brand">{brand}</div>
              <div className="formation_title">{title}</div>
            </div>
            <div className="formation_item-bottom">
              <div className="formation_type">{formation}</div>
              <div className="time_progress">
                <div className="formation_progress">
                  <div
                    className={`progress ${completed ? "completed" : ""}`}
                    style={progressBar}
                  >
                    <div className="completed_text">
                      {t("account:validate_course")}
                    </div>
                  </div>
                </div>
                <div className="formation_time">
                  <div className="time">{time}</div>
                </div>
              </div>
            </div>
          </LazyLoadBackground>
        </div>
      </Link>
    );
  }
}

export default withTranslation()(FormationItem);
