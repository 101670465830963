import React, { Component } from "react";
import ItemHp from "../../../components/ItemHp/ItemHp";
import InsHeader from "../../../components/InsHeader/InsHeader";
import InsNavigation from "../../../components/InsNavigation/InsNavigation";
import InsCGUBar from "../../../components/InsCGUBar/InsCGUBar";
import Hammer from "hammerjs";

import "./Homepage.scss";
import { newsHp } from "../../../api/news";

export default class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      activeSlide: 0,
      animating: false,
      contentHeight: window.innerHeight,
    };

    this.nbrItems = 0;
    this.itemHeight = 140;
  }

  componentDidMount() {
    this.sliderHeight();
    this.fetchNewsHp();
    window.addEventListener("resize", this.updateDimensions);
  }

  fetchNewsHp() {
    newsHp().then((data) => {
      const dataItems = data.map((elem, index) => ({
        ...elem,
        size: index > 0 ? 0 : 1,
      }));
      this.setState({ items: dataItems }, this.hammerSliderFunc);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    if (this.hammerSlider) {
      this.hammerSlider.destroy();
    }
  }

  updateDimensions = () => {
    this.setState({ contentHeight: window.innerHeight });
  };

  sliderHeight = () => {
    let sliderEl = document.querySelector("#items_list");
    let headerHeight = document.querySelector(".ins_header").offsetHeight;
    let navHeight = document.querySelector(".ins_navigation").offsetHeight;
    sliderEl.style.height =
      window.innerHeight - (headerHeight + navHeight) + "px";
  };

  /**
   * Change la height de l'item au scroll
   */
  heightOnScroll = (index, scrollRatio, nbrItems) => {
    // Position optimale de l'element par rapport a la fenetre et le nombre d'element
    const perfectRatio = index / nbrItems;
    const step = 1 / nbrItems;
    const offsetRatio = Math.abs(scrollRatio - perfectRatio);
    if (offsetRatio < step) {
      return (step - offsetRatio) / step;
    } else {
      return 0;
    }
  };

  /**
   * Utilisation de Hammer pour les actualités en homepage
   */
  hammerSliderFunc = () => {
    const { items } = this.state;
    let sliderEl = document.querySelector("#items_list");

    // Récupération du nombre d'items recu
    this.nbrItems = items.length;
    // Initialisation d'Hammer
    this.hammerSlider = new Hammer.Manager(sliderEl);

    this.hammerSlider.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));

    // Détection du pan sur la liste d'item
    this.hammerSlider.on("pan", (e) => {
      const { activeSlide, animating } = this.state;
      // Déjà une anim on stop
      if (animating) {
        return;
      }

      // Ratio du scroll par rapport à la fenetre
      let translate = Math.min(-activeSlide * this.itemHeight + e.deltaY, 0);
      let scrollRatio =
        Math.abs(translate) / ((this.nbrItems - 1) * this.itemHeight);

      sliderEl.style.transform = "translateY(" + translate + "px)";

      const newItems = items.map((elem, index) => {
        return {
          ...elem,
          size: this.heightOnScroll(index, scrollRatio, this.nbrItems - 1),
        };
      });
      this.setState({ items: newItems });

      if (Math.abs(e.deltaY) >= this.itemHeight) {
        if (e.deltaY > 0) {
          this.goToSlide(activeSlide - 1);
        } else {
          this.goToSlide(activeSlide + 1);
        }

        // stop anim
        return;
      }
      if (e.isFinal) {
        if (e.deltaY < -30) this.goToSlide(activeSlide + 1);
        else if (e.deltaY > 30) this.goToSlide(activeSlide - 1);
        else this.goToSlide(activeSlide);
      }
    });
  };

  goToSlide = (number) => {
    this.setState(
      {
        animating: true,
      },
      () => {
        const sliderEl = document.querySelector("#items_list");

        if (number < 0) {
          number = 0;
        } else if (number > this.nbrItems - 1) {
          number = this.nbrItems - 1;
        }

        this.hammerSlider.stop();
        let scrollRatio = number / (this.nbrItems - 1);
        const newItems = this.state.items.map((elem, index) => {
          return {
            ...elem,
            size: this.heightOnScroll(index, scrollRatio, this.nbrItems - 1),
          };
        });

        this.setState({
          activeSlide: number,
          animating: false,
          items: newItems,
        });
        const translate = -this.itemHeight * number;
        sliderEl.style.transform = "translateY(" + translate + "px)";
      }
    );
  };

  render() {
    const { contentHeight, items } = this.state;
    return (
      <>
        <InsHeader />
        <div className="page_container" style={{ height: contentHeight - 120 }}>
          <div id="items_slider">
            <ul id="items_list" style={{ height: contentHeight - 167 }}>
              {items.map((item, index) => (
                <li key={index} className="item">
                  {item && (
                    <ItemHp
                      background={item.picture}
                      logo={item.brand_logo}
                      type={item.type}
                      title={item.title}
                      brand={item.brand}
                      size={item.size}
                      id={item.id}
                      brandId={item.brand_id}
                      youtubeId={item.youtube_id}
                    />
                  )}
                </li>
              ))}
            </ul>
            <InsCGUBar />
          </div>
        </div>
        <InsNavigation />
      </>
    );
  }
}
