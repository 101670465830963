import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withTranslation } from 'react-i18next';
import Login from "./Login/Login";
import Intro from './Intro/Intro';
import Create from './Create/Create';
import CreateSuccess from './CreateSuccess/CreateSuccess';
import ForgetPassword from './ForgetPassword/ForgetPassword';
import NewPassword from './NewPassword/NewPassword.js';
import ResetPassword from './ResetPassword/ResetPassword';
import InsHeaderConnect from '../../components/InsHeaderConnect/InsHeaderConnect';
import Cgu from '../App/Cgu/Cgu';

class ConnectRouter extends Component {
  renderHeader = () => {
    const { t, location } = this.props
    let header = null
    if(/\/create/ig.test(location.pathname)) {
      // page creation
        header = <InsHeaderConnect title={t('connect:create_account')} />
    }
    
    return header
  }
  
  render() {
    const { match, location } = this.props
    
    return (
      <Fragment>
        {this.renderHeader()}
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            classNames='fade'
            timeout={{ enter: 250, exit: 250 }}
          >
            <section className="route-section">
              <div className="route-section-inner">
                <Switch location={location}>
                  <Route exact path={`${match.path}/login`} component={Login} />
                  <Route exact path={`${match.path}/create`} component={Create} />
                  <Route exact path={`${match.path}conditions-generales-utilisation`} component={Cgu} />
                  <Route exact path={`${match.path}/create-success`} component={CreateSuccess} />
                  <Route exact path={`${match.path}/forget-password`} component={ForgetPassword} />
                  <Route exact path={`${match.path}/new-password`} component={NewPassword} />
                  <Route exact path={`${match.path}/reset-password/:token`} component={ResetPassword} />
                  <Route path={`${match.path}`} component={Intro} />
                </Switch>
              </div>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    )
    }
}

export default withTranslation()(ConnectRouter)