import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
//components
import InsHeader from "../../../../components/InsHeader/InsHeader";
import InsNavigation from "../../../../components/InsNavigation/InsNavigation";
import InsWallHeader from "../../../../components/InsWallHeader/InsWallheader";
import NewPostBtn from "../../../../components/Wall/NewPostBtn";
import SquaredPost from "../../../../components/Wall/List/SquaredPost";
import InsLoader from "../../../../components/InsLoader/InsLoader";
//style
import "./WallList.scss";
//api
import { getAllPosts, getPostWithFilter } from "../../../../api/wall";
import InsCGUBar from "../../../../components/InsCGUBar/InsCGUBar";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDataByLanguage } from "i18next";

function WallList(props) {
  const [wallList, setWallList] = useState([]);
  const [contentHeight, setContentHeight] = useState(window.innerHeight);

  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const [filters, setFilters] = useState(props.wallFiltersList);

  //Détermine le type d'icon à afficher
  function iconOption(type, filesLength) {
    if (filesLength > 1) {
      return "multipleFiles";
    } else {
      if (type === "image") {
        return "image";
      } else if (type === "video") {
        return "video";
      }
    }
    return "error";
  }

  function extensionImg(obj) {
    let extension = obj.split(".");
    extension = extension[extension.length - 1];
    return extension;
  }

  function updateDimensions() {
    setContentHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    setOffset(0);
  }, []);

  //Lance getData au chargement du composant
  useEffect(() => {
    const getData = async () => {
      if (height < contentHeight - 120) {
        await fetchData();
        setHeight(ref.current.clientHeight);
      }
    };

    getData();
  }, [height]);

  const fetchPosts = async (data) => {
    try {
      setHasMore(data.length ? true : false);
      setWallList((prev) => [...prev, ...data]);
      setOffset((prev) => prev + 1);
    } catch (error) {
      console.error(error);
    }
  };

  // Checks if filters were passed, and call the right route.
  const fetchData = async () => {
    let data = null;

    setLoading(true);
    if (filters.brands.length || filters.themes.length) {
      data = await getPostWithFilter({
        ...filters,
        offset,
      });
    } else {
      data = await getAllPosts({ offset });
    }
    setLoading(false);

    fetchPosts(data);
  };

  const loader = (
    <div
      style={{
        display: "flex",
        margin: "10px 0",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <LoadingSpinner loading={loading}></LoadingSpinner>
    </div>
  );

  return (
    <>
      <InsHeader />
      <InsWallHeader />
      <div
        className="content_wall_list"
        style={{ height: contentHeight - 120 }}
        id="scrollable-content-wall-list"
      >
        {wallList ? (
          <div ref={ref}>
            <LazyLoadComponent>
              <InfiniteScroll
                dataLength={wallList.length}
                next={fetchData}
                hasMore={hasMore}
                className="wallList_wrapper"
                scrollableTarget="scrollable-content-wall-list"
                loader={loader}
                style={{
                  overflow: "hidden",
                }}
              >
                {wallList.map((post, i) => (
                  <Link
                    className="squared_wrapper"
                    key={i}
                    to={{
                      pathname: "/wall/p/" + post.id,
                      state: { transitionName: "slide_in" },
                    }}
                  >
                    <SquaredPost
                      type={iconOption(post.files[0].type, post.files.length)}
                      imagePreview={
                        process.env.REACT_APP_URL + post.files[0].name.name_333
                      }
                      videoPreview={
                        process.env.REACT_APP_URL + post.files[0].name.thumbnail
                      }
                      extension={extensionImg(post.files[0].name.name)}
                    />
                  </Link>
                ))}
              </InfiniteScroll>
            </LazyLoadComponent>
          </div>
        ) : (
          <div className="loader_wall">
            <InsLoader />
          </div>
        )}

        <NewPostBtn bottom="7.5rem" position="fixed" />
        <InsCGUBar />
      </div>
      <InsNavigation />
    </>
  );
}
//extract data from the redux store
const mapStateToProps = (state) => ({
  wallFiltersList: state.wallFilters,
});

export default connect(mapStateToProps)(WallList);
