import './ProfilePage.scss'
import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { store } from '../../../../store/configureStore'
import * as accountActions from "../../../../store/actions/accountActions";
import { sendAccount } from '../../../../api/account'
import { withTranslation } from 'react-i18next';
import images from '../../../../../src/ressources/images';
import InsInput from '../../../../components/InsInput/InsInput';
import Validate from '../../../../ressources/validation';
import ValidatePassword from '../../../../components/ValidatePassword/ValidatePassword';
import * as loadImage from 'blueimp-load-image'
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { setTimeout } from 'timers'
import { connect } from 'react-redux'
registerLocale('fr', fr);

export class ProfilePageEdit extends Component {
  constructor(props) {
    super(props)

    const date = new Date()
    this.max_year = new Date(date.getFullYear()-16, date.getMonth(), date.getDate());
    this.min_year = new Date(date.getFullYear()-100, date.getMonth(), date.getDate());

    this.state = {
        profileDatas: {password:''},
        passwordError: '',
        passwordConfirmError: '',
        birthdate: '',
        birthdateError: '',
        editPassword: false,
        preview: null,
        cpError: false,
        phoneError: false,
        accountUpdated: false
    }
  }

  componentDidMount() {
    this.getAccount()
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  };
  /**
   * Récupere les informations du compte
   */
  getAccount() {
    const {account} = this.props
    this.setState({
      profileDatas: {...account, password: ''}
    })
  }

  /**
   * Envoie les informations du compte (informations stockées dans le state)
   */
  sendAccountDatas = (e) => {
    e.preventDefault();
    const { cpError, phoneError, profileDatas } = this.state
    if (!cpError && !phoneError ){
      sendAccount(profileDatas).then(() => {
        this.accountUpdated()
        store.dispatch(accountActions.setAccountDatas(profileDatas))
      }).catch(error => {
        console.log(error);      
      });
    }
  }

  accountUpdated = () => {
    this.setState({accountUpdated: true})
    setTimeout(() => {
      this.setState({accountUpdated: false})      
    }, 3000);
  }

  /**
   * Change state datas input text
   */
  handleChange = (params) => (event) => {
    const value = event.target.value
    
    this.setState(prevState => ({
      profileDatas: {                   
          ...prevState.profileDatas,    
          [params]: value
      }
    }))
  }

  loadImageUrl = (input) => {
    loadImage(
      input.files[0],
      (canvas) => {

        let base64 = '';

        if (canvas.tagName === 'IMG') {
          const canvasElement = document.createElement('canvas');
          const ctx = canvasElement.getContext('2d');
          canvasElement.width = canvas.naturalWidth;
          canvasElement.height = canvas.naturalHeight;
          ctx.drawImage(canvas, 0, 0);
          base64 = canvasElement.toDataURL();
        } else {
          base64 = canvas.toDataURL();
        }

        this.setState(prevState => ({
          profileDatas: {
              ...prevState.profileDatas,    
              picture: base64
          }
        }))
      },
      { 
        orientation: true,
        maxWidth: 200,
        maxHeight: 200
      }
    );
  }

  /**
   * Change state datas input file (img)
   */
  onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      this.loadImageUrl(event.target);
    }
    // Formdata avec un append 'avatar' de event.target.files pour une requête post avec le fichier directement (evol)
   }

  _handleBirthdate = (value) => {
    if(value > this.max_year) {
      value = this.max_year
    } else if (value < this.min_year) {
      value = this.min_year
    }

    this.setState(prevState => ({
      profileDatas: {
          ...prevState.profileDatas,
          birthdate: this._convertDatetoString(value)
      }
    }))
  }

  _convertDatetoString = (date) => {
    if(!date){
      return ''
    }
    const pad = (s) => ((s < 10) ? '0' + s : s )
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
  }

  _convertStringToDate = date => {
    const parts = date.split('/');
    return new Date(parts[2], parts[1] - 1, parts[0]); 
  }

  _phoneValidation = () => {
    const {profileDatas} = this.state
    profileDatas.phone.length < 10 ? this.setState({phoneError: true}) : this.setState({phoneError: false})
  }

  _cpValidation = () => {
    const {profileDatas} = this.state
    profileDatas.postal_code.length < 5 ? this.setState({cpError: true}) : this.setState({cpError: false})
  }

  /**
  * Valide le champ password
  */
  _validatePassword = () => {
    const error = Validate('password', this.state.profileDatas.password);
    this.setState({
      passwordError: error
    })
    return !error;
  }

  /**
   * Valide la confirmation du champ password
   */
  _validatePasswordConfirm = () => {
    const { password, passwordConfirm } = this.state.profileDatas
    const { t } = this.props
    if( password !== passwordConfirm && !Validate('password', this.state.profileDatas.password)) {
      this.setState({
        passwordConfirmError: t('error:validation.password.not_same')
      })
    } else {
      this.setState({
        passwordConfirmError: ''
      })
    }
    return (password === passwordConfirm);
  }

  _editPassword = () => {
    this.setState({editPassword: !this.state.editPassword})
  }

  editPicture = () => {
    this.refs['edit_picture'].click()
  }

  render() {
    const {t} = this.props
    const { accountUpdated, cpError, editPassword, phoneError, profileDatas,  passwordConfirmError, passwordError, preview } = this.state
    const {birthdate, firstname, lastname, password, picture} = profileDatas;

    return (
      <div className="bg_white">
        <form onSubmit={this.sendAccountDatas} className="profile_container">
            <div className="column_center">
              <Link 
                to={{ pathname: '/profile', state: { transitionName: "slide_out" }}} 
                className="back_link"
              >
              </Link>
              <div onClick={this.editPicture}className="profil_picture">
                {preview ?
                  <canvas></canvas>
                :
                  <img src={picture ? picture : images.icon_user} alt="" />
                }
              </div>
              <div className="input_file">
                <label htmlFor="file" className="label-file">Modifier la photo de profil</label>
                <input ref='edit_picture' type="file" onChange={this.onImageChange} />
              </div>
            </div>
          <div className="send_wrapper">
            <div className="can_edit">
              <InsInput     
                name="firstname"        
                type="text"
                placeholder={t("account:firstname")}
                value={firstname ? firstname : ''}
                disabled
              />
              <InsInput   
                name="lastname"           
                type="text"
                placeholder={t("account:name")}
                value={lastname ? lastname : ''}
                disabled
              />
              <DatePicker
                selected={(birthdate) ? this._convertStringToDate(birthdate) : ''}
                onChange={this._handleBirthdate}
                placeholderText={t("connect:birthdate")}
                minDate={this.min_year}
                maxDate={this.max_year}
                dateFormat="dd/MM/yyyy"
                className="ins_input ins_datePicker"
                locale="fr"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperPlacement="top-center"
              />
              <InsInput    
                name="address"          
                type="text"
                placeholder={t("account:address")}
                value={profileDatas.address ? profileDatas.address : ''}
                onChange={this.handleChange('address')}
                maxLength="115"
              />
              <InsInput    
                name="city"          
                type="text"
                placeholder={t("account:city")}
                value={profileDatas.city ? profileDatas.city : ''}
                onChange={this.handleChange('city')}
              />
              <InsInput    
                name="postal_code"
                type = "number"
                onInput={(e)=> {e.target.value = e.target.value.slice(0,5)}}
                maxLength="5"
                placeholder={t("account:postal_code")}
                value={profileDatas.postal_code ? profileDatas.postal_code : ''}
                onChange={this.handleChange('postal_code')}
                onBlur={this._cpValidation}
              />
              {cpError && 
                <div className={cpError ? 'phone_error mb20' : 'phone_error'}>{t('error:cp_error')}</div>
              }

              <InsInput    
                className={phoneError ? 'mb0' : 'mb20'}
                name="phone"          
                type="tel"
                maxLength="10"
                placeholder={t("account:phone")}
                value={profileDatas.phone ? profileDatas.phone : ''}
                onChange={this.handleChange('phone')}
                onBlur={this._phoneValidation}
              />
              {phoneError && 
                <div className={phoneError ? 'phone_error mb20' : 'phone_error'}>{t('error:phone_number_error')}</div>
              }

              {!editPassword &&
                <div className="edit_password" onClick={() => this._editPassword()}>
                  Modifier votre mot de passe
                </div>
              }
              {editPassword &&              
                <div>
                  <InsInput 
                    name="password"
                    type="password"
                    placeholder={t("connect:password")}
                    onChange={this.handleChange('password')}
                    error={passwordError}
                  />
                  <InsInput 
                    name="passwordConfirm"
                    type="password"
                    placeholder={t("connect:password_confirm")}
                    onChange={this.handleChange('passwordConfirm')}
                    onBlur={this._validatePasswordConfirm}
                    error={passwordConfirmError}
                  />
                  <div className="profil-validate-password">
                    <ValidatePassword 
                      value={password} 
                      activeColor="#32CD32"
                    >
                      {t("connect:validate_pwd.help")}
                    </ValidatePassword>
                  </div>
                </div>
              }
              {/* <input type="text" placeholder="Date de naissance" value='07/10/90' /> Infos manquante */}
            </div>

            <div className="cant_edit">
              <InsInput    
                name="shop_name"          
                type="text"
                placeholder={t("account:shop_name")}
                value={profileDatas.store ? profileDatas.store['name'] : ''}
                disabled
              />
              <InsInput    
                name="city"          
                type="text"
                placeholder={t("account:city")}
                value={profileDatas.store ? profileDatas.store['city'] : ''}
                disabled
              />
              <InsInput    
                name="address"          
                type="text"
                placeholder={t("account:address")}
                value={profileDatas.store ? profileDatas.store['address'] : ''}
                disabled
              />
            </div>
          </div>

          <div className="last_form">
            {accountUpdated &&
              <div className="account_updated">
                {t('account:account_updated')}
              </div>
            }
            <input className="register" type="submit" disabled={phoneError || cpError ? true : false} value="Enregistrer" />
            <input className="reset" type="reset" value="Annuler" onClick={() => this.props.history.goBack()}/>
          </div>
        
        </form>
      </div>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(ProfilePageEdit)))