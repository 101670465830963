import './Cgu.scss'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

export class Cgu extends Component {

  /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history, location } = this.props;
    if(location.state && location.state.goBack){
      history.goBack();
    } else {
      history.replace('/');
    }
  }

  render() {
    
    return (
      <div className="cgu">
        <div className='back_link' onClick={this._goBack}></div>

        <div className="text_center">
          <div className="title">
            CONDITIONS D’UTILISATION DU SITE 
          </div>
          <a href="http://www.wearelorealluxe.com">www.wearelorealluxe.com</a> <br/>
          En vigueur à compter du 2 septembre 2019 <br/>
        </div>
          
        <div>
          Nous vous souhaitons la bienvenue sur ce site dédié aux marques L’Oréal Luxe (ci-après le « Site »). 
        </div>
        
        <div>
          Nous vous invitons à lire attentivement les présentes conditions d’utilisation qui régissent la navigation sur ce Site (ci-après les « Conditions d’Utilisation »). 
          En utilisant le Site vous acceptez sans réserve les présentes Conditions d’Utilisation.
        </div>
        
        <div>
          Pour toute demande relative à votre utilisation du Site, vous pouvez Nous contacter à l’adresse suivante : <a href="mailto:contact@weareloreallluxe.com">contact@weareloreallluxe.com</a>. 
        </div>
        
        
        <div className="title">
          MENTIONS LEGALES
        </div>
        
        <div>
          <div className="underline">
            URL du Site
          </div>: <a href="http://www.wearelorealluxe.com">http://www.wearelorealluxe.com</a>
        </div>
        
        <div>
          <div className="underline">
            Contact
          </div> : Courrier électronique : <a href="mailto:contact@wearelorealluxe.com">contact@wearelorealluxe.com</a> - Numéro de téléphone : <a href="tel:0157771934">0157771934</a>
        </div>
        
        <div>
          <div className="underline">
            Directeur de la Publication
          </div> : EL MILI Tamim – GENERAL MANAGER L’Oréal Luxe France 
        </div>
        
        <div>
          <div className="underline">
            Editeur
          </div>: société L’Oréal Produits de Luxe France, société en nom collectif au capital de 84 000 euros, dont le siège social est sis 14, rue Royale à PARIS (75008) inscrite au Registre du Commerce et des Sociétés de PARIS sous le numéro 314 428 186 agissant au nom et pour le compte de ses marques GIORGIO ARMANI Parfums, BIOTHERM, YVES SAINT LAURENT BEAUTÉ, LANCÔME, VIKTOR&ROLF Parfums, DIESEL Parfums, CACHAREL Parfums, VALENTINO, MAISON MARTIN MARGIELA, URBAN DECAY, IT COSMETICS (ci-après la « Société Editrice » ou « Nous »). 
        </div>
        
        <div>
          N° de TVA intracommunautaire : FR19314428186
          <div className="underline">
            Hébergeur
          </div> : société Mediactive Network, SASU au capital de 200 000,00 euros, dont le siège social est 3 Cité de Paradis à Paris, inscrite au Registre du Commerce et des Sociétés de Paris sous le numéro 393 078 647. <br/>
          Numéro de téléphone : 01 55 28 80 60
        </div>
        
        
        <div className="subtitle">
          1. ACCES AU SITE
        </div>
        
        <div>
          Pour accéder et utiliser ce Site, vous devez être 
          <ul>
            <li>
              âgé(e) de 16 ans ou plus]
            </li>
            <li>
              un professionnel de la beauté / un revendeur / un(e) conseillèr(e) de beauté travaillant pour un partenaire commercial de L’Oréal Luxe, ou
            </li>
            <li>
              un collaborateur du Groupe L’Oréal
            </li>
            <li>
              et disposer d’une adresse de courrier électronique valide
            </li>
          </ul>
        </div>
        
        <div>
          Vous pouvez accéder librement et gratuitement au Site, sans inscription ni création de compte préalable.
        </div>
        
        <div>
          L’accès au Site et/ou à certaines de ses Rubriques peut nécessiter l’utilisation de codes d'accès personnels. Dans ce cas, il vous appartient de prendre les mesures propres à assurer le secret de ces codes. Vous pouvez bien entendu les modifier à tout moment. Toutefois, le nombre de tentatives d'accès au Site et/ou à certaines de ses Rubriques peut être limité afin de prévenir un usage frauduleux desdits codes. Nous vous invitons à nous informer de toute utilisation frauduleuse dont vous pourriez éventuellement avoir connaissance. Par ailleurs, en cas de non-respect des règles décrites dans les présentes Conditions d’Utilisation, Nous nous réservons le droit de suspendre votre accès.
        </div>
        
        <div>
          Les frais d’accès et d’utilisation du réseau de télécommunication restent néanmoins à votre charge. 
        </div>
        <div className="subtitle">
          2. PROPRIETE INTELLECTUELLE
        </div>
        
        <div className="index">
          a. Droits de propriété intellectuelle
        </div>
        
        <div>
          Le développement de ce Site a impliqué des investissements importants. Le Site et chacun des éléments qui le composent (tels que marques, images, textes, vidéos, etc.) sont protégés au titre de la propriété intellectuelle. Toute utilisation, reproduction ou représentation de du Site (en tout ou partie), sur quelque support que ce soit, à d’autres fins, et notamment commerciales, est interdite. 
        </div>
        
        <div>
          Nous pouvons être amenés à mettre à votre disposition sur ce Site des contenus que vous êtes autorisés à télécharger (ci-après le(s) « Contenu(s) Téléchargeable(s) »). Nous vous concédons, pour vos seuls besoins personnels et privés, à titre gracieux et pour la durée légale de protection des droits de propriété intellectuelle telle que définie par les législations françaises et étrangères et les conventions internationales, un droit non exclusif et non transférable d’utilisation du Contenu Téléchargeable. Toute reproduction, représentation, modification ou distribution du Contenu Téléchargeable est interdite. En téléchargeant ou en utilisant ces Contenus Téléchargeables, vous vous engagez à les utiliser conformément aux présentes Conditions d’Utilisation. 
        </div>
        
        
        <div className="index">
          b. Droit des tiers
        </div>
        
        <div>
          Nous vous rappelons que vous devez impérativement obtenir/vous faire céder l’ensemble des autorisations et droits nécessaires auprès des différents ayants droit concernés par les éventuels contenus que vous souhaiteriez publier sur le Site, en ce compris l’ensemble des droits de propriété intellectuelle et/ou des droits de propriété littéraire, artistique et/ou industrielle, ainsi que les droits de la personnalité (et notamment droit à l’image), afin que vous puissiez exploiter paisiblement lesdits contenus. A titre d’exemple, vous devez impérativement obtenir/vous faire céder les droits sur les contenus, et notamment les droits d’auteur sur les photographies, ainsi que sur l’ensemble des éléments apparaissant dans lesdits contenus, comme par exemple des éléments d’architecture, de design, des créations publicitaires, ou encore des créations vestimentaires. 
        </div>
        
        <div className="index">
          c. Contenus Utilisateurs
        </div>
        
        <div>
          Nous pouvons être amenés à mettre à disposition sur ce Site un espace destiné à accueillir des contenus des utilisateurs, comme par exemple des textes, photos, vidéos, avis, etc. (ci-après les « Contenus Utilisateurs »). 
        </div>
        
        <div>
          En publiant un Contenu Utilisateur sur le Site, vous Nous accordez par la présente une licence gratuite, irrévocable, non exclusive, mondiale et pour toute la durée des droits de propriété intellectuelle, littéraire et artistique et/ou industrielle, et notamment du droit d’auteur telle que définie par les législations françaises et étrangères et les conventions internationales (y compris toutes règlementations supplétives ou modificatives ultérieures), de reproduire, représenter, utiliser, copier, modifier, adapter, traduire, créer des œuvres dérivées, intégrer dans d'autres œuvres, distribuer ce Contenu Utilisateur (en tout ou en partie). 
        </div>
        
        <div>
          Cette utilisation est autorisée à toutes fins de communication interne ou externe, corporate ou financière, de publicité, ainsi qu’à toutes fins de relations publiques, et à des fins historiques ou d’archives, du Groupe L’OREAL ou de ses filiales, de ses produits et/ou de ses marques, et notamment sur les supports suivants :
          <ul>
            <li>
              Affichages tous format, en quantité illimitée,
            </li>
            <li>
              Presse, nombre de parutions illimitées, 
            </li>
            Edition, nombre de publications illimitées, notamment édition pour communication interne, y compris force de
            <li>
              vente et réseau de distribution (grossistes, détaillants, agents, etc..), événementiel, affichettes pour congrès, salons, stands…; communication BtoB, dans la presse professionnelle, pour un nombre de parutions et/ou des quantités illimités,
            </li>
            <li>
              Edition électronique, informatique, numérique, multimédia, Internet et Intranets, tous sites (quel que soit le site et/ou le support, incluant les sites dits « réseaux sociaux » comme par exemple Facebook, Twitter, Youtube ou encore Dailymotion) nombre d’insertions et diffusion illimitées,
            </li>
            <li>
              sur tout autre support publicitaire (notamment par voie de publicité sur lieu de vente et sur les produits des marques du Groupe L’OREAL) (Ci-après le(s) « Support(s) »).
            </li>
          </ul>
        </div>
        
        <div>
          Vous êtes informés que ces réseaux sociaux sont des plateformes appartenant à des tiers et qu’en conséquence la diffusion et l’utilisation du Contenu Utilisateur sur ces réseaux sociaux sont gouvernées par les conditions d’utilisation établies par ces tiers. Ainsi, Nous ne pouvons être tenus responsables de toute utilisation du Contenu par Nous-mêmes ou par des tiers en conformité avec les conditions d’utilisation établies par les réseaux sociaux, et notamment, en termes de périmètre de droits concédés, de durée des droits et de suppression des Contenus. Vous ferez votre affaire de toute réclamation de tiers portant sur l’utilisation du Contenu en conformité avec les conditions d’utilisation établies par les réseaux sociaux. 
        </div>
        
        <div>
          Par ailleurs, Nous vous rappelons notamment que tout Contenu est susceptible d'être référencé sur un moteur de recherche et, donc, d'être consulté par un public extérieur à celui du Site. 
        </div>
        
        <div>
          Cette autorisation Nous donne la possibilité d’adapter votre Contenu et/ou d’apporter à la fixation initiale toute précision que Nous jugerions utile dès lors que ledit Contenu n’altère pas votre image ou vos propos. 
        </div>
        
        <div>
          En outre, l’utilisation du Contenu Utilisateur peut être accompagnée de certaines informations dépersonnalisées telle que votre ville, votre pays ou votre âge, et/ou, le cas échéant si vous l’avez expressément autorisé pour ce faire, d’informations permettant votre identification telles que votre prénom, ou votre pseudo. 
        </div>
        
        <div>
          Les Contenus Utilisateurs que vous publiez sur ce Site relèvent de votre choix et de votre responsabilité exclusive. Toutefois, Nous vous rappelons que ces Contenus Utilisateurs ne devront pas être contraires à la législation en vigueur, aux bonnes mœurs et aux principes énoncés aux présentes. A ce titre, Nous nous réservons le droit de retirer à tout moment tout Contenu Utilisateur qui ne respecte pas les présentes Conditions d’Utilisation et notamment la Charte de Bonne Conduite. 
        </div>
        
        <div>
          Par ailleurs, si vous accédez à un Contenu Utilisateur créé par un autre utilisateur, vous devez respecter les droits de cet utilisateur et notamment veiller à ne pas reproduire et diffuser ledit Contenu publié sur d'autres supports sans l'accord préalable de l’utilisateur concerné.
        </div>
        
        <div className="subtitle">
          3. CHARTE DE BONNE CONDUITE
        </div>
        
        <div>
          Nous défendons des valeurs de tolérance et de respect d’autrui. 
        </div>
        
        <div>
          Pour cette raison, en utilisant ce Site, vous vous interdisez de :
          
          <ul>
            <li>
              véhiculer des propos racistes, violents, xénophobes, malveillants, vulgaires, obscènes ou encore illicites,
            </li>
            
            <li>
              diffuser un contenu préjudiciable, diffamatoire, non autorisé, malveillant, portant atteinte à la vie privée ou aux droits à l’image, incitant à la violence, à la haine raciale ou ethnique, ou un contenu constituant un outrage aux bonnes mœurs ou une incitation à la réalisation de certains délits et crimes ;
            </li>
            
            <li>
              utiliser le Site pour faire de la politique, de la propagande ou du prosélytisme ;
            </li>
            
            <li>
              publier des contenus publicitaires ou promotionnels  pour des produits et/ou services concurrents de la(ou les) marque(s) présentée(s) sur le Site ;
            </li>
            
            <li>
              détourner le Site de sa finalité, notamment en l’utilisant comme un espace de rencontre ;
            </li>
            
            <li>
              diffuser des informations permettant, directement ou indirectement l’identification nominative et précise d’une personne physique sans son consentement exprès et préalable, telles que notamment le nom de famille, l’adresse postale, l’adresse de courrier électronique, le numéro de téléphone ;
            </li>
            
            <li>
              diffuser des informations ou contenus susceptibles de heurter la sensibilité des plus jeunes ;
            </li>
            
            <li>
              intimider ou harceler autrui ;
            </li>
            
            <li>
              mener des activités illégales, notamment portant atteinte aux titulaires de droits sur des logiciels, marques, photographies, images, textes, vidéos etc. ;
            </li>
            
            <li>
              diffuser des contenus (notamment des photographies et des vidéos) représentant des mineurs. 
            </li>
          </ul>
        </div>
        
        <div>
          Si vous découvrez un Contenu Utilisateur faisant l'apologie des crimes contre l'humanité, incitant à la haine raciale et/ou à la violence ou concernant la pornographie enfantine, vous devez Nous en informer immédiatement à l’adresse de courrier électronique suivante : contact@wearelorealluxe.com, soit en adressant un courrier circonstancié à l’adresse suivante : WE ARE L’OREAL LUXE L’Oréal Luxe France 30 rue d’Alsace 92300 Levallois-Perret, en indiquant dans votre courriel/courrier la date à laquelle vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et l’identifiant de son auteur. 
        </div>
        
        <div>
          Si vous estimez qu’un Contenu Utilisateur porte atteinte aux principes énoncés ci-dessus et à vos droits ou aux droits d’un tiers (par exemple contrefaçon, insulte, atteinte à la vie privée), vous pouvez le notifier à l’adresse de courrier électronique suivante : contact@wearelorealluxe.com, soit en adressant un courrier circonstancié à l’adresse suivante : WE ARE L’OREAL LUXE L’Oréal Luxe France 30 rue d’Alsace 92300 Levallois-Perret, en indiquant dans votre courriel/courrier la date à laquelle vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et l’identifiant de son auteur. 
        </div>
         
        <div>
          Conformément aux dispositions de l’article 6-I-5 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, la notification devra contenir :
          <ul>
            <li>
              la date de la notification ;
            </li>
            <li>
              pour une personne physique : nom, prénoms, profession, domicile, nationalité, date et lieu de naissance ; 
            </li>
            <li>
              le nom et le domicile du destinataire ou, s'il s'agit d'une personne morale, sa dénomination et son siège social ;
            </li>
            <li>
              la description des faits litigieux et leur localisation précise (ex : lien URL du contenu litigieux) ; 
            </li>
            <li>
              les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits ;
            </li>
            <li>
              la copie de la correspondance adressée à l'auteur ou à l'éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l'auteur ou l'éditeur n'a pu être contacté.
            </li>
          </ul>
        </div>
        
        <div>
          Toute notification incomplète sera susceptible de ne pas être traitée. ATTENTION : Le fait, pour toute personne, de présenter un contenu ou une activité comme étant illicite dans le but d'en obtenir le retrait ou d'en faire cesser la diffusion en lui présentant des informations fausses ou inexactes est puni d'une peine d'un an d'emprisonnement et de 15 000 euros d'amende.
        </div>
        <div className="subtitle">
          4. INFORMATIONS CONTENUES SUR LE SITE
        </div>
        
        <div className="index">
          a. Dispositions générales
        </div>
        
        <div>
          Nous vous rappelons que des inexactitudes ou omissions peuvent apparaître dans les informations disponibles sur ce Site notamment du fait de tiers. Nous nous engageons à retirer les inexactitudes ou à compléter ces informations sur le Site dès que possible.
        </div>
        
        <div className="index">
          b. Informations sur les produits et services
        </div>
        
        <div>
          Les produits et services qui vous sont présentés sur ce Site ne constituent pas une offre de vente mais une présentation générale de la gamme de produits et services que Nous distribuons dans le pays dans lequel ce Site est diffusé. 
        </div>
        
        <div className="index">
          c. Conseils et outils de diagnostic
        </div>
        
        Les conseils dispensés sur ce Site et/ou les outils de diagnostic éventuellement disponibles sont de simples simulations destinés à obtenir des conseils d'ordre cosmétologique. <br/>
        <div>
          Les informations qu'ils permettent de délivrer sont données à titre strictement indicatif et ne peuvent en aucun cas remplacer un diagnostic médical ou une consultation clinique, ni se substituer à un traitement médical. 
        </div>
        <div>
          En conséquence, Nous ne pouvons vous garantir une pleine satisfaction concernant les conseils résultant de l’utilisation de ces outils et n’assumons aucune responsabilité quant à l’utilisation que vous pourriez en faire. 
        </div>
        
        <div>
          Pour toute information complémentaire ou en cas de doute, Nous vous recommandons de consulter votre médecin.
        </div>
        
        <div className="index">
          d. Liens hypertextes
        </div>
        
        <div>
          Les liens hypertextes mis en place sur ce Site peuvent vous conduire sur des sites Internet édités par des tiers dont Nous ne maîtrisons pas le contenu. En conséquence, et dans la mesure où les liens hypertextes ont été inclus sur ce Site uniquement afin de faciliter votre navigation sur Internet, la consultation de sites de tiers relèvera de votre choix et de votre responsabilité exclusive. 
        </div>
        
        <div className="subtitle">
          5. DONNEES A CARACTERE PERSONNEL
        </div>
        
        <div>
          Nous pouvons être amenés à collecter des données à caractère personnel vous concernant, notamment lorsque vous  (i) vous souscrivez à un service, (ii) téléchargez un Contenu Téléchargeable, (iii) vous authentifiez, (iv) vous inscrivez à un jeu/concours, (v) Nous faites parvenir un courrier électronique, (vi) répondez à un sondage ou à une étude. 
        </div>
        
        <div>
          Vos données à caractère personnel sont enregistrées dans un fichier informatisé par L’Oréal Luxe pour 
        </div>
        <div>
          Gérer les concours, promotions, enquêtes ou compétitions auxquels vous participez ; 
        </div>
        <div>
          Répondre à vos questions et interagir avec vous de toute autre manière ;  
        </div>
        <div>
          Vous proposer un programme de fidélité ; 
        </div>
        <div>
          Vous permettre de gérer vos préférences ; 
        </div>
        <div>
          Vous adresser des communications commerciales (sauf si vous vous y êtes opposé) qui peuvent être adaptées à votre « profil » (par exemple en utilisant les données à caractère personnel dont nous avons connaissance vous concernant ainsi que vos préférences) ; 
        </div>
        <div>
          Contrôler et améliorer nos sites Internet et nos applications ; 
        </div>
        <div>
          Procéder à des analyses d’audience ou réaliser des statistiques ; 
        </div>
        <div>
          Sécuriser nos sites Internet/applications et assurer notre et votre protection contre la fraude. 
        </div>
        <div>
          Tenir à jour une liste de suppression si vous avez demandé à ne pas être contacté ; 
        </div>
        <div>
          Veiller au bon fonctionnement de notre site Internet/applications : 
        </div>
        <div>
          Diffusion appropriée de contenus ; 
        </div>
        <div>
          Création et conservation en mémoire d’un panier d’achat ; 
        </div>
        <div>
          Création et conservation en mémoire de vos données de connexion ; 
        </div>
        <div>
          Personnalisation de l’interface telle que la langue utilisée ; 
        </div>
        <div>
          Paramétrage de votre appareil, y compris la résolution de votre écran, etc. ;  
        </div>
        <div>
          Garantir la protection et la sécurité du site Internet/de l’application, et vous protéger contre toute fraude ou utilisation frauduleuse de nos sites Internet ou de nos services, par exemple, en procédant à des opérations de connexion. 
        </div>
        <div>
          Réaliser des statistiques afin : 
        </div>
        <div>
          d’éviter que les visiteurs soient enregistrés deux fois ; 
        </div>
        <div>
          de comprendre de quelle façon vous avez découvert nos sites Internet/applications. 
        </div>
        <div>
          Remplir les tâches que vous nous avez demandées comme, par exemple, gérer votre participation à des concours, des programmes de récompense, des jeux et des enquêtes, y compris la prise en compte de votre avis et de vos suggestions ; 
        </div>
        <div>
          Suivre votre parcours de formation L’Oréal Luxe. 
        </div>
        
        <div>
          Elles sont conservées pendant 3 ans et sont destinées à L’Oréal Luxe France agissant au nom et pour le compte de ses marques GIORGIO ARMANI Parfums, BIOTHERM, YVES SAINT LAURENT BEAUTÉ, LANCÔME, VIKTOR&ROLF Parfums, DIESEL Parfums, CACHAREL Parfums, MAISON MARTIN MARGIELA, VALENTINO, IT COSMETICS, URBAN DECAY. 
        </div>
        
        <div>
          Conformément aux dispositions règlementaires et législatives en vigueur, vous bénéficiez d'un droit d'accès, de rectification et de suppression des données qui vous concernent. Vous pouvez exercer ce droit à tout moment en adressant un courrier, accompagné d’une photocopie d’un justificatif d’identité, à l’adresse suivante : 
        </div>
        <div>
          AGENCE COSMIC - 6 rue Royale 75008 PARIS. 
        </div>
        
        <div>
          Pour plus d’informations sur le traitement de vos données à caractère personnel, Nous vous invitons à consulter notre Politique relative aux données à caractère personnel accessible ici.
        </div>
        <div className="subtitle">
          6. COOKIES
        </div> 
        
        <div>
          Les cookies sont de petits fichiers qui sont déposés sur votre dispositif lors de votre navigation sur le Site (comme par exemple les pages que vous avez consultées, la date et l'heure de la consultation, etc.) et qui pourront être lus lors de vos visites sur ce même Site (ci-après les « Cookies ») 
        </div>
        
        <div>
          Pour plus d’informations sur l’utilisation des Cookies sur le Site, Nous vous invitons à consulter notre tableau sur les Cookies, accessible ici. 
        </div>
        
        <div className="subtitle">
          7. MODIFICATION DU SITE ET DES CONDITIONS D’UTILISATION
        </div>
        
        <div>
          Nous pouvons être amenés à modifier les contenus et informations inclus dans ce Site ainsi que les présentes Conditions d’Utilisation, notamment afin de respecter toute nouvelle législation et/ou réglementation applicable et/ou afin d’améliorer le Site. 
        </div>
        
        <div>
          Toute modification sera portée à votre connaissance sur le Site avant sa prise d’effet dans le cadre des présentes Conditions d’Utilisation. Sauf si la modification implique votre acceptation expresse, le fait que vous continuiez à utiliser le Site implique que vous acceptez les nouvelles Conditions d’Utilisation.
        </div>

        <button alt='' class="optanon-show-settings">Paramètres des cookies</button><br/>

        <div id="optanon-cookie-policy"></div>
        
        <div className="subtitle">
          8. CREDITS
        </div>
        
        <div>
          Le Site a été développé pour la Société Editrice par COSMIC, SASU au capital de 362 754,00 euros, dont le siège social est sis 6 rue Royale, 75008 PARIS, immatriculée au RCS de Paris sous le numéro 478 147 366, adresse mail : BONJOUR@AGENCECOSMIC.COM numéro de téléphone : +33 (0)1 44 58 58 58
        </div>
        
        
        <div className="subtitle">
          9.   AVERTISSEMENT
        </div>
        
        <div>
          Nous nous efforçons de maintenir le Site et le Contenu Téléchargeable accessibles à tout moment. En revanche, nous ne pouvons garantir la disponibilité et l’accessibilité permanente du Site. En effet, nous pouvons nous trouver dans l’obligation de suspendre momentanément l'accès partiel ou total au Site notamment pour des raisons techniques de maintenance. 
        </div>
        
        <div>
          Il est précisé également que le réseau Internet et les systèmes informatiques et de télécommunications ne sont pas exempts d’erreurs et que des interruptions et pannes peuvent survenir. Nous ne pouvons fournir aucune garantie à cet égard et ne saurait dès lors être responsable pour tous dommages inhérents auxdites utilisations du réseau Internet et des systèmes informatiques et de télécommunications, notamment sans que cette liste ne soit limitative :
          <ul>
            <li>
              la mauvaise transmission et/ou réception de toute donnée et/ou information sur Internet ;
            </li>
            <li>
              une intrusion extérieure ou la présence de virus informatique ;
            </li>
            <li>
              la défaillance de tout matériel de réception ou des lignes de communication ; et
            </li>
            <li>
              tout autre dysfonctionnement du réseau Internet empêchant le bon fonctionnement de du Site.
            </li>
          </ul>
        </div>
        <div>
          Enfin, notre responsabilité ne pourra être engagée qu’au titre de dommages directs, à l’exclusion de tous autres dommages ou préjudices de quelque nature que ce soit. En particulier, les dommages indirects liés tels que, de manière non exhaustive, la perte de profits ou de revenus ou la perte de clientèle.
        </div>
        <div className="subtitle">
          10.   DROIT APPLICABLE ET LITIGES 
        </div>
        
        <div>
          Les Conditions d’Utilisation sont soumises à la loi française. 
        </div>
        
        Pour tout problème, merci de prendre contact avec notre Service Client : 
        
        <div className="text_center">
          <a href="mailto:contact@wearelorealluxe.com">contact@wearelorealluxe.com</a>
          <a href="tel:0157771934">0157771934</a>
          De 9h à 19h du lundi au vendredi
        </div>
        <div>
          Conformément aux dispositions du Code de la consommation concernant le règlement amiable des litiges, L’Oréal adhère aux services du Centre de Médiation et d’Arbitrage de Paris (ci-après le « Médiateur ») dont les coordonnées sont les suivantes : 39 avenue Franklin D. Roosevelt - 75008 Paris - <a href="http://www.cmap.fr">http://www.cmap.fr</a>. 
        </div>
        
        <div>
          En cas de réclamation écrite non résolue par notre Service Consommateurs, vous pouvez saisir gratuitement le Médiateur. Pour connaître les modalités de saisine du Médiateur, <a href="http://www.cmap.fr/le-cmap/nous-saisir/nous-saisir-en-mediation-de-la-consommation-2/">cliquez ici</a>. Au niveau européen, la Commission Européenne a également mis en place une plateforme de résolution en ligne des litiges <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">accessible ici</a>. 
        </div>
        
        <div>
          A défaut de résolution du litige dans le cadre de cette médiation, le litige sera porté devant les juridictions françaises compétentes.
        </div>
      </div>
    )
  }
}

export default withTranslation()(Cgu)
