import React from 'react'
import images from '../../ressources/images'

function SplashScreen() {
  const style = {
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "70%",
    height: "100vh"
  }
  
  return (
    <div style={style}>
      <img src={images.we_are_lol_white_png} alt=""/>
    </div>
  )
}

export default SplashScreen
