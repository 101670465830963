import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
//components
import images from '../../../../src/ressources/images'
//style
import '../WallComponents.scss';

function SquaredPost(props) {
  switch(props.type){
    case 'image' :
        return (
          <>
            <LazyLoadImage
              alt={props.imagePreview}
              effect="opacity"
              src={props.imagePreview}
              width="100%"
            />
            {/* <div className='content_squared_post bg_img' style={{backgroundImage: 'url('+props.imagePreview+')'}}>
            </div> */}
          </>
        );

    case 'video' :
        return (
          <>
            <div className='content_squared_post bg_video'>
              <img className="preview_icon" src={images.icon_video_white} alt='' />
              <img className="play_icon" src={images.icon_play_white} alt='' />
              <img src={props.videoPreview} alt="" className="blur"/>
              <img src={props.videoPreview} alt="" className="not-blur"/>
            </div>
          </>
        );

    case 'multipleFiles' :
      return (
        props.extension === 'mp4' ? 
          <>
            <div className='content_squared_post bg_video'>
              <img className="preview_icon" src={images.icon_multiple} alt='' />
              <img className="play_icon" src={images.icon_play_white} alt='' />
              <img src={props.videoPreview} alt="" className="blur"/>
              <img src={props.videoPreview} alt="" className="not-blur"/>
            </div>
          </>
          :
          <div className='content_squared_post bg_img'>
            <LazyLoadImage
              alt={props.imagePreview}
              effect="opacity"
              src={props.imagePreview} 
            />
            <img className="preview_icon" src={images.icon_multiple} alt='' />
          </div>
        )

    default:
      return (
        <LazyLoadImage
          alt={props.imagePreview}
          effect="opacity"
          src={props.imagePreview} 
        />
      )
       
  }
}

export default SquaredPost;
