import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import WallList from './WallList/WallList';
import WallPost from './WallPost/WallPost';
import WallCreate from './WallCreate/WallCreate';
import WallFilter from './WallFilter/WallFilter';
import WallEdit from './WallEdit/WallEdit';

export class WallRouter extends Component {
  render() {
    const { match, location } = this.props
    
    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}`} component={WallList} />
          <Route exact path={`${match.path}/p/:id`} component={WallPost} />
          <Route exact path={`${match.path}/p/:id/edit`} component={WallEdit} />
          <Route exact path={`${match.path}/create`} component={WallCreate} />
          <Route exact path={`${match.path}/filter`} component={WallFilter} />
          <Route component={WallList}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(WallRouter)
