import {axiosApi} from './helper'

export function getAllTraining(obj) {
  return axiosApi('courses', obj, 'get')
}

export function getTraining(id) {
  return axiosApi('course/'+id, {}, 'get')
}

export function getModule(id) {
  return axiosApi('module/'+id, {}, 'get')
}

export function getModuleGroup(id) {
  return axiosApi('moduleGroup/'+id, {}, 'get')
}

export function checkQuestion(idModule, idQuestion, userChoice) {
  let obj = {
    "responses": userChoice
  }
  return axiosApi('check-response/'+idModule+'/'+idQuestion, obj, 'post')
}

export function checkModule(idModule) {
  return axiosApi('check-result/'+idModule, {}, 'post')
}

export function getCourseComments(id) {
  return axiosApi('course/'+id+'/comments', {}, 'get')
}

export function addCourseComment(id, obj) {
  return axiosApi('course/'+id+'/comment', obj, 'post')
}

export function replyCourseComment(id, id_comment, obj) {
  return axiosApi('course/'+id+'/comment/'+id_comment+'/response', obj, 'post')
}

export function addLike(id) {
  return axiosApi('course/'+id+'/addlike', {}, 'post')
}

export function coursePreview(id) {
  return axiosApi('module-group-preview/'+id,{}, 'get')
}

export function groupCourses(groupId) {
  return axiosApi('course/usergroup/'+groupId,{}, 'get')
}
