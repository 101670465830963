import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { getUserPosts } from '../../../../api/account'
import { withTranslation } from 'react-i18next';
import InsLoader from '../../../../components/InsLoader/InsLoader'
import SquaredPost from '../../../../components/Wall/List/SquaredPost';
import InsButton from '../../../../components/InsButton/InsButton'
import images from '../../../../../src/ressources/images';
import { connect } from 'react-redux'

class ProfilePage extends Component {
  constructor(props) {
    super(props)
    const nav = this.props.location?.state?.nav ?? 'posts'
    this.state = {
      user: null,
      posts: null,
      points: null,
      loaderPost: true,
      bottomNav: nav
    }
  }

  componentDidMount() {
    this.getUserPosts()
  }

  getUserPosts = () => {
    getUserPosts().then(data => {
      this.setState({
        posts: data,
        loaderPost: false
      })
    })
  }

  toggleNav = input => {
    this.setState({bottomNav: input})
  }

  closeProfil = () => {
    const { history, location } = this.props;
    if(location.state && location.state.goBack){
      history.goBack();
    } else {
      history.replace('/');
    }
  }

  iconOption = (type, filesLength) => {
    if(filesLength > 1){
      return 'multipleFiles'
    }
    else{
      if(type === 'image'){
        return 'image'
      }
      else if(type === 'video'){
        return 'video'
      }
    }
    return 'error'
  }

  extensionImg = (obj) => {
    let extension = obj.split('.');
    extension = extension[extension.length-1];
    return extension;
  }
  
  render() {
    const {account, t} = this.props
    const {bottomNav, loaderPost, posts} = this.state
    return (
      <div className="bg_white profile_page">
        <div className="close_profil" onClick={this.closeProfil}>
          <div className="menu--line3"></div>
          <div className="menu--line3"></div>
          <div className="menu--line3"></div>
        </div>

        <div className="profil_infos">            
          <Link className="edit_link" to={{ pathname: '/profile/edit', state: { transitionName: "slide_in" }}}>
            <div className="profil_picture">
              <img src={account.picture ? process.env.REACT_APP_URL + account.picture : images.icon_user} alt={account.firstname + 'avatar'}/>
            </div>
            <div className="edit_btn"></div>
          </Link>

          <div className="username">
            <span className="firstname">{account.firstname} </span>
            <span className="lastname">{account.lastname}</span>
          </div>

          <div className="email">
            {account.email}
          </div>
        </div>
        <div className="profil_bottom">
          <div className="profil_bottom-nav">
            <div 
              className={bottomNav === "posts" ? "posts active" : "posts"}
              onClick={() => {this.toggleNav('posts')}}
            >
              {t('account:post')}
            </div>
            <div 
              className={bottomNav === "points" ? "points active" : "points"}
              onClick={() => {this.toggleNav('points')}}
            >
              {t('account:points')}
            </div>
          </div>
          {bottomNav === "posts" ?
            <div className="posts_container">
              {loaderPost ?
                <InsLoader/>
              :              
                posts.map((post, i) => (
                  <Link className='squared_wrapper' 
                    key={i}  
                    to={{ pathname: '/wall/p/'+post.id, state: { transitionName: "slide_in" }}}
                  >
                    <SquaredPost
                      type={this.iconOption(post.files[0].type, post.files.length)}
                      imagePreview={process.env.REACT_APP_URL+post.files[0].name.name_333}
                      videoPreview={process.env.REACT_APP_URL+post.files[0].name.thumbnail}
                      extension={this.extensionImg(post.files[0].name.name)}
                    />
                  </Link> 
                ))                
              }
            </div>
          :
            <div className="points_container">
              <div className="wrapper">
                <div className="congrats">
                  {t('account:congrats')}
                </div>
                <div className="points_circle">
                  <div className="points">{account.points}</div>
                  <div className="points_txt">points</div>
                </div>
                <InsButton
                  text={t('account:points_history')}
                  link="/profile/points"
                />
                <InsButton
                  text={t('account:my_courses')}
                  link="/profile/courses"
                />

              </div>
            </div>
          }
          
        </div>
      </div>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(ProfilePage)))
