import * as types from "../actions/actionsTypes";

let initialState = {
  scormValue: {}
}

const scormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SCORM:      
      return {
        ...state,
        scormValue: {
          ...state.scormValue,
          [action.data.id]: {
            ...state.scormValue[action.data.id],
            [action.data.key]: action.data.value
          }
        }
      }

    case types.REFRESH_SCORM:      
      return {
        ...state,
        scormValue: {
          ...state.scormValue,
          [action.data.id]: {}
        }
      }
    
    default:
      return state;
  }
}


export default scormReducer;