import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router";

import './InsHeaderConnect.scss'

class InsHeaderConnect extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    linkBack: PropTypes.string,
    state: PropTypes.any
  }
  static defaultProps = {
    linkBack: '/connect'
  }
  
  /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history, linkBack, location, state } = this.props;
    if(location.state && location.state.goBack){
      history.goBack();
    } else {
      history.replace(linkBack, state);
    }
  }

  render() {
    const { title } = this.props
    return (
      <Fragment>
        <div id="header_connect_padding"></div>
        <header className='header_connect'>
          <div className='back_link' onClick={this._goBack}></div>
          <div className="title">{title}</div>
        </header>
      </Fragment>
    )
  }
}

export default withRouter(InsHeaderConnect)
