import React, { Component } from 'react';
import { Link } from "react-router-dom";
import images from '../../../ressources/images';
import './Parrainage.scss';
import { withTranslation } from 'react-i18next'
import { sendMail, getSponsorshipList } from '../../../api/account';
import Validate from '../../../ressources/validation';

class Parrainage extends Component {
  constructor(props) {
    super(props)
    this.createdMail = React.createRef();
    this.state = {
        sponsorshipList: [],
        createSponsorShip : [{email: '', error: null, checkOnce: false}],
        notSend: {
            alreadyExists: [],
            alreadyInvited: []
          },
        sending: false,
        sponsorshipError: false,
        sponsorshipErrorText: ""
    }
  }

  componentDidMount() {
    this.getSponsorship()
  }

  goodFormMail = (mail) => {
    if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
      return true
    }
    else{
      return false
    }
  }

  getSponsorship = () => {
    getSponsorshipList().then((data) =>{
      this.setState({sponsorshipList: data})
    })
  }

  sendInvit = (event) => {    
    event.preventDefault();
    const { createSponsorShip } = this.state
    const sendCreateSponserShip = createSponsorShip.filter(el => !el.error && el.checkOnce).map(el => el.email )
    const createSponsorShipFormat = {'email': sendCreateSponserShip}
    if(createSponsorShipFormat.email.length) {
      sendMail(createSponsorShipFormat).then((response) => {
        this.setState({
          notSend: {
            alreadyExists: response.alreadyExists, 
            alreadyInvited: response.alreadyInvited
          }, 
          sending: true,
          sentCount: response.sentCount
        })
      }).catch(error => {
        console.log('error:', error)
        this.setState({
          sponsorshipError: true,
          sponsorshipErrorText: error
        })
      });
    }
  }

  checkLimit = () => {
    return ((this.state.sponsorshipList.length + this.state.createSponsorShip.length) < 10)
  }

  addInputMail = () => {
    // let inputId = this.state.createSponsorShip.length
    this.setState(prevState => ({
      createSponsorShip: [...prevState.createSponsorShip, {email: '', error: null, checkOnce: false}]
    }))
  }

  handleChange = (params) => (event) => {
    const { createSponsorShip } = this.state
    const value = event.target.value
    this.setState({
      createSponsorShip: createSponsorShip.map( (el,index) => {
        if(index !== params){
          return el;
        } else {
          return (el.checkOnce) ? {...el, email: value, error: Validate('email', value)} : {...el, email: value}
        }
      })
    })
  }
  handleBlur = (params) => () => {
    const { createSponsorShip } = this.state
    this.setState({
      createSponsorShip: createSponsorShip.map( (el,index) => {
        if(index !== params){
          return el;
        } else {
          return {...el, error: Validate('email', el.email), checkOnce: true}
        }
      })
    })
  }

  render() {
    const {t} = this.props
    const { notSend, sentCount, sponsorshipList, createSponsorShip,sponsorshipError, sponsorshipErrorText } = this.state
    return (
      <div className="bg_white">
        <Link to="/" className="back_link">
        </Link>
        <div className='white'>
        {sentCount > 0 && 
            <div className={this.state.sending ? 'pop_in_mail_send is--open' : 'pop_in_mail_send'}>
                <div className='pop_in_mail_send--text'>
                  <img src={images.closeCross} alt='' onClick={() => {this.setState({sending: false})}}/>
                  <p>{t('account:sponsorship_send')}</p>
                </div>            
            </div>
        }
          <div className="parrain_title">
            <p>Parrainage</p>
            <img src={images.icon_sponsorship} alt="" />
          </div>
          <div className="parrain_img">
            <img src={images.womens_sponroship} alt='' />
          </div>
          <div className="parrain_text">
            <p>
              {this.props.t('account:sponsorship_text_content1')}
              <b>{this.props.t('account:we_are_lol')}</b>
              {this.props.t('account:sponsorship_text_content2')}
            </p>
          </div>
          <form className="parrain_form_container" onSubmit={this.sendInvit}>

            {/* displaying mail sponsorship already send */}
            {sponsorshipList.map((mail) =>
             <input type="text" key={mail} placeholder="Email@example.com" value={mail.email} disabled/>
            )}

            {/* displaying input mail to send a new sponsorship invitation*/}
            {this.checkLimit() &&
              createSponsorShip.map((created, index) =>
                <div className="gap_bottom" key={index}>
                  <input type="email" placeholder="Email@example.com" value={createSponsorShip[index].email} onChange={this.handleChange(index)} onBlur={this.handleBlur(index)} />
                  <p className="error_mail">{createSponsorShip[index].error}</p>
                </div>
              )
            }

            {this.checkLimit() &&
              <div className="new_mail" onClick={this.addInputMail}>
                <span>
                  +
                </span>
              </div>
            }

            {notSend.alreadyExists.length > 0 &&
              <div className="mail_already_exist">
                {notSend.alreadyExists.length > 1 ? t('account:sponsorships_exist') : t('account:sponsorship_exist')}
                {notSend.alreadyExists.map((address, index) => 
                  <div className="mail_exist" key={index}>
                    {address}
                  </div>
                )}
              </div>
            }
            {notSend.alreadyInvited.length > 0 &&
              <div className="mail_already_exist">
                {notSend.alreadyInvited.length > 1 ? t('account:sponsorships_invited') : t('account:sponsorship_invited')}
                {notSend.alreadyInvited.map((address, index) => 
                  <div className="mail_exist" key={index}>
                    {address}
                  </div>
                )}
              </div>
            }
            {sponsorshipError && 
              <div className="sponsorship_error">
                {sponsorshipErrorText}
              </div>
            }
            <div className="send_form">
              <input className="register" type="submit" value="Envoyer" />
              <input className="reset" type="reset" value="Annuler" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Parrainage);