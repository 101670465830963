import React, { Component } from 'react'
import './InsCGUBar.scss'
import { withTranslation } from 'react-i18next'

export class InsCGUBar extends Component {
  
  render() {
    const {t} = this.props
    return (
        <div className="cgu_container" style={{marginTop: "auto", width: "100%"}}>
          <div className="cgu_bar">
            <div className="cgu_bar_bloc">
              <a href={process.env.REACT_APP_URL + '/backend/uploads/WALL-CGU.pdf'} target='_blank'  rel="noopener noreferrer">
                <p>{t('cgu:terms_and_conditions')}</p>
              </a>
            </div>
            <div className="cgu_bar_bloc">
              <a href={process.env.REACT_APP_URL + '/backend/uploads/WALL_Privacy_Policy_FR.pdf'} target='_blank'  rel="noopener noreferrer">
                <p>{t('cgu:privacy_policy')}</p>
              </a>
            </div>
          </div>
        </div>
    )
  }
  }


export default withTranslation()(InsCGUBar)
