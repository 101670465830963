import './InsCreateSuccess.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/createAccountAction';
import { login } from '../../../../api/helper';

class InsCreateSuccess extends Component {
  static propTypes = {
    img: PropTypes.string.isRequired,
    text1: PropTypes.string.isRequired,
    text2: PropTypes.string,
    link: PropTypes.string.isRequired,
    textLink: PropTypes.string
  }
  static defaultProps = {
    img: '',
    text1: '',
    link: '/'
  }

  delInfos = () => {
    this.props.deleteInfos()
  }

  
  _connect = () => {
    const { history } = this.props
    const { email, plainPassword} = this.props.userInfo.userInfo
    login(email, plainPassword, true).then(data => {
        history.replace('/');
    })
  }

  endCreateAccount = () => {
    this.delInfos()

    this.props.approuved && this._connect()
  }

  render() {
    return (
      <>
        <div className="account-success">
          <div className={'account-logo '+this.props.img}>

          </div>
          <div className='account-check-text'>
            <p>{this.props.text1}</p>
            <p>{this.props.text2}</p>
          </div>
        </div>
        <div className="account-final-link">
            <Link 
              to={this.props.link}
              onClick={this.endCreateAccount}
            >
              {this.props.textLink}
            </Link>
        </div>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  userInfo: state.createAccount
})

const mapDispatchToProps  = (dispatch) => {
  return {
    deleteInfos: () => { dispatch(actions.deleteInfos()) },
  }  
}

export default (connect(mapStateToProps, mapDispatchToProps)(InsCreateSuccess))