import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import InsButton from '../../../../components/InsButton/InsButton';
import InsInput from '../../../../components/InsInput/InsInput';
import InsRadio from '../../../../components/InsRadio/InsRadio';
import "react-datepicker/dist/react-datepicker.css";
import Validate from '../../../../ressources/validation';
import StepCount from '../../../../components/StepCount/StepCount';
import "./Step2.scss";


import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

class Step2 extends Component {
  constructor(props){
    super(props)
    // radio inputs
    this.radio = [
      {
        value: "Homme",
        label: props.t("connect:m")
      },
      {
        value: "Femme",
        label: props.t("connect:mme")
      }
    ]
    
    const date = new Date()
    this.max_year = new Date(date.getFullYear()-16, date.getMonth(), date.getDate());
    this.min_year = new Date(date.getFullYear()-100, date.getMonth(), date.getDate());

    this.state = {
      gender: props.userInfo.gender || '',
      lastname: props.userInfo.lastname || '',
      lastnameError: '',
      firstname: props.userInfo.firstname || '',
      firstnameError: '',
      birthdate: props.userInfo.birthdate || '',
      birthdateError: '',
    }
  }

  static propTypes = {
    onValidate: PropTypes.func,
    userInfo: PropTypes.any
  }

  _handleChange = input => e => {
    this.setState({[input] : e.target.value})
  }

  _handleBirthdate = (value) => {
    if(value > this.max_year) {
      value = this.max_year
    } else if (value < this.min_year) {
      value = this.min_year
    }
    this.setState({'birthdate' : this._convertDatetoString(value)})
  }

  _convertDatetoString = (date) => {
    if(!date){
      return ''
    }
    const pad = (s) => ((s < 10) ? '0' + s : s )
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/');
  }

  _convertStringToDate = date => {
    const parts = date.split('/');
    return new Date(parts[2], parts[1] - 1, parts[0]); 
  }

   /**
    * Valide le champ lastname
    */
  _validateLastname = () => {
    const error = Validate('lastname', this.state.lastname);
    this.setState({
        lastnameError: error
    })
    return !error;
  }

  /**
   * Valide le champ firstname
   */
   _validateFirstname = () => {
    const error = Validate('firstname', this.state.firstname);
    this.setState({
        firstnameError: error
    })
    return !error;
  }

  /**
   * Vailde le formulaire de l'étape
   */
  _validateFormStep = () => {
    const { lastname, firstname, birthdate, gender } = this.state
    return (
      !Validate('lastname',lastname) &&
      !Validate('firstname',firstname) &&
      gender.length && 
      birthdate.length
    )
  }

  _onValidate = () => {
    const { onValidate } = this.props
    const { lastname, firstname, birthdate, gender } = this.state
    const userInfo = {
      lastname: lastname,
      firstname: firstname,
      birthdate: birthdate,
      gender: gender
    }
    onValidate(userInfo);
  }

  render() {
    const { t } = this.props
    const { lastname, firstname, birthdate, gender, lastnameError, firstnameError } = this.state
    return (
      <form className="default-container col-flex" onSubmit={(e) => e.preventDefault()}>
        <StepCount step={2}/>
        <p className="bold-label centered mb50">
          {t("connect:step2")}
        </p>
        <div className="radio-button-container">
          {this.radio.map(( elem, i) => (
            <InsRadio 
              key={elem.value}
              value={elem.value}
              name={'gender-'+i}
              label={elem.label}
              checked={gender === elem.value}
              onChange={this._handleChange('gender')}
            />
          ))}
        </div>
        <div>
          <InsInput 
            name="lastname"
            value={lastname}
            placeholder={t("connect:lastname")}
            onChange={this._handleChange('lastname')}
            onBlur={this._validateLastname}
            error={lastnameError}
          />
        </div>
        <div>
          <InsInput 
            name="firstname"
            value={firstname}
            placeholder={t("connect:firstname")}
            onChange={this._handleChange('firstname')}
            onBlur={this._validateFirstname}
            error={firstnameError}
          />
        </div>
        <div>
        <DatePicker
          selected={(birthdate.length) ? this._convertStringToDate(birthdate) : ''}
          onChange={this._handleBirthdate}
          placeholderText={t("connect:birthdate")}
          minDate={this.min_year}
          maxDate={this.max_year}
          dateFormat="dd/MM/yyyy"
          className="ins_input ins_datePicker"
          locale="fr"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="top-center"
        />
        </div>
        <div className="bottom-container">
          {!this._validateFormStep() &&
            <div className="alert_msg mb10">
              {t('connect:required')}
            </div> 
          }
          <InsButton
            text={t("common:next")}
            onClick={this._onValidate}
            disabled={!this._validateFormStep()}
          />
        </div>
      </form>
    )
  }
}

export default withTranslation()(Step2)