import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import Shop from './Shop';
import ShopProduct from './ShopProduct/ShopProduct';
import ShopCart from './ShopCart/ShopCart';
import ShopValidation from './ShopValidation/ShopValidation';

class ShopRouter extends Component {
  render() {
    const { match, location } = this.props    
    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}`} component={Shop} />
          <Route exact path={`${match.path}/product/:id`} component={ShopProduct} />
          <Route exact path={`${match.path}/cart`} component={ShopCart} />
          <Route exact path={`${match.path}/cart/order=:number`} component={ShopValidation} />
          <Route component={Shop}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(ShopRouter)
