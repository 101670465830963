import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import ProfilePage from './ProfilePage/ProfilePage';
import ProfilePageEdit from './ProfilePage/ProfilPageEdit';
import ProfileOrders from './ProfileOrders/ProfileOrders';
import ProfileOrder from './ProfileOrder/ProfileOrder';
import ProfileCourses from './ProfileCourses/ProfileCourses';
import ProfilePoints from './ProfilePoints/ProfilePoints';
import GroupsRouter from './Groups/GroupsRouter';
import ProfileCourseInformation from './ProfileCourseInformation/ProfileCourseInformation';

export class ProfilRouter extends Component {
  render() {
    const { match, location } = this.props
    
    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}`} component={ProfilePage} />
          <Route exact path={`${match.path}/courses`} component={ProfileCourses} />
          <Route exact path={`${match.path}/course/:id`} component={ProfileCourseInformation} />
          <Route path={`${match.path}/groups`} component={GroupsRouter} />
          <Route exact path={`${match.path}/points`} component={ProfilePoints} />
          <Route exact path={`${match.path}/edit`} component={ProfilePageEdit} />
          <Route exact path={`${match.path}/orders`} component={ProfileOrders} />
          <Route exact path={`${match.path}/order/:id`} component={ProfileOrder} />
          <Route component={ProfilePage}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(ProfilRouter)
