import React, {useState, useEffect} from 'react'
import { withTranslation } from 'react-i18next'

const Variations = (props) => {
  const {close, t, variations, selectVariation} = props
  const [contentHeight,setContentHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }

  useEffect(
    () => {
      window.addEventListener("resize", updateDimensions);
    }
  );

  return (
    <div className="variation_modal"  style={{height: contentHeight - 60}}>
      <div className="close" onClick={close}></div>
      <div className="modal_header">
        {variations[0].color ? t('shop:color') : t('shop:container')}
      </div>
      <div className="modal_content">
        {variations.map((item, index) => (
          item.color ?
          <div className="variation" 
            key={index} 
            onClick={selectVariation}  
            data-color={item.color}
            data-points={item.points}
            data-ean={item.ean}
            data-stock={item.stock}
          >
            <div className="color" style={{backgroundColor: item.color}}></div>
            <div className="label">{item.value_label}</div>
          </div>
          :
          <div className="variation" 
            key={index}
            onClick={selectVariation}
            data-points={item.points}
            data-ean={item.ean}
            data-stock={item.stock}
          >
            <div className="label">{item.value_label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default withTranslation()(Variations)
