import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import './CreateSuccess.scss'
import WeAreLL from '../../../components/WeAreLL/WeAreLL';
import InsCreateSuccess from './InsCreateSuccess/InsCreateSuccess';
//import { store } from '../../../store/configureStore';
import { connect } from 'react-redux'
import InsLoader from '../../../components/InsLoader/InsLoader';

class CreateSuccess extends Component {

  constructor(props){
    super(props);
    this.state = {
      step: 0 //gérer le loader
    }
  }

  render() {
    const { t } = this.props
    const firstname = this.props.userInfo.userInfo.firstname
    

      //redirection si l'utilisateur arrive sur la page par url ou par historique
      if(!this.props.location.state || this.props.location.state.isCreated === undefined){
        return (<Redirect to="/" />)
      }

      //logique illusion de loading
      if(this.state.step === 0){
        setTimeout(() => {
          this.setState({
            step:1
          })
        }, 3000)
      }

      //chargement du bon composant en fonction du resultat de la requete api
      if(this.state.step === 1){
        
        //Nous ne parvenons à trouver votre profil pour le moment.
        if(!this.props.location.state.isCreated){
          return (
            <div className="default-container col-flex">
              <div className="mv20">
                <WeAreLL />
              </div>
    
                <InsCreateSuccess
                img="info" 
                text1={t('connect:not_find_profile_moment')} 
                text2={t('connect:notified_mail_next_day_when_account_validated')}
                link="/connect"
                textLink={t('connect:back_to_home')}
                />
      
              <div className="bottom-container"></div>
            </div>
          )
        }
        //Nous parvenons à trouver votre profil.
        if(this.props.location.state.isCreated){
          return (
            <div className="default-container col-flex">
              <div className="mv20">
                <WeAreLL />
              </div>
      
              <InsCreateSuccess 
              img="check" 
              text1={t('connect:account_is_approved')}
              text2={"Bienvenue "+firstname+" !"}
              link="/"
              textLink={t('connect:access_to_backstage')}
              approuved={true}
              />

              <div className="bottom-container"></div>
            </div>
          )
        }

      }

      //affichage illusion de loading
      else{

        return (
          <div className="default-container col-flex">
          <div className="mv20">
            <WeAreLL />
          </div>
          <div className="account-check-content col-flex">
            <InsLoader/>
            <div className='account-check-text'>
              <p>{t('connect:search_profile_text')}</p>
              <p>{t('connect:wait_please')}</p>
            </div>
          </div> 
        </div>
        )

      }      
    }
  }

  const mapStateToProps = (state) => ({
    userInfo: state.createAccount,
  })
  
  const mapDispatchToProps  = () => {
  }

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateSuccess))