import * as types from "../actions/actionsTypes";

let initialState = {
  notifications : [],
  notificationsNotSeen: 0
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATIONS:
      return({
        ...state,
        notifications: action.data,
        notificationsNotSeen: action.data.filter(notif => !notif.seen).length
      })

    default:
      return state
  }
}

export default notificationReducer;