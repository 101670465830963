import React, { Component } from 'react'
import './BrandContent.scss'
import { Link } from "react-router-dom"
import BrandlistMenu from '../../../../components/Brands/BrandlistMenu/BrandlistMenu';
import { getVideosByBrand, getBrandById } from '../../../../api/brands';
import images from '../../../../ressources/images';
import InsNavigation from '../../../../components/InsNavigation/InsNavigation';
import BrandVideosItem from '../../../../components/Brands/BrandVideosItem/BrandVideosItem';
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar';

export class BrandVideos extends Component {
    
    constructor(props) {
      super(props);
      this.state = { 
        whoActive: 'videos',
        brandId: 0,
        dataVideos: [],
        dataBrand: []
      };
    } 

    UNSAFE_componentWillMount(){
      let brandId = document.location.href.split('/');
      brandId = brandId[brandId.length-1];
      this.setState({brandId:brandId});
    }
    
    componentDidMount(){
      this.getBrandByIdFront();
      this.getVideosData()
    }

    getBrandByIdFront = () => {
      const {brandId} = this.state
      getBrandById(brandId).then((data) =>{
        this.setState({dataBrand: data})
      })
    }

    getVideosData = () => {
      const {brandId} = this.state
      
      getVideosByBrand(brandId).then((data) =>{
        this.setState({dataVideos: data})
      })
    }

  render() {
    const {brandId, dataBrand, dataVideos, whoActive} = this.state
    return (
      <>
      <div className="white--wrapper">
          <Link className='brandlist_backlink' to={{ pathname: '/brands', state: { transitionName: "slide_in" }}}>
            <img src={images.arrow_left_black} alt="" />
          </Link>
          <div className="brandlist_content">
            <div className='flex-row-center'>
              <div className="brand_logo">
                <img className="brandlist_logo" src={process.env.REACT_APP_URL+dataBrand.logoNoir} alt="logo" />
              </div>
            </div>
            <BrandlistMenu whoActive={whoActive} brandId={brandId} />
            <div className="brand_contentlist">
              { dataVideos.length > 0 ?
                  dataVideos.map((video, index) =>
                    <BrandVideosItem 
                      key={index} 
                      brandId={brandId} 
                      VideoId={video.id} 
                      VideoPicture={video.picture} 
                      VideoTitle={video.title} 
                      VideoSubtitle={video.subtitle} 
                      YoutubeId={video.youtube_id}
                    />
                  )
                  :
                <div className="back_soon">
                  <div>En cours de tournage à Hollywood…</div>
                  <div>revenez bientôt </div>
                  <span className="smile" role="img" aria-label="">&#128578;</span>
                </div>
              }
              <InsCGUBar/>
            </div>
            <InsNavigation/>
          </div>
      </div>
      </>
    )
  }
}

export default BrandVideos
