import '../../components/Quizz/Result/Result.scss'
import React, { Component } from 'react'
import images from '../../ressources/images'
import { withTranslation } from 'react-i18next'

export class ResultPreview extends Component {
  
  render() {
    const {t} = this.props
    return(
      <div className="result_wrapper">
        <div className="result_container">
          <div className="result">
            {t('common:congratulation')} !
          </div>
          <div className="result_logo">
            <div className="result_points_wrapper">
              <img className="like_result" src={images.like} alt=""/>
            </div>
          </div>
          <div className="result_text">
          Vous avez répondu à toutes
          les questions. <br/>
          </div>   
        </div>     
        <button className='close_preview btn_verif'>          
          {t("common:close_preview")}
        </button>
      </div>
    )
  }
}

export default withTranslation()(ResultPreview)
