import React, {useState, useEffect} from 'react'
import InsButton from '../../../../components/InsButton/InsButton'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import InsHeader from '../../../../components/InsHeader/InsHeader'
import InsNavigation from '../../../../components/InsNavigation/InsNavigation'
import images from '../../../../ressources/images';
import './ShopValidation.scss'

const ShopValidation = (props) => {
  const [contentHeight, setContentHeight] = useState(window.innerHeight)
  const {t} = props
  const orderNbr = props.match.params.number
  let contentStyle = {height: contentHeight - 120}

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }
  
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, [contentHeight])

  return (
    <div>
      <InsHeader/>
      <div className="order_validation_page" style={contentStyle}>
        <div className="order_confirm">
          {t('shop:order_confirm')}
        </div>
        <div className="order_confirm_img">
          <img src={images.order_confirm} alt={t('shop:order_confirm')}/>
        </div>
        <div className="order_register">
          {t('shop:order_register')}
        </div>
        <div className="order_tracking">
          {t('shop:order_tracking')} <br/>
          {t('shop:order_number')} {orderNbr}
        </div>
        <Link to={{ pathname: '/shop', state: { transitionName: "slide_out" }}}>
          <InsButton
            text={t('shop:back_home')}
          />
        </Link>
      </div>
      <InsNavigation/>
    </div>
  )
}

export default withTranslation()(ShopValidation)
