import './Step1.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import InsButton from '../../../../components/InsButton/InsButton';
import InsInput from '../../../../components/InsInput/InsInput';
import StepCount from '../../../../components/StepCount/StepCount';
import { autocomplete } from '../../../../api/account';
import InsAutocomplete from '../../../../components/InsAutocomplete/InsAutocomplete';

class Step1 extends Component {
  constructor(props){
    super(props)
    this.state = {
      mark_displays: props.userInfo.store ? props.userInfo.store.mark_displays : '',
      mark_displaysError: '',
      cities: props.userInfo.store ? props.userInfo.store.cities : '',
      citiesError: '',
      names: props.userInfo.store ? props.userInfo.store.names : '',
      namesError: '',
      addresses: props.userInfo.store ? props.userInfo.store.addresses : '',
      addressesError: '',
      autocomplete: [],
      acFocus: '',
      acAddress: false,
      storeCode: ''
    }

    this.apiUrl = process.env.REACT_APP_API
  }

  static propTypes = {
    onValidate: PropTypes.func,
    userInfo: PropTypes.any
  }
  
  _handleChange = input => e => {    
    const value = e.target.value
    const {names, cities, mark_displays} = this.state;

    if(!value.length) {
      this.clearForm()
    }
    this.setState({
      [input] : value
    },() => {
      if(value.length >= 3) {  
        if(this.handleChangeTimer){
          clearTimeout(this.handleChangeTimer)
        }
        this.handleChangeTimer = setTimeout(() => {    
          this.autocompleteRequest()
        }, 500);
      } else {
        if(names.length >= 3 || cities.length >= 3 || mark_displays.length >= 3) {   
          this.autocompleteRequest()
        }
      }
    })
  }

  clearForm = () => {
    this.setState({
      cities: '',
      names: '',
      mark_displays: '',
      addresses: ''
    })
  }

  autocompleteRequest = (generation = false) => {
    const {mark_displays, cities, names, addresses } = this.state
    let obj = {}
    if(mark_displays.length)
      obj.mark_display = mark_displays.trim()
    if(cities.length)
      obj.city = cities.trim()
    if(names.length)
      obj.name = names.trim()
    if(addresses.length)
      obj.address = addresses.trim()
    
    autocomplete(obj).then((data) => {
      console.log("storeCode:data['code']", data['code'])
      this.setState({autocomplete: data})
      if(data['code']) {
        this.setState({storeCode:data['code']})
      }
      if(generation) {
        Object.keys(data).forEach(item => {
            if(data[item].length === 1) {
              if(this.state[item] !== data[item][0]) {
                  this.setState({[item]: data[item][0]});
                  this.autocompleteRequest(true);
              }
            } else if( (data['names'].length === 1 || data['names'].indexOf(obj.name) !== -1) && cities.length && mark_displays.length && data['addresses'].length > 1) {
              this.setState({acAddress: true})
            }
        })
      }
    })
  }

  showAutocomplete = name => () => {
    clearTimeout(this.setTimeOutAutoComplete)
    this.setState({acFocus: name})
  }

  _onClickAutocomplete = input => value => {
    this.setState({
      [input] : value,
      acAddress: false
    }, () => {
      this.autocompleteRequest(true)
      this.showAutocomplete('')
    })
  }

  _clearInput = input => {
    this.setState({
      [input] : ''
    }, () => {
      this.autocompleteRequest(true)
    })
  }

  _onValidate = () => {
    const { onValidate } = this.props
    const { storeCode } = this.state
    const userInfo = {
      store : {
        storeCode: storeCode,
      }
    }
    onValidate(userInfo);
  }

   /**
   * Valide le formulaire de l'étape
   */
  _validateFormStep = () => {
    const { mark_displays, names, cities, addresses } = this.state
    return (
      mark_displays !== '' &&
      names !== '' &&
      cities !== '' &&
      addresses !== ''
    )
  }

  render() {
    const { t } = this.props
    const { acAddress, mark_displays, cities, names, addresses, autocomplete, acFocus } = this.state

    return (
      <form className="default-container col-flex" onSubmit={(e) => e.preventDefault()} >
        <StepCount step={1}/>
        <p className="bold-label centered mb50">
          {t("connect:step1")}
        </p>
        <div className='input_container'>
          <InsInput 
            name="mark_displays"
            onChange={this._handleChange('mark_displays')}
            onFocus={this.showAutocomplete('mark_displays')}
            onBlur={() => {this.setTimeOutAutoComplete = setTimeout(() => {this.showAutocomplete('')()}, 150 )}}
            placeholder={t("connect:mark_displays")}
            value={mark_displays}
            autoComplete='new-password'
          />        
          <InsAutocomplete 
            visible={acFocus === 'mark_displays'} 
            values={autocomplete.mark_displays}
            onClick={this._onClickAutocomplete('mark_displays')}
          />
        </div>
        <div className='input_container'>
          <InsInput 
            name="cities"
            onChange={this._handleChange('cities')}
            onFocus={this.showAutocomplete('cities')}
            onBlur={() => {this.setTimeOutAutoComplete = setTimeout(() => {this.showAutocomplete('')()}, 150 )}}
            placeholder={t("connect:cities")}
            value={cities}
            autoComplete='new-password'
          />     
          <InsAutocomplete 
            visible={acFocus === 'cities'} 
            values={autocomplete.cities}
            onClick={this._onClickAutocomplete('cities')}
          />
        </div>
        <div className='input_container'>
          <InsInput 
            name="names"
            onChange={this._handleChange('names')}
            onFocus={this.showAutocomplete('names')}
            onBlur={() => {this.setTimeOutAutoComplete = setTimeout(() => {this.showAutocomplete('')()}, 150 )}}
            placeholder={t("connect:names")}
            value={names}
            autoComplete='new-password'
            
          />  
          <InsAutocomplete 
            visible={acFocus === 'names'} 
            values={autocomplete.names}
            onClick={this._onClickAutocomplete('names')}
          />
        </div>
        <div className='input_container'>
          <InsInput 
            name="addresses"
            onChange={this._handleChange('addresses')}
            onFocus={this.showAutocomplete('addresses')}
            onBlur={() => {this.setTimeOutAutoComplete = setTimeout(() => {this.showAutocomplete('')()}, 150 )}}
            maxLength="115"
            placeholder={t("connect:addresses")}
            value={addresses}
            autoComplete='new-password'
            readOnly
          />
           <InsAutocomplete 
            visible={acAddress} 
            values={autocomplete.addresses}
            onClick={this._onClickAutocomplete('addresses')}
          />
          {addresses.length ? 
              <div className="clear_input"
                onClick={() => this._clearInput('addresses')}
              >
                &#10006;
              </div>
            :
              ""
          }
        </div>

        <div className="bottom-container">
          {!this._validateFormStep() &&
            <div className="alert_msg mb10">
              {t('connect:required')}
            </div> 
          }
          <InsButton
            text={t("common:next")}
            onClick={this._onValidate}
            disabled={!this._validateFormStep()}
          />
        </div>
      </form>
    )
  }
}

export default withTranslation()(Step1)