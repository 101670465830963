import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
// import images from '../../ressources/images'

import './InsHeaderFormationsIndex.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom' 
import images from '../../ressources/images'

class InsHeaderFormationsIndex extends Component {
  static propTypes = {
    linkBack: PropTypes.string,
    state: PropTypes.any
  }
  static defaultProps = {
    linkBack: '/formations/'
  }

  /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history, location, state } = this.props;
    if (location.state && location.state.return) { 
      history.replace('/profile/courses', {transitionName: "slide_out", return: location.state.return});
    }
    else if (location.state && location.state.transitionName) {
      history.goBack();
    } 
    else {
      history.replace('/brands/', {transitionName: "slide_out",...state});
    }
  }
  render() {
    const {addLike, currently_liked, likes} = this.props
    return (
      <Fragment>
        <header className='header_formationsIndex'>
          <div className='back_link_formationsIndex' onClick={this._goBack}>
            <img src={images.arrow_left_white} alt=""/>
          </div>
          <div className="formation_likes">
            <div className={currently_liked ? 'like_icon liked' : 'like_icon'} onClick={addLike}></div>   
            <div className="like_count">{likes}</div>
          </div>
        </header>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  navigation: state.navigation
})

export default withRouter(connect(mapStateToProps)(InsHeaderFormationsIndex)) 
