import React, { Component } from 'react'
import InsLoader from '../../InsLoader/InsLoader'

export class CreateHeader extends Component {
  /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history, location } = this.props;
    if(location.state && location.state.goBack){
      history.goBack();
    } else {
      history.replace('/wall');
    }
  }

  render() {
    const { loadCreate, validate, t } = this.props

    return (
      <div className='create_header'>
        <div className='back_link' onClick={this._goBack}></div>
        {loadCreate ? 
          <InsLoader/>
        :
          <div 
            className={"valid_post " + validate} 
            onClick={validate ? this.props.submitPost : () => {console.log('erreur')}}>{t("common:next")}
          </div>
        }
      </div>
    )
  }
}

export default CreateHeader
