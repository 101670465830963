import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './InsSelect.scss'

class InsSelect extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    values: PropTypes.arrayOf(
        PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
      })
    ).isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }


  render() {
    const { 
      className,
      name, 
      error,
      values,
      placeholder,
      ...rest
    } = this.props    
    
    return (
      <div className={className + ' mb20'}>
        <select 
          id={name}
          name={name}
          autoComplete='off'
          {...rest}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {values.map((item, index) => (
            <option key={index} value={item.value}>{item.label}</option>
          ))
            
          }
        </select>
        {error && 
          <div className={'error-'+name}>{error}</div>
        }
      </div>
    )
  }
}

export default InsSelect
