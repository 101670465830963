import * as types from "../actions/actionsTypes";

let initialState = {
  userInfo: {},
}

const createAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ACCOUNT_UPD_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.data
        }
      }

    case types.CREATE_ACCOUNT_DEL_INFO:
      return {
        ...state,
        userInfo: {}
      }
    
    default:
      return state;
  }
}


export default createAccountReducer;