import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './InsInput.scss'

class InsInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword : false
    }
  }
  static propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    type: PropTypes.oneOf(['date', 'email', 'password', 'number', 'search', 'tel', 'text']),
  }

  static defaultProps = {
    type: 'text'
  }

  /**
   * Montrer/Cacher le mot de passe
   */
  showPassword() {
   this.setState({showPassword: !this.state.showPassword})
  }

  render() {
    const { 
      type, 
      error,
      name,
      ...rest
    } = this.props    
    let className = `ins_input ins_input_${type}${error ? ' error' : ''}`

    return (
      <div className={className+' mb20'}>
        <input 
          type={this.state.showPassword ? 'text' : type} 
          {...rest}
        />
        {type === 'password' && 
          <div className='show_password' onClick={() => this.showPassword()}></div>
        }
        {error && 
          <div className={'error-'+name}>{error}</div>
        }
      </div>
    )
  }
}

export default InsInput
