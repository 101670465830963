import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import './ModalQuizz.scss'
import images from '../../../ressources/images';

class ModalQuizz extends Component {
  static propTypes = {
    result: PropTypes.bool,
    response: PropTypes.any,
    nextStep: PropTypes.func,
    step: PropTypes.number,
    reveal: PropTypes.bool
  }

   /**
   * Passe au mini-jeu suivant
   */
  nextStep = () => {
    this.props.nextStep()
  }

  render() {
    const {checked, type, reveal, result, response, t} = this.props
    return (
      <>
        { reveal &&
          <div>
            <div className="modal_overlay"></div>
            <div className="modal_quizz">
              <div className="result">
                {result 
                  ? 
                    <div>
                      <img alt="good answer" src={images.good}></img> Bravo
                    </div> 
                  : 
                    <div>
                      <img alt="wrong answer" src={images.error}></img>
                      Oups...
                    </div>
                }
              </div>
                {!result && 
                  <div className="correction">
                    <div>
                      {!checked && response.length > 1 ? "Les bonnes réponses étaient :" : "La bonne réponse était :"}
                    </div> 
                    <div className="responses">
                      {!checked &&
                        response.map((el, index) => 
                          type 
                            ? 
                              type === "truefalse"  
                              ? 
                                <div key={index} className="truefalse-responses"> <img src={process.env.REACT_APP_URL+el} alt=""/></div> 
                              : 
                                <div key={index} className="qcmi-responses"> <img src={process.env.REACT_APP_URL+el} alt=""/></div>
                            : <div key={index}>{el}</div>
                        )
                      }
                    </div>
                  </div>
                }
              <button className="btn_continue" onClick={this.nextStep}>{t("common:continue")}</button>
            </div>
          </div>
        }
      </>
    )
  }
}

export default withTranslation()(ModalQuizz)
