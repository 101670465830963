import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import images from '../../../../../ressources/images'
import './Group.scss'
import GroupsNav from '../../../../../components/Groups/GroupsNav'
import InsLoader from '../../../../../components/InsLoader/InsLoader'
import BrandProductsItem from '../../../../../components/Brands/BrandProductsItem/BrandProductsItem'
import { groupProduct } from '../../../../../api/shop'

function GroupShop(props) {
  const [productsList, setProductsList] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    const groupId = props.match.params.id
    groupProduct(groupId).then(data => {
      setProductsList(data)
      setLoad(false)
    })
  }, [props.match.params.id])
  
  return (
    <div className="bg_white">
      <div className="group_header">
        <Link className="backlink" to={{ pathname: '/profile/groups', state: { transitionName: "slide_out" }}}>
          <img src={images.arrow_left_black} alt="" />
        </Link>
        {props.history.location.state.groupName}
      </div>
      <GroupsNav 
        groupId={props.match.params.id}
        groupName={props.history.location.state.groupName}
      />
      <div className="group_content">
      { load ?
      <InsLoader/>
      :
      productsList.length > 0 
        ?
        productsList.map(
        (data) => 
        <BrandProductsItem
          key={data.id}
          title={data.name} 
          img={process.env.REACT_APP_URL+data.picture} 
          pdf={data.file}
          link={data.link}
        />
        )
        :
        <div className="back_soon">
          <div>En rupture de stock…</div>
          <div>revenez bientôt </div>
          <span className="smile" role="img" aria-label="">&#128578;</span>
        </div>
      }
      </div>
    </div>
  )
}

export default GroupShop
