import React, { Component } from 'react'
import './BrandlistMenu.scss'
import { NavLink } from "react-router-dom"
import PropTypes from 'prop-types'
import images from '../../../ressources/images';

export class BrandlistMenu extends Component {
    static propTypes = {
      whoActive: PropTypes.string,
      brandId: PropTypes.any
    }

    constructor(props) {
      super(props);
      this.state = {
        formationsClass: '',
        iconFormation: images.formation_icon_grey,
        articlesClass: '',
        iconArticles: images.article_icon_grey,
        produitsClass: '',
        iconProducts: images.produit_icon_grey,
        videosClass: '',
        iconVideo: images.video_icon_grey,
        brandId: 0
      };
    }

    whoActive(propsActive){
      if(propsActive === 'formations'){
        this.setState({ 
          formationsClass: ' active',
          iconFormation: images.formation_icon_black,
          articlesClass: '',
          iconArticles: images.article_icon_grey,
          produitsClass: '',
          iconProducts: images.produit_icon_grey,
          videosClass: '',
          iconVideo: images.video_icon_grey
        })
      }

      if(propsActive === 'articles'){
        this.setState({ 
          formationsClass: '',
          iconFormation: images.formation_icon_grey,
          articlesClass: ' active',
          iconArticles: images.article_icon_black,
          produitsClass: '',
          iconProducts: images.produit_icon_grey,
          videosClass: '',
          iconVideo: images.video_icon_grey
        })
      }

      if(propsActive === 'produits'){
        this.setState({ 
          formationsClass: '',
          iconFormation: images.formation_icon_grey,
          articlesClass: '',
          iconArticles: images.article_icon_grey,
          produitsClass: ' active',
          iconProducts: images.produit_icon_black,
          videosClass: '',
          iconVideo: images.video_icon_grey
        })
      }

      if(propsActive === 'videos'){
        this.setState({ 
          formationsClass: '',
          iconFormation: images.formation_icon_grey,
          articlesClass: '',
          iconArticles: images.article_icon_grey,
          produitsClass: '',
          iconProducts: images.produit_icon_grey,
          videosClass: ' active',
          iconVideo: images.video_icon_black
        })
      }
    }

    UNSAFE_componentWillMount() {
      this.whoActive(this.props.whoActive)
      this.setState({brandId: this.props.brandId})
    }
    render() {
    const {
      formationsClass,
      iconFormation,
      articlesClass,
      iconArticles,
      produitsClass,
      iconProducts,
      videosClass,
      iconVideo,
      brandId
    } = this.state
      
    let style = {};
    if(brandId == 29) {
      style = {
        justifyContent: "space-around",
      }
    }
    let menuItem = 'menu_item';
  
    return (
      <>
            <div className="brandlist_menu" style={style}>
              {brandId != 29 ?
                <NavLink exact to={{pathname: "/brands/formations/"+brandId ,state: { transitionName: "fade_app" }}}>
                    <div className={menuItem + formationsClass} onClick={() => this.whoActive('formations')}>
                      <img src={iconFormation} alt="" />
                      <p>Formations</p>
                    </div>
                </NavLink>
                  : ''}
              <NavLink exact to={{pathname: "/brands/articles/"+brandId ,state: { transitionName: "fade_app" }}}>
                <div className={menuItem  + articlesClass} onClick={() => this.whoActive('articles')}>
                  <img src={iconArticles} alt="" />
                  <p>Articles</p>
                </div>
              </NavLink>
              {brandId != 29 ?
                <NavLink exact to={{pathname: "/brands/produits/"+brandId ,state: { transitionName: "fade_app" }}}>
                  <div className={menuItem  + produitsClass} onClick={() => this.whoActive('produits')}>
                    <img src={iconProducts} alt="" />
                    <p>Produits</p>
                  </div>
                </NavLink>
                  : ''}
              <NavLink exact to={{pathname: "/brands/videos/"+brandId ,state: { transitionName: "fade_app" }}}>
              <div className={menuItem  + videosClass} onClick={() => this.whoActive('videos')}>
                <img src={iconVideo} alt="" />
                <p>Vidéos</p>
              </div>
              </NavLink>
            </div>
      </>
    )
  }
}

export default BrandlistMenu
