import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { checkQuestion } from '../../../api/training';
import ModalQuizz from '../ModalQuizz/ModalQuizz';

export class QcmMultiple extends Component {
  static propTypes = {
      questions: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      allQuestions: [],     // Toutes les questions
      allResponses: [],     // Toutes les bonnes reponses
      allChoices: [],       // Tous les choix possible
      question: [],         // Question en cours
      questionId: null,     // Id de la question en cours
      questionPicture: null,// Image de la question
      choice: [],           // Choix de la question en cours
      userChoice: [],       // Choix de l'utilisateur sur la question en cours
      response: [],         // Bonne réponse de la question en cours
      step: 0,              // Numéro de la question
      modal: false,
      result: null,
      disabled: true
    }
  }  

  componentDidMount() {
    this.getDatas()
    this.activeButton() 
  }
  
  /**
   * Récupere les questions et choix du QCM
   */
  getDatas = () => {
    // Recupere toutes les questions
    const dataQuestions = this.props.questions.map((elem) => (elem.question))
    
    // Recupere l'id de toutes les questions
    const dataIdQuestions = this.props.questions.map((elem) => (elem.id))

    const tempResponses = this.props.questions.map((elem) => (elem.responses))

     // Recupere les images des questions
     const dataPicture = this.props.questions.map((elem) => (elem.picture))
    
    // Recupere les choix possibles
    let dataChoix = []
    tempResponses.forEach(response => {
      dataChoix.push(response.map(elem => elem.response))
    });

    let dataResponses = []
    tempResponses[this.state.step].forEach(element => {
      if(element.valid) {
        dataResponses.push(element.response)
      }
    });

    this.setState({
      allChoices: dataChoix,
      allQuestions: dataQuestions,
      allResponses: tempResponses,
      question: dataQuestions[this.state.step],
      questionId: dataIdQuestions[this.state.step],
      questionPicture: dataPicture[this.state.step],
      choice: dataChoix[this.state.step],
      response: dataResponses,
    })
  }

  /**
   * Stock les choix de l'utilisateur
   */
  handleChoiceSelect = e => {
    const {userChoice} = this.state;
    const check = e.target.checked;
    const checked_value = e.target.value;
    if(check){
      this.setState({
        userChoice: [...userChoice, checked_value]
      }, () => {this.activeButton()})
    }else{ 
      const index = userChoice.indexOf(checked_value);
      if (index > -1) {
        userChoice.splice(index, 1);
        this.setState({
          userChoice: userChoice
        }, () => {this.activeButton()})
      } 
    }
  }
  
  activeButton = () => {
    const {userChoice} = this.state
    this.setState({disabled: !userChoice.length > 0})
  }

  /**
   * Vérifie les réponses de l'utilisateur et affiche le resultat
   */
  checkResponse = () => {
    const { questionId, userChoice, step } = this.state
    const { isPreview, moduleId } = this.props

    this.props.nextQuestion(step)
    if(isPreview) {      
      this.nextStep()
    } else {
      checkQuestion(moduleId, questionId, userChoice).then((data) =>{
        this.setState({
          modal: true,
          result: data.response,
          response: data.good_reponse
        })
      })
    }
  }

  /**
   * Passe au mini-jeu suivant
   */
  nextStep = () => {
    const { step } = this.state
    if( step < (this.state.allQuestions.length - 1) ) {
      this.setState({
        userChoice: [],
        step: step+1,
        modal: false,
        result: null
      }, () => {
        this.getDatas()
      })
    } else {
      this.props.nextQuizz()
      this.setState({
        userChoice: [],
        step: 0,
        modal: false
      }, () => {this.getDatas()})
    }
  }

  isChecked = (choix) => this.state.userChoice.includes(choix)
  
  render() {
    const {disabled, modal, question,questionPicture, choice, result, response, step} = this.state
    const { t } = this.props   

    const listChoices = choice.map((choix,index) =>
      <div className='quizz_choice--btn qcmm' key={index} >
        <input 
          onChange={this.handleChoiceSelect} 
          type="checkbox" 
          id={choix} 
          name="choice" 
          value={choix}
          checked={this.isChecked(choix)}
        />
        <label htmlFor={choix}>{choix}</label>
      </div>
    );

    return (
      <div className="qcm_wrapper">
        {
          questionPicture &&
          <div className="qcm_img">
            <img src={process.env.REACT_APP_URL+questionPicture} alt=""/>
          </div>
        }
        <div className="quizz_question">
          {question}
        </div>
        <div className="quizz_choice">
          {listChoices}
        </div>
        <button className="btn_verif" disabled={disabled} onClick={this.checkResponse}>
          {t("common:check")}
        </button>
        <ModalQuizz reveal={modal} result={result} response={response} step={step} nextStep={this.nextStep}/>
      </div>
    )
  }
}

export default withTranslation()(QcmMultiple)
