import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"

export class ResultBad extends Component {
  render() {
    const {formation, moduleName, t} = this.props
    return (
      <div className="result_wrapper">
        <div className="result_container">
          <div className="result">
            {t('common:sorry')}...
          </div>
          <Link to={'/formations/'+formation+'/'+moduleName} className="result_logo">
            <div className="result_points_wrapper retry">
              <span className="result_retry"></span>
            </div>
          </Link>
          <div className="result_text">
            Vous n’avez pas répondu correctement à toutes les questions. 
            Rien n’est perdu ! <br/>
            Recommencez la formation pour gagner des points.
          </div> 
        </div>       
        <div>
          <Link to={'/formations/'+formation+'/'+moduleName} className="btn_verif">          
            {t("common:retry")} 
          </Link>
          <Link to={'/formations/'+formation} className="btn_verif btn_verif_grey">          
            {t("common:leave")} 
          </Link>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ResultBad)