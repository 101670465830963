import React, { Component } from "react";
import FormationItem from "../../../../components/FormationItem/FormationItem";
import InsHeader from "../../../../components/InsHeader/InsHeader";
import InsNavigation from "../../../../components/InsNavigation/InsNavigation";
import "./FormationsList.scss";
import { getAllTraining } from "../../../../api/training";
import InsCGUBar from "../../../../components/InsCGUBar/InsCGUBar";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadComponent } from "react-lazy-load-image-component";

export class FormationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listFormations: [],
      contentHeight: window.innerHeight,
      offset: 0,
      loading: false,
      prevY: 0,
      hasMore: true,
    };
  }

  componentDidMount() {
    this.getAllFormation();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  /**
   * Récupere toutes les formations disponible
   */
  getAllFormation = async () => {
    this.setState({ loading: true });

    const data = await getAllTraining({ offset: this.state.offset });

    if (data.length === 0) {
      this.setState({ hasMore: false });
    }
    this.setState({
      listFormations: [...this.state.listFormations, ...data],
    });
    this.setState({ offset: this.state.offset + 1 });
    this.setState({ loading: false });
  };

  updateDimensions = () => {
    this.setState({ contentHeight: window.innerHeight });
  };

  render() {
    let { contentHeight, listFormations } = this.state;
    const loader = (
      <div
        style={{
          margin: "20px 0",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner loading={true}></LoadingSpinner>
      </div>
    );

    return (
      <>
        <InsHeader />
        <div className="page_container">
          <div
            className="formations_list"
            style={{
              height: contentHeight - 120,
            }}
            id="scrollable-formations-list"
          >
            <InfiniteScroll
              dataLength={listFormations.length}
              next={this.getAllFormation}
              hasMore={this.state.hasMore}
              scrollableTarget="scrollable-formations-list"
              loader={loader}
              style={{
                overflow: "hidden",
              }}
            >
              <LazyLoadComponent>
                {listFormations.map((item, index) => (
                  <FormationItem
                    key={index}
                    idFormation={item.id}
                    image={item.picture}
                    title={item.title}
                    brand={item.brand}
                    points={item.points}
                    time={item.time}
                    formation={item.type}
                    pourcent={item.pourcent}
                  />
                ))}
              </LazyLoadComponent>
            </InfiniteScroll>
            <InsCGUBar />
          </div>
        </div>
        <InsNavigation />
      </>
    );
  }
}

export default FormationsList;
