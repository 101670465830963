import React, { Component } from 'react'
import images from '../../../ressources/images';
import './ProgressBar.scss'

export class ProgressBar extends Component {

  /**
   * Quitte le module et revient à la page index de la formation
   */
  closeQuizz = () => {
    this.props.close()
  }
  
  render() {
    const {isPreview, modules} = this.props

    return (
      <div className="progress_bar">
        {!isPreview &&
          <div className="close_quizz" onClick={this.closeQuizz}>
            <img src={images.closeCross} alt=""/>
          </div>
        }        
        {modules.map((module, index) => 
          {
            const checkQuestions = []
            if(module.questions) {
              module.questions.forEach(el => {
                if(el.check || el == null)
                  checkQuestions.push(el.check)
              })
            }

            let moduleLength = 0
            if(module.questions) {
              moduleLength = module.questions.length
            } else if (module.text) {
              moduleLength = module.text.length
            } else if (module.content) {
              moduleLength = module.content.length
            }

            const moduleStep = 0 || checkQuestions.length
            const moduleRatio = moduleLength/moduleStep
            let styleBar = {
              width: 100/moduleRatio+'%'
            }
            return ( 
              <div key={index} className="progress_step">
                <div className="filler" style={styleBar}></div>
              </div>
            )
          }
        )}
      </div>
    )
  }
}

export default ProgressBar
