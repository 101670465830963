import React from 'react'
import './InsLoader.scss'

export default function InsLoader() {
  return (
    <div className="account-check-picto lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
