import validatejs from 'validate.js';
import Strings from '../translations/translations';

validatejs.validators.sponsorship = function(value, options, key, attributes) {
  return (/\d{6}/.test(value) || !value.length) ? null : options.message ;
};

const validation = {
  email: {
    presence: {
      allowEmpty: false,
      message: `^${Strings('error:validation.email.presence')}`
    },
    email: {
      message: `^${Strings('error:validation.email.email')}`
    }
  },

  password: {
    presence: {
      allowEmpty: false,
      message: `^${Strings('error:validation.password.presence')}`
    },
    format: {
      pattern: "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9@#$%^&+*!=]).*$",
      message: `^${Strings('error:validation.password.format')}`
    },
    length: {
      minimum: 8,
      message: `^${Strings('error:validation.password.length')}`
    },
  },
  
  passwordLength: {
    length: {
      minimum: 8,
      message: `^${Strings('error:validation.password.length')}`
    },
  },
  passwordMin: {
    format: {
      pattern: "(?=.*[a-z]).*$",
    },
  },
  passwordMaj: {
    format: {
      pattern: "(?=.*[A-Z]).*$",
    },
  },
  passwordSpecial: {
    format: {
      pattern: "(?=.*[0-9@#$%^&+*!=]).*$"
    },
  },

  lastname: {
    presence: {
      allowEmpty: false,
      message: `^${Strings('error:validation.lastname.presence')}`
    },
    format: {
      pattern: "^(?=.{1,40}$)[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿ]+)*$",
      message: `^${Strings('error:validation.lastname.format')}`
    },
    length: {
      maximum: 30,
      minimum: 2,
      message: `^${Strings('error:validation.lastname.length')}`
    },
  },

  firstname: {
    presence: {
      allowEmpty: false,
      message: `^${Strings('error:validation.firstname.presence')}`
    },
    format: {
      pattern: "^(?=.{1,40}$)[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿ]+)*$",
      message: `^${Strings('error:validation.firstname.format')}`
    },
    length: {
      maximum: 30,
      minimum: 2,
      message: `^${Strings('error:validation.firstname.length')}`
    },
  },

  sponsorship : {
    sponsorship: {
      message: `^${Strings('error:validation.sponsorship.length')}`      
    },

  },

  phone: {
    presence: {
      allowEmpty: false,
      message: `^Téléphone requis`
    },
    length: {
      is: 10,
      message: `^Numéro non valide`
    },
    numericality: {
      onlyInteger: true,
      message: `^Numéro non valide`
    },
  }
}

/**
 * Valider un champ input
 * @param {string} fieldName Le type de champ
 * @param {any} value Valeur du champ
 */
export default function Validate(fieldName, value) {
  // Validate.js validates your values as an object
  // e.g. var formValues = {email: 'email@example.com'}
  var formValues = {}
  formValues[fieldName] = value

  // Creates a temporary form with the validation fields
  var formFields = {}
  formFields[fieldName] = validation[fieldName]

  // The formValues and validated against the formFields
  // the variable result hold the error messages of the field
  const result = validatejs(formValues, formFields)

  // If there is an error message, return it!
  if (result) {
    // Return only the field error message if there are multiple
    return result[fieldName][0]
  }

  return null
}
