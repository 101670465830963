import './Step1Code.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import InsButton from '../../../../components/InsButton/InsButton';
import StepCount from '../../../../components/StepCount/StepCount';
import InsInput from '../../../../components/InsInput/InsInput';
import InsSelect from '../../../../components/InsSelect/InsSelect';

class Step1Code extends Component {
  constructor(props){
    super(props)

    this.agencies = [
      { value: "Globe", label: "Globe" }, 
      { value: "Qualibeauté", label: "Qualibeauté" }, 
      { value: "KS", label: "KS" }, 
      { value: "Talent/Ergalis", label: "Talent/Ergalis" }, 
      { value: "Aladinoo", label: "Aladinoo" }, 
      { value: "Maquillage et séduction", label: "Maquillage et séduction" }, 
      { value: "PHARMANIMATION", label: "PHARMANIMATION" }, 
      { value: "Autre", label: "Autre" }
    ];

    this.enseignes = [
      { value: "BEAUTY SUCCESS", label: "Beauty Success" }, 
      { value: "MARIONNAUD", label: "Marionnaud" }, 
      { value: "NG/GL", label: "NG/GL" }, 
      { value: "NOCIBE", label: "Nocibé" }, 
      { value: "PASSION BEAUTE", label: "Passion Beauté" }, 
      { value: "PRINTEMPS", label: "Printemps" }, 
      { value: "SEPHORA", label: "Sephora" },
      { value: "UNE HEURE POUR SOI", label: "Une heure pour soi" }
    ];
    
    this.prada = [
      { value: "Prada", label: "Prada" }  
    ];
    
    this.codes = [
      { code: "wall19ai", withAgency: this.agencies }, 
      { code: "wall19se", withAgency: this.enseignes },
      { code: "wall19lo", withAgency: false }, 
      { code: "wall19ec", withAgency: false }, 
      { code: "wall19si", withAgency: false }, 
      { code: "wall19gm", withAgency: false },
      { code: "wall20kcr", withAgency: false },
      { code: "wall20dt", withAgency: false },
      { code: "PRADAMODE", withAgency: this.prada }
    ];
    
    this.state = {
      code: props.userInfo.code || '',
      codeError: '',
      agency: props.userInfo.agency || '',
      agencyError: ''
    }
  }

  static propTypes = {
    onValidate: PropTypes.func
  }

  _handleChange = input => e => {
    this.setState({[input] : e.target.value})
  }

  /**
   * Valide le code
   */
  _validateCode = () => {
    const { t } = this.props
    const { code } = this.state
    if (this.codes.find(elem => (elem.code === code))) {
      this.setState({
        codeError: ''
      })
    } else {
      this.setState({
        codeError: t('error:unknow_code')
      })
    }
  }

  /**
   * Valide l'agence
   */
  _validateAgency = (e) => {
    const { t } = this.props
    const { agency } = this.state
    const agencyRealValue = (e) ? e.target.value : agency;
    this.setState({
      agencyError: !agencyRealValue.length ? t('error:choose_option') : ''
    })
  }

  /**
   * Vailde le formulaire de l'étape
   */
  _validateFormStep = () => {
    const { code, agency } = this.state
    let valid = false;
    const codeObj = this.codes.find(elem => (elem.code === code));
    if(codeObj){
      if (codeObj.withAgency) {
        valid = !!agency.length
      } else {
        valid = true
      }
    }
    return valid
  }

  _onValidate = () => {
    const { onValidate } = this.props
    const { agency, code } = this.state

    const userInfo = agency ? { code,agency } : {code}

    onValidate(userInfo);
  }

  render() {
    const { t } = this.props
    const { code, codeError, agency, agencyError } = this.state
    const codeObj = this.codes.find(elem => (elem.code === code));
    return (
      <form className="default-container col-flex" onSubmit={(e) => e.preventDefault()}>
        <StepCount step={1}/>
        <p className="bold-label centered mb50">
          {t("connect:step1_code")}
        </p>
        
        <div>
          <InsInput 
            name="code"
            value={code}
            placeholder={t("connect:code")}
            onChange={this._handleChange('code')}
            onBlur={this._validateCode}
            error={codeError}
          />
        </div>
        { (codeObj && codeObj.withAgency) ?
          <div>
            <InsSelect
              name={"agency"}
              value={agency}
              values={codeObj.withAgency}
              placeholder={t("connect:agence_choice")}
              error={agencyError}
              onChange={(e) => { this._handleChange('agency')(e); this._validateAgency(e) }}
            />
          </div>
          : null
        }
        
        <div className="bottom-container">
          {!this._validateFormStep() &&
            <div className="alert_msg mb10">
              {t('connect:required')}
            </div> 
          }
          <InsButton
            text={t("common:next")}
            onClick={this._onValidate}
            disabled={!this._validateFormStep()}
          />
        </div>
      </form>
    )
  }
}

export default withTranslation()(Step1Code)