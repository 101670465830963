import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { deletePost } from '../../../api/wall';

export class PostOptions extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
     postId: this.props.postId,
     showOptions: false,
     showConfirm: false
    }
  }

  
  componentWillUnmount() { 
    document.body.style.overflow = "";
  }

  openOptions = () => {
    this.setState({showOptions: true})    
    document.body.style.overflow = "hidden";
  }

  closeOptions = () => {
    this.setState({showOptions: false})    
    document.body.style.overflow = "";
  }

  confirm = () => {
    this.setState({showConfirm: !this.state.showConfirm})
  }

  deletePost = () => {
    const {postId} = this.state
    const {history} = this.props
    
    deletePost(postId).then(() => {
      history.replace('/wall')
    })
  }
  
  render() {
    const {t} = this.props
    const {postId, showConfirm, showOptions} = this.state

    return (
      <div className="post_options">
        <div className="options_icon" onClick={this.openOptions}>
          <div className="dots"></div>
          <div className="dots"></div>
          <div className="dots"></div>
        </div>
        {showOptions &&
          <div className="options_modal">
            <ul className="options">
                {showConfirm ?
                  <>
                    <li className="option">{t('wall:confirm_delete')}</li>
                    <li className="option" onClick={this.deletePost}>{t('common:yes')}</li>
                    <li className="option" onClick={this.confirm}>{t('common:no')}</li>
                  </>
                :
                  <>
                    <li className="option">
                      <Link
                        to={{ pathname: '/wall/p/'+postId+'/edit', state: { transitionName: "slide_in" }}}
                      >
                        {t('common:edit')}
                      </Link>
                    </li>
                    <li className="option" onClick={this.confirm}>
                      {t('common:delete')}
                    </li>
                  </>
                }
            </ul>
            <div className="options_modal-overlay" onClick={this.closeOptions}></div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(PostOptions)
