import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { login } from '../../../api/helper';
import InsButton from '../../../components/InsButton/InsButton';
import InsHeaderConnect from '../../../components/InsHeaderConnect/InsHeaderConnect';
import InsInput from '../../../components/InsInput/InsInput';
import Validate from '../../../ressources/validation';
import InsCheckbox from '../../../components/InsCheckbox/InsCheckbox';
import WeAreLL from '../../../components/WeAreLL/WeAreLL';
import ReactGA from 'react-ga'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            emailError: '',
            passwordError: '',
            rememberMe: false
        }
    }
    _connect = () => {
        const { history } = this.props
        const { email, password, rememberMe } = this.state
        login(email, password, rememberMe).then(data => {
            history.replace('/');

            // enseigne
            let enseigne = data.enseign;
            ReactGA.set({ dimension1: enseigne });

            //Secteur
            let secteur = data.sector;
            ReactGA.set({ dimension2: secteur });
            
        }, (data) => {            
            this.setState({
                passwordError: data
            })
        })
    }

    handleChange = input => e => {
        this.setState({[input] : e.target.value}) 
    }

    /**
     * Vailde le formulaire de l'étape
     */
    _validateFormStep = () => {
        const { email } = this.state
        return (
        !Validate('email', email)
        )
    }

    /**
    * Valide le champ email
    */
    _validateEmail = () => {        
        const error = Validate('email', this.state.email);
        this.setState({
            emailError: error
        })
        return !error;
    }

    /**
    * Valide le champ password
    */
    // _validatePassword= () => {
    //     const error = Validate('password', this.state.password);
    //     this.setState({
    //         passwordError: error
    //     })
    //     return !error;
    // }

    continue = e => {
        e.preventDefault();
        let valid = true;
        valid = (this._validateEmail() && valid )
        if ( valid ) {
            this._connect()       
        }
    }

    rememberMe = () => {
        this.setState({rememberMe: !this.state.rememberMe})
    }

    render() {        
        const { t } = this.props
        return (
            <div className='background_connect'>
                <InsHeaderConnect title={t("connect:connection")} />
                <form className="default-container col-flex">
                    <div className="mv20">
                        <WeAreLL/>
                    </div>
                    <InsInput 
                        onChange={this.handleChange('email')}
                        onBlur={this._validateEmail }
                        type="email" name="login_mail" 
                        placeholder={t("connect:email_address")} 
                        error={this.state.emailError}
                    />
                    <InsInput 
                        onChange={this.handleChange('password')} 
                        onBlur={this._validatePassword} 
                        type="password" 
                        name="login_password" 
                        placeholder={t("connect:password")} 
                        error={this.state.passwordError}
                    />
                    <Link 
                        to='forget-password'
                        className="forgot-password-link mt20">
                        {t('connect:forget_password')}
                    </Link>
                    <div className="bottom-container">
                        <InsCheckbox
                            label='Se souvenir de moi'
                            name='remember_me'
                            onClick={this.rememberMe}
                        />
                        <InsButton 
                            text={t("common:validate")}
                            onClick={this.continue}
                            disabled={!this._validateFormStep()}
                        />
                    </div>
                </form>
            </div>
        )
    }
}

export default withTranslation()(Login)