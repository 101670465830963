import React, { useState, useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { oneExternalCourse } from "../../../../api/account";
import InsLoader from "../../../../components/InsLoader/InsLoader";
import images from "../../../../ressources/images";
import * as moment from "moment";
import "./ProfileCourseInformation.scss";

const TYPE_WORDING = {
  VIRTUAL_COURSE: "Classe virtuelle",
  PHYSICAL_COURSE: "Formation présentielle",
  TRAINING_EVENING: "Soirée de formation",
  STORE_COURSE: "Formation en magasin",
  DIGITAL_COURSE: "Formation digitale",
  DIGITAL_EVENT: "Event digital",
};

function ProfileCourseInformation(props) {
  const { location, match, t } = props;

  const [informations, setInformations] = useState(null);
  const [firstTimestamp, setFirstTimestamp] = useState(null);
  const [dates, setDates] = useState(null);

  const momentFormat = (a) => moment(a, "DD/MM/YYYY hh:mm:ss");
  // check si date est valide: différent de minuit
  const isValid = (dt) =>
    !momentFormat(dt).isSame(momentFormat(dt).startOf("date"));

  // On récupère les informations à afficher
  // via le state passer dans le router
  // sinon via api avec l'id passer dans l'url
  useEffect(() => {
    const data = location.state?.dataFormation;
    if (data) {
      setInformations(data);
    } else {
      const id = match.params.id;
      oneExternalCourse(id).then((data) => {
        setInformations(data);
      });
    }
  }, [location.state, match.params.id]);

  // on reformate session_shedule pour affichage des dates
  // On considère une date à minuit comme non valide
  useEffect(() => {
    if (informations) {
      const { session_schedule } = informations;
      let firstPeriod = false;
      let secondPeriod = false;
      let wholePeriod = false;

      session_schedule.forEach((schedule) => {
        // Check if first period is used
        const diffAM = momentFormat(schedule.am[1]).diff(
          momentFormat(schedule.am[0])
        );

        // Check if second period is used
        const diffPM = momentFormat(schedule.pm[1]).diff(
          momentFormat(schedule.pm[0])
        );

        if (diffAM > 0) {
          firstPeriod = true;
        }
        if (diffPM !== 0) {
          secondPeriod = true;
        }

        if (diffAM < 0) {
          firstPeriod = false;
          secondPeriod = false;
          wholePeriod = true;
        }

        if (!firstTimestamp) {
          if (firstPeriod || wholePeriod) {
            setFirstTimestamp(schedule.am[0]);
          } else if (secondPeriod) {
            setFirstTimestamp(schedule.pm[0]);
          }
        }
      });
    }
  }, [informations]);

  /**
   * Rendu du composant
   */
  // header: image + titre + marque + point
  const renderTop = () => {
    const { brand, point, course_type: type } = informations;
    return (
      <div className="learning_information_top">
        <div
          className="learning_container"
          style={{
            backgroundImage:
              "url(" + images["bg_formation_" + type + "_small"] + ")",
          }}
        >
          <div className="learning_item-top">
            <div className="learning_points">+{point}</div>
            <div className="learning_item-content">
              <div className="learning_brand">{brand}</div>
              <div className="learning_type">{TYPE_WORDING[type]}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // title: marque + nom de la formation
  const renderTitle = () => {
    const { course_name } = informations;
    return (
      <div className="course-info__title--container">
        <div className="course-info__title">{course_name}</div>
      </div>
    );
  };

  // dates
  const renderDates = () => {
    const { validated_at_formatted, session_schedule } = informations;
    if (!session_schedule) return null;
    if (informations.course_type === "DIGITAL_COURSE") return null;

    // if (validated_at_formatted) {
    //   return (
    //     <div className="course-date__wrapper">
    //       <div className="course-date__item">
    //         <b>{t("account:date")}</b>
    //         <span>
    //           {/* Here we just display the day, so we don't take periods into account */}
    //           {momentFormat(validated_at_formatted).format("dddd DD MMMM YYYY")}
    //         </span>
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div className="course-date__wrapper">
        <div className="course-date__item">
          {session_schedule.map((schedule, index) => (
            <Schedule key={index} schedule={schedule} />
          ))}
        </div>
      </div>
    );
  };

  const Schedule = ({ schedule }) => {
    let firstPeriod = false;
    let secondPeriod = false;
    let wholePeriod = false;
    let noEnd = false;

    // Check if first period is used
    const diffAM = momentFormat(schedule.am[1]).diff(
      momentFormat(schedule.am[0])
    );

    // Check if second period is used
    const diffPM = momentFormat(schedule.pm[1]).diff(
      momentFormat(schedule.pm[0])
    );

    if (diffAM > 0) {
      firstPeriod = true;
    }
    if (diffPM !== 0) {
      secondPeriod = true;
    }

    if (diffPM < 0) {
      noEnd = true;
    }

    if (diffAM < 0) {
      firstPeriod = false;
      secondPeriod = false;
      wholePeriod = true;
    }

    return (
      <div className="date-group">
        <b>{t("account:date")}</b>
        <span className="margin-top-dates">
          {/* Here we just display the day, so we don't take periods into account */}
          {momentFormat(schedule.am[0]).format("dddd DD MMMM YYYY")}
        </span>
        <div className="schedule">
          {firstPeriod && (
            <div className={`course-date__cols two-col"}`}>
              <div className="course-date__col">
                <b>{t("account:morning")}</b>
                {schedule.am.map((datetime, i) => (
                  <span key={i}>
                    {momentFormat(datetime).format("H[h]mm")}
                    {i !== schedule.am.length - 1 && " | "}
                  </span>
                ))}
              </div>
            </div>
          )}
          {secondPeriod &&
            (noEnd ? (
              <div className={`course-date__cols two-col"}`}>
                <div className="course-date__col">
                  <b>{t("account:afternoon")}</b>
                  <span>{momentFormat(schedule.pm[0]).format("H[h]mm")}</span>
                </div>
              </div>
            ) : (
              <div className={`course-date__cols two-col"}`}>
                <div className="course-date__col">
                  <b>{t("account:afternoon")}</b>
                  {schedule.pm.map((datetime, i) => (
                    <span key={i}>
                      {momentFormat(datetime).format("H[h]mm")}
                      {i !== schedule.pm.length - 1 && " | "}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          {wholePeriod && (
            <div className={`course-date__cols two-col"}`}>
              <div className="course-date__col">
                <b>{t("account:day")}</b>
                <span>
                  {momentFormat(schedule.am[0]).format("H[h]mm")} |{" "}
                  {momentFormat(schedule.pm[1]).format("H[h]mm")}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  // map / itinéraire: uniquement pour formation présentielle et soirée de formation
  const renderMap = () => {
    if (
      informations &&
      (informations.course_type === "PHYSICAL_COURSE" ||
        informations.course_type === "TRAINING_EVENING")
    ) {
      const {
        planAcces,
        adresse,
        codePostal,
        nom,
        ville,
      } = informations.address;
      let showAddress = false;
      if (firstTimestamp) {
        if (momentFormat(firstTimestamp).isBefore(moment())) {
          showAddress = true;
        } else if (
          momentFormat(firstTimestamp).diff(momentFormat(), "day", true) < 10
        ) {
          showAddress = true;
        }
      } else {
        showAddress = false;
      }

      return (
        <div className="course-map">
          {informations.course_type === "PHYSICAL_COURSE" && (
            <>
              <img className="course-map__icon" alt="" src={images.location} />
              <div className="course_map__name">{nom}</div>
              <a
                className="course-map__link"
                href={planAcces}
                target="_blank"
                rel="noopener noreferrer"
              >
                {adresse}
              </a>
              <div className="course-map__city">
                {codePostal} {ville}
              </div>
            </>
          )}
          {informations.course_type !== "PHYSICAL_COURSE" ? (
            showAddress ? (
              <>
                <img
                  className="course-map__icon"
                  alt=""
                  src={images.location}
                />
                <div className="course_map__name">{nom}</div>
                <a
                  className="course-map__link"
                  href={planAcces}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {adresse}
                </a>
                <div className="course-map__city">
                  {codePostal} {ville}
                </div>
              </>
            ) : (
              <>
                <img
                  className="course-map__icon"
                  alt=""
                  src={images.location}
                />
                <div className="course-map__city">{ville}</div>
              </>
            )
          ) : null}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="my_course_information bg_white">
      <div className="header">
        <Link
          className="backlink"
          to={{
            pathname: "/profile/courses",
            state: {
              transitionName: "slide_out",
              return: props.location.state.return,
            },
          }}
        >
          <img src={images.arrow_left_black} alt="" />
        </Link>
        <div className="title">{t("account:my_courses")}</div>
      </div>

      <div className="course-info__content">
        {!informations ? (
          <InsLoader />
        ) : (
          <>
            {renderTop()}
            {renderTitle()}
            {renderDates()}
            {renderMap()}
            {(informations.course_type === "VIRTUAL_COURSE" ||
              informations.course_type === "DIGITAL_EVENT" ||
              informations.course_type === "DIGITAL_COURSE") && (
              <Timer time={firstTimestamp} />
            )}
            <ButtonConnect
              type={informations.course_type}
              time={firstTimestamp}
              link={informations.eLearningLink}
              in_progress={informations.digital_in_progress}
            />
          </>
        )}
      </div>
    </div>
  );
}

/**
 * Composant du bouton
 * dans un composant à part car actualisation toute les secondes
 */
function ButtonConnect({ link, time, type, in_progress }) {
  const { t } = useTranslation();

  const momentFormat = (a) => moment(a, "DD/MM/YYYY hh:mm:ss");

  const diff = momentFormat(time).diff(moment());

  if (!link) {
    return null;
  }

  if (in_progress && (type === "DIGITAL_COURSE" ? true : diff < 3600000)) {
    return (
      <div className="course-info__btn-container">
        <a href={link} className="ins_button ins_button_default">
          {t("account:connect")}
        </a>
      </div>
    );
  } else {
    return (
      <div className="course-info__btn-container">
        <button className="ins_button ins_button_grey" disabled>
          {t("account:connect")}
        </button>
      </div>
    );
  }
}

/**
 * Composant du timer
 * dans un composant à part car actualisation toute les secondes
 */
function Timer({ time }) {
  const { t } = useTranslation();

  const momentFormat = (a) => moment(a, "DD/MM/YYYY hh:mm:ss");
  const [diff, setDiff] = useState(moment(time).diff(moment()));

  useEffect(() => {
    setDiff(momentFormat(time).diff(moment()));
    const interval = setInterval(() => {
      setDiff(momentFormat(time).diff(moment()));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  if (!time || diff < 0) {
    return null;
  }

  const formatTimer = () => {
    const duration = moment.duration(diff);
    const days = ("" + Math.floor(duration.asDays())).padStart(2, "0");
    const hours = ("" + duration.hours()).padStart(2, "0");
    const minutes = ("" + duration.minutes()).padStart(2, "0");
    const seconds = ("" + duration.seconds()).padStart(2, "0");

    return `${days}j : ${hours}h : ${minutes}m : ${seconds}s`;
  };

  return (
    <div className="course-timer__wrapper">
      <div className="course-timer__label">{t("account:remaining_time")}</div>
      <div className="course-timer">{formatTimer()}</div>
    </div>
  );
}

export default withTranslation()(ProfileCourseInformation);
