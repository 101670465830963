import './WallComponents.scss'
import React, { Component } from 'react'
import { Link } from "react-router-dom"

export class NewPostBtn extends Component {
  render() {
    let style = {
      top: this.props.top,
      bottom: this.props.bottom,
      position: this.props.position
    }
    return (
      <Link to='/wall/create' className='btn_new_post' style={style}>
      </Link>
    )
  }
}

export default NewPostBtn

