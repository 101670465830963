import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import images from '../../../ressources/images';



export class ResultGood extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      moduleCheck: false
    }
  }

  render() {
    const {alreadyDone, points, courseCompleted, formation, t} = this.props
    
    return (
      <div className="result_wrapper">
        <div className="result_container">
          <div className="result">
            {t('common:congratulation')} !
          </div>
          <div className="result_logo">
            <div className="result_points_wrapper">
              {!alreadyDone && courseCompleted 
                ?
                <>
                  <span className="result_points">+{points}</span>
                  <span className="result_points_text">points</span>
                </>
                :
                <img className="like_result" src={images.like} alt=""/>
              }
            </div>
          </div>
          <div className="result_text">
          Vous avez répondu correctement à toutes
          les questions. <br/>
          {!alreadyDone && courseCompleted ? 'Vous avez gagné '+points+' points.' : ''}
          </div>   
        </div>     
        <Link to={'/formations/'+formation} className="btn_verif">          
          {t("common:continue")} 
        </Link>
      </div>
    )
  }
}

export default withTranslation()(ResultGood)
