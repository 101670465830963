import {axiosApi} from './helper'

export function newsHp(obj) {
  return axiosApi('homepage', obj, 'get')
}
export function news(id) {
  return axiosApi('articles/'+id, {}, 'get')
}

export function addLike(id) {
  return axiosApi('article/'+id+'/addlike', {}, 'post')
}

export function getArticleComments(id) {
  return axiosApi('article/'+id+'/comments', {}, 'get')
}

export function addArticleComment(id, obj) {
  return axiosApi('article/'+id+'/comment', obj, 'post')
}

export function replyArticleComment(id, id_comment, obj) {
  return axiosApi('article/'+id+'/comment/'+id_comment+'/response', obj, 'post')
}

export function groupNews(groupId) {
  return axiosApi('articles/usergroup/'+groupId, {}, 'get')
}