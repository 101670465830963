import './Video.scss'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { checkQuestion } from '../../../api/training';

export class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allQuestions: [],
      allContent: '',  // Contenu du module
      step: 0,
      videoModal: false
    }
  }

  componentDidMount() {
    this.getDatas()
  }

  getDatas = () => {
    const {step} = this.state
    const dataContents = this.props.questions
    const dataVideo = this.props.questions[step].video
    const dataVignette = this.props.questions[step].vignette
    const dataText = this.props.questions[step].text
    const questionId = this.props.questions[step].id

    this.setState({
      allQuestions: dataContents,
      allVideos: dataVideo,
      allTexts: dataText,
      videoControl: 'pause',
      vignette: dataVignette,
      questionId
    })
  }

  openVideoModal = () => {
    this.setState({
      videoModal: true,
      videoControl: 'play'
    }, () => {this.endVideo()})
  }

  closeVideoModal = () => {
    this.setState({videoModal: false})
  }

  videoControls = () => {
    let video = document.getElementById("learn_video_modal")
    if(video.paused) {
      video.play()
      this.setState({videoControl: 'play'})
    } else {
      video.pause()      
      this.setState({videoControl: 'pause'})
    }
  }

  endVideo = () => {
    let video = document.getElementById("learn_video_modal")
    video.onended = () => this.setState({videoControl: 'end'})
  }

    /**
   * Passe au mini-jeu suivant
   */
  nextStep = () => {
    const {step, questionId} = this.state
    const { moduleId } = this.props
    checkQuestion(moduleId, questionId, ["compris"]).then((data) =>{
      if (step < (this.state.allQuestions.length - 1)) {
        this.props.nextQuestion(step)
        this.setState({
          step: step+1,
        }, () => {this.getDatas()})
      } else {
        this.props.nextQuizz()
        this.setState({
          step: 0
        })
        this.props.nextQuestion(step)
      }
    })
  }

  render() {
    const {allVideos, allTexts, vignette, videoControl, videoModal} = this.state
    const {t} = this.props
    return (
      <div className="content_wrapper">
        <div className="video_container">
          <div className="text">
            {allTexts}
          </div>
          <div className={'video '+videoControl}  onClick={this.openVideoModal}>
            {vignette !== null ? 
            <img id="learn_video" alt="" src={process.env.REACT_APP_URL+vignette}/> 
            :
            <video id="learn_video" preload="auto" src={process.env.REACT_APP_URL+allVideos}></video>
            }
          </div>
        </div>
        <div className="btn_verif" onClick={this.nextStep}>          
          {t("common:understood")} !
        </div>
        {videoModal && 
          <div className="modal_video">
            <div className="close_video_modal" onClick={this.closeVideoModal}></div>
            <div className={'video '+videoControl}  onClick={this.videoControls}>
              <video id="learn_video_modal" autoPlay preload="auto" src={process.env.REACT_APP_URL+allVideos}></video>
              {/* <video id="learn_video_modal" preload="auto" src="https://interactive-examples.mdn.mozilla.net/media/examples/flower.webm"></video> */}
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(Video)
