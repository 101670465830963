import {axiosApi} from './helper'

export function getBrandsList(obj) {
  return axiosApi('brands', obj, 'get')
}

export function getCourseByBrands(id,obj) {
  return axiosApi('course/brand/'+id, obj, 'get')
}

export function getArticlesByBrands(id,obj) {
  return axiosApi('articles/brand/'+id, obj, 'get')
}

export function getBrandById(id,obj) {
  return axiosApi('brand/'+id, obj, 'get')
}

export function getArticleById(id,obj) {
  return axiosApi('articles/'+id, obj, 'get')
}

export function getProductsByBrand(id,obj) {
  return axiosApi('product-catalog/brand/'+id, obj, 'get')
}

export function getVideosByBrand(id,obj) {
  return axiosApi('videos/brand/'+id, obj, 'get')
}

export function statVideo(id) {
  return axiosApi('videos/'+id, {}, 'get')
}

export function statArticles(id) {
  return axiosApi('articles/'+id, {}, 'get')
}

export function getProductCatalogPDF(pdf) {
  return axiosApi('product-catalog-pdf/'+pdf, {}, 'get')
}