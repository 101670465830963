import React, { Component } from 'react'
import './BrandArticlesItem.scss'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

export class BrandArticlesItem extends Component {

  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    picture: PropTypes.string,
    id: PropTypes.number,
    content: PropTypes.string,
  }

  render() {

    const {brandId, id, t} = this.props
    let urlArticle = brandId + '/' + id;

    return (
      <div>
        <Link className='articlelist_link' 
          to={{ 
          pathname: '/articles/'+urlArticle, 
          state: { transitionName: "slide_in"}
          }}
        >
          <div className="article_picture">
            <img className="picture" src={process.env.REACT_APP_URL+this.props.picture} alt={this.props.title}/>
          </div>
          <p className="title">{this.props.title}</p>
          <p className="subtitle">{this.props.subtitle}</p>
          <p className="discover">{t('common:discover')}</p>
          <p className="final-bar"></p>
        </Link>
      </div>
    )    
  }
}

export default withTranslation()(BrandArticlesItem)
