import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router";

import './InsButton.scss'

class InsButton extends Component {
  
  static propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['default', 'outline', 'white']),
    disabled: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func,
    replace: PropTypes.bool,
    state: PropTypes.any
  }
  static defaultProps = {
    disabled: false,
    type: "default",
    replace: false
  }

  _customLink = () => {
    const { link, replace, history, state } = this.props;
    if(replace){
      history.replace(link, state);
    } else {
      history.push(link, state);
    }
  }

  render() {
    const { text, disabled, link, onClick, type } = this.props;
    let className = `ins_button ins_button_${type}`

    return (
      <button 
        className={className} 
        disabled={disabled}
        onClick={link ? this._customLink : onClick }
      >
        {text}
      </button>
    )
  }
}

export default withRouter(InsButton)
