import React from 'react';
import './WeAreLL.scss';
import images from '../../ressources/images';

const WeAreLL = () => (
	<div className='we-are-ll'>
		<img src={images.we_are_lol_white} alt="logo white" /> 
	</div>
);

export default WeAreLL