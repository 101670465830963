import './WallEdit.scss'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { editPost, getPost } from '../../../../api/wall';
import { Redirect } from "react-router-dom"
import InsLoader from '../../../../components/InsLoader/InsLoader';
import { Carousel } from 'react-responsive-carousel';

class WallEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      post: [],
      loader: true,
      focus: false,
      contentHeight: 5
    }

    this.postId = this.props.match.params.id
  }

  componentDidMount() {
    this.getPostDatas()
  }

  getPostDatas = () => {
    getPost(this.postId).then((data) => {
      this.setState({
        post: data,
        loader: false,
      }, () => {
        let scrollHeight = this.refs['content'].scrollHeight
        this.refs['content'].focus()
        this.refs['content'].scrollIntoView()
        this.setState({contentHeight: scrollHeight})
      })
    })  
  }

   /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history } = this.props;
    history.replace('/wall/p/'+this.postId)
  }

  onFocus = () => {
    this.setState({focus: true})
  }

  _handleChange = input => e => {    
    const value = e.target.value
    let scrollHeight = this.refs['content'].scrollHeight
    this.setState({
      [input] : value,
      contentHeight: scrollHeight
    })
  }

  editPost = () => {
    const{legend} = this.state
    let obj = {}
    obj.content = legend

    editPost(this.postId, obj).then(() => {
      const { history } = this.props;
      history.replace('/wall/p/'+this.postId)
    })
  }
  
  render() {
    // const {t} = this.props
    const {focus, post, loader, contentHeight} = this.state
    const {files} = post
    let contentStyle = {height: contentHeight - 10}

    return (
      <div className='bg_white wall_create edit'>
        <div className="wall_edit_header">
          <div className='back_link' onClick={this._goBack}></div>
          <div 
            className="valid_post"
            onClick={this.editPost}
          >
            {/* {t('common:next')} */}
            Suivant
          </div>
        </div>
        <div className="container">
          {loader ?
            <InsLoader/>
            :
              post.mine ?
                <div>
                  <div className="media">
                    <Carousel
                      className="carousel"
                      showArrows={false}
                      showThumbs={false}
                      showStatus={files.length > 1}
                      statusFormatter={(current, total) => current + ' / ' + total}
                      showIndicators={files.length > 1}
                    >
                      {files.map((file, index) => (
                          file.type === "video/mp4" ?
                          <div key={index}>
                            <video className="blur" playsInline preload="auto" poster={process.env.REACT_APP_URL + file.name.thumbnail} src={process.env.REACT_APP_URL + file.name.name} alt="" />
                            <video className="not-blur" playsInline preload="auto" poster={process.env.REACT_APP_URL + file.name.thumbnail} src={process.env.REACT_APP_URL + file.name.name} alt=""/>
                          </div>
                          :
                          <img src={process.env.REACT_APP_URL + file.name.name} alt="" key={index}/>  
                      ))}
                    </Carousel>
                  </div>
            
                  <div className={focus ? 'legend focus' : 'focus'}>
                    <textarea ref="content" style={contentStyle} defaultValue={post.content}  onFocus={this.onFocus} onChange={this._handleChange('legend')} className="content_post edit" maxLength="2200"/>
                  </div>
                </div> 
              :
                <Redirect to="/wall" />
          }
        </div>
      </div> 
    )
  }
}

export default withTranslation()(WallEdit)
