import React from 'react'
import './InsAutocomplete.scss'

export default function InsAutocomplete({values, visible, onClick}) {
 
  return (
    <div className={visible ? 'autocomplete show' : 'autocomplete'}>
        { values && visible ? values.map(
            (item, index) => ( 
              <div key={index} 
                className="autocomplete_choice" 
                onClick={() => {onClick(item.name || item)}}
              >
                {item.name || item}
              </div>           
            ) 
          ) :
          null
        }
    </div>
  )
}
