import React from 'react'

const OrderItem = (props) => {
  const {picture, brand, name, points, quantity} = props
  return (
    <div className="order_item">
      <div className="product_picture">
        <img src={process.env.REACT_APP_URL + picture} alt=""/>
      </div>

      <div className="product_infos">
        <div className="product_brand">{brand}</div>
        <div className="product_name">{name}</div>
        <div className="product_price">{points} points</div>
      </div>

      <div className="product_qty">
        <div className="qty">x{quantity}</div>
      </div>
    </div>
  )
}

export default OrderItem
