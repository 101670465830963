import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/configureStore";
import RootRouter from "./RootRouter";
import { loadReCaptcha } from "react-recaptcha-v3";
import "./ressources/styles/styles.scss";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import { Offline, Online } from "react-detect-offline";
import { maintenance } from "./api/splashscreen";
import NotConnected from "./components/NotConnected/NotConnected";
import * as moment from "moment";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loader: true,
      maintenance: false,
    };

    moment.locale("fr", {
      months: "Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre".split(
        "_"
      ),
      monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split(
        "_"
      ),
      monthsParseExact: true,
      weekdays: "Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi".split(
        "_"
      ),
      weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
      weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
      weekdaysParseExact: true,
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH[h]mm",
        LLLL: "dddd D MMMM YYYY - HH[h]mm",
      },
      calendar: {
        sameDay: "[Aujourd’hui à] LT",
        nextDay: "[Demain à] LT",
        nextWeek: "dddd [à] LT",
        lastDay: "[Hier à] LT",
        lastWeek: "dddd [dernier à] LT",
        sameElse: "L",
      },
      relativeTime: {
        future: "%s",
        past: "%s",
        s: "%ds",
        ss: "%ds",
        m: "%dm",
        mm: "%dm",
        h: "%dh",
        hh: "%dh",
        d: "%dj",
        dd: "%dj",
        M: "%d mois",
        MM: "%d mois",
        y: "%d an",
        yy: "%d ans",
      },
      dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
      ordinal: function (number) {
        return number + (number === 1 ? "er" : "e");
      },
      meridiemParse: /PD|MD/,
      isPM: function (input) {
        return input.charAt(0) === "M";
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? "PD" : "MD";
      },
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // Used to determine first week of the year.
      },
    });
  }

  componentDidMount() {
    loadReCaptcha('6Ldg5rQUAAAAAGd82vPYHsmf6gRaPdFGNHMnGcCQ');
    this.checkMaintenance()
    window.addEventListener('offline', function(e) {
      console.log('offline'); });
  }

  checkMaintenance = () => {
    maintenance().then((data) => {
      setTimeout(() => {
        this.setState({ loader: false, maintenance: data });
      }, 1000);
    });
  };

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Online polling={{ url: "/internet-check.txt", interval: 10000 }}>
            {this.state.loader ? (
              <SplashScreen />
            ) : (
              <RootRouter maintenance={this.state.maintenance} />
            )}
          </Online>
          <Offline polling={{ url: "/internet-check.txt", interval: 10000 }}>
            <NotConnected />
          </Offline>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
