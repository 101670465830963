import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import images from "../../../../ressources/images"
import './ProfilePoints.scss'
import { pointsHistory } from '../../../../api/account'
import InsLoader from '../../../../components/InsLoader/InsLoader'

function ProfilePoints(props) {
  const [history, setHistory] = useState({})
  const [loader, setLoader] = useState(true)
  const {account, t} = props

  const getPointsHistory = () => {
    pointsHistory().then(data => {
      setHistory(data)
      setLoader(false)
    })
  }

  useEffect(
    () => {
      getPointsHistory()  
    }, []
  );

  return (
    <div className="my_points bg_white">
      <div className="header">
        <Link className='backlink' to={{ pathname: '/profile', state: { nav: "points",transitionName: "slide_out" }}}>
          <img src={images.arrow_left_black} alt="" />
        </Link>
        <div className="title">
          {t('account:points_history')}
        </div>
      </div>

      <div className="points_circle">
        <div className="points">{account.points}</div>
        <div className="points_txt">points</div>
      </div>

      <div className="content">
        <div className="table">
          <div className="thead">
            <div className="cell title">{t('common:title')}</div>
            <div className="cell">Date</div>
            <div className="cell">Points</div>
          </div>
          <div className="tbody">
            {loader ?
              <InsLoader/>
            :
              <div>
               { history.map((el, index) => (
                  <div className="tr" key={index}>
                    <div className="cell title">
                      <div>{el.action}</div>
                      <b>{el.course_type && t('courses:'+el.course_type)}</b>
                    </div>
                    <div className="cell">{el.date}</div>
                    <div className="cell">{el.points}</div>
                  </div>
               ))}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(ProfilePoints)))
