import React, { useState, useEffect } from 'react'
import images from '../../ressources/images'
import { withTranslation } from 'react-i18next'
//api
import { getBrandsList } from '../../api/brands';
import './Filters.scss'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/accountActions';



const FILTERS_THEMES_DATA = [
  {id: "VIRTUAL_COURSE", name: "Classe virtuelle"},
  {id: "PHYSICAL_COURSE", name: "Formation présentielle"},
  {id: "TRAINING_EVENING", name: "Soirée de formation"},
  // {id: "STORE_COURSE", name: "Formation en magasin"},
  {id: "DIGITAL_COURSE", name: "Formation digitale"},
  {id: "DIGITAL_EVENT", name: "Event digital"},
]

function Filters(props) {
  const {t} = props
  const [brandsList,setBrandsList] = useState(null)
  const [themesList,setThemesList] = useState(null)
  const [filter, setFilter] = useState('brands')
  const [filtersApplied, setFiltersApplied] = useState(props.accountFiltersList);
  
  const getData = () => {
    getBrandsList().then(data => {
      setBrandsList(data);
    });
    // getAllThemesList().then(data => {
    //   setThemesList(data);
    // });
    setThemesList(FILTERS_THEMES_DATA)
  }

  const addFilter = (elmt) => {    
    if(filtersApplied[filter].indexOf(elmt.target.name) !== -1) {
      let array = [...filtersApplied[filter]]; // make a separate copy of the array
      let index = array.indexOf(elmt.target.name)
      if (index !== -1) {
        array.splice(index, 1);
        setFiltersApplied({...filtersApplied, [filter]: array});
      }
    }
    else {
      setFiltersApplied( {...filtersApplied, [filter]: [...filtersApplied[filter], elmt.target.name]})
    } 
  }

  const checkButton = () => {
    
    let disable = true
    if(filtersApplied.brands.length > 0 || filtersApplied.themes.length > 0) {
      disable = false
    }

    return disable
  }

   //envoie formulaire donc envoie les filtres choisis a redux
   const handleSubmit = e => {
    e.preventDefault();
    //on envoie a redux les filtres
    props.setAccountFilters(filtersApplied);
    props.applyFilters(filtersApplied)
  }
  const handleReset= e => {
    e.preventDefault()
    setFiltersApplied({brands:[], themes:[]})
    props.resetAccountFilters()
    props.applyFilters({brands:[], themes:[]})
  }
  
  //Run function to component did mount
  useEffect(
    () => {
      getData();
    },
    [] //Afin d'éviter les boucles infini
  );
  return (
    <div className={props.filters ? "filters active" : "filters"}>
    <div className='filter_title'>
      <div className="close_filter" onClick={props.close}>
        <img src={images.closeCross} alt='' />
      </div>
      <h2>{t('common:filter')}</h2>
    </div>
    <div className='filter_nav'>
      <div className={filter === 'brands' ? 'filter active' : 'filter'} onClick={() => {setFilter('brands')}}>{t('wall:brand')}</div>
      {/* <div className={filter === 'themes' ? 'filter active' : 'filter'} onClick={() => {setFilter('themes')}}>{t('wall:Theme')}</div> */}
    </div>
    <form onReset={handleReset} onSubmit={handleSubmit}>
    <div className="filters_list">
      {
        brandsList && filter === 'brands' &&
        brandsList.map((brand) =>
          <div key={brand.id}>
            <input
            checked={filtersApplied['brands'].includes(""+brand.id)}
            type="checkbox"
            id={brand.name}
            name={brand.id}
            onChange={(e) => addFilter(e)}
            />
            <label className='one_filter' htmlFor={brand.name}>{brand.name}
              <img src={images.checked_black} alt='' />
            </label>
          </div>
        )
      }
      {
        themesList && filter === 'themes' &&
        themesList.map((theme) =>
          <div key={theme.id}>
            <input
            type="checkbox"
            id={theme.name}
            name={theme.id}
            checked={filtersApplied['themes'].includes(""+theme.id)}
            onChange={(e) => addFilter(e)}
            />
            <label className='one_filter' htmlFor={theme.name}>{theme.name}
              <img src={images.checked_black} alt='' />
            </label> 
          </div>
        )
      }
    </div>
    <div className="filter_end_form">
      <input type="submit" disabled={checkButton()} value={t('wall:Validate')} />
      <input type="reset" value={t('wall:Reset')} />
    </div>
    </form>
    
  </div>
  )
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  accountFiltersList: state.accountFilters
})

// Le tableau est modifié on le modifie dans le store
const mapDispatchToProps  = (dispatch) => {
  return {
    setAccountFilters: (filtersApplied) => { dispatch(actions.setAccountFilters(filtersApplied)) },
    resetAccountFilters: () => {dispatch(actions.resetAccountFilters())}
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Filters))