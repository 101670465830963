import * as types from "./actionsTypes";

export function setTokens(data) {
  return {
    type: types.AUTH_SET_TOKEN,
    data: data
  }
}

export function delTokens() {
  return {
    type: types.AUTH_DEL_TOKEN,
  }
}

export function setRememberMe(email, password) {
  return {
    type: types.AUTH_SET_REMEMBER_ME,
    data: {
      email: email, 
      password: password
    }
  }
}

export function delRememberMe() {
  return {
    type: types.AUTH_DEL_REMEMBER_ME,
  }
}