import {axiosApi} from './helper'
import { store } from '../store/configureStore'
import * as accountActions from "../store/actions/accountActions";

// Création de compte
export function createAccountAPI(data){
  delete data.hasCode
  // delete data.code
  return axiosApi('account', data, 'post')
}

// Vérification de l'email pour la demande de nouveau mot de passe
export function forgotPassword(mail){
  let obj = {
    "mail" : mail,
  }
  return axiosApi('connect/forget-password', obj, 'post')
}

// Nouveau mot de passe
export function NewPasswords(obj, token){
  return axiosApi('account', obj, 'put', {'Authorization': `Bearer ${token}`})
}

// Reset mot de passe
export function resetNewPassword(password, token){
  let obj = {
    "password" : password,
    "token": token
  }
  return axiosApi('reset-password', obj, 'post')
}

export function autocomplete(obj) {
  return axiosApi('auto-suggest-store', obj, 'get')
}

export function accountData() {
  return new Promise((resolve, reject) => {
    // RECUPERATION ACCOUNT REDUX
    let account = store.getState().account
    let isUpdate = Date.now() - account.lastUpdated < 300000
    if(isUpdate) {
      resolve(account)
    } else {      
      axiosApi('account', {}, 'get').then((data) => {
        store.dispatch(accountActions.setAccountDatas(data))
        resolve(data)
      },error => {
        reject(error)
      })            
    }
  })
}

export function refreshAccountData() {
  return new Promise((resolve, reject) => {
    axiosApi('account', {}, 'get').then((data) => {
      store.dispatch(accountActions.setAccountDatas(data))
      resolve(data)
    },error => {
      reject(error)
    }) 
  })           
}

export function sendAccount(obj) {
  return axiosApi('account', obj, 'put')
}

export function getSponsorshipList(obj) {
  return axiosApi('sponsorships', obj, 'get')
}

export function sendMail(obj) {
  return axiosApi('sponsorMail', obj, 'post')
}

export function getUserPosts() {
  return axiosApi('user/posts', {}, 'get')
}

/**
 * GET USER ORDERS
 */
export function getUserOrders(number_page, offset) {
  return axiosApi('orders/'+number_page+'/'+offset, {}, 'get')
}

/**
 * GET USER ORDER
 */
export function getUserOrder(id) {
  return axiosApi('order/'+id, {}, 'get')
}

export function pointsHistory() {
  return axiosApi('profile/history', {}, 'get')
}

export function allCourses(obj) {
  return axiosApi('my-courses', obj, 'get')
}

export function oneExternalCourse(id) {
  return axiosApi('external-course/session/'+id, {}, 'get')
}

export function allGroups() {
  return axiosApi('profile/usergroups', {}, 'get')
}

export function groupVideos(groupId) {
  return axiosApi('videos/usergroup/'+groupId, {}, 'get')
}
