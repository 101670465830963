// AUTH
export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN"
export const AUTH_DEL_TOKEN = "AUTH_DEL_TOKEN"
export const AUTH_SET_REMEMBER_ME = "AUTH_SET_REMEMBER_ME"
export const AUTH_DEL_REMEMBER_ME = "AUTH_DEL_REMEMBER_ME"

// ACCOUNT
export const ACCOUNT_SET_DATAS = "SET_ACCOUNT_DATAS"
export const ACCOUNT_DEL_DATAS = "DEL_ACCOUNT_DATAS"
export const SET_ACCOUNT_FILTERS = "SET_ACCOUNT_FILTERS"
export const RESET_ACCOUNT_FILTERS = "RESET_ACCOUNT_FILTERS"

// CREATE ACCOUNT
export const CREATE_ACCOUNT_UPD_INFO = "CREATE_ACCOUNT_UPD_INFO"
export const CREATE_ACCOUNT_DEL_INFO = "CREATE_ACCOUNT_DEL_INFO"

// NAVIGATION
export const NAVIGATION_SET_PATH = "NAVIGATION_SET_PATH"
export const NAVIGATION_SET_PATHBRAND = "NAVIGATION_SET_PATHBRAND"

// SCORM
export const SET_SCORM = "SET_SCORM"
export const REFRESH_SCORM = "REFRESH_SCORM"

//WALL LIST FILTERS
export const SET_FILTERS = "SET_FILTERS"
export const RESET_FILTERS = "RESET_FILTERS"

// NOTIFICATIONS
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
