import {axiosApi} from './helper'

/**
 * GET ALL PRODUCTS
 */
export function getProducts(offset) {
  return axiosApi('gifts', {offset}, 'get')
}

/**
 * GET PRODUCT BY ID
 * @param {*} id 
 */
export function getProduct(id) {
  return axiosApi('gift/'+id, {}, 'get')
}

/**
 * ADD PRODUCT TO CART
 * @param {*} ean EAN Product
 * @param {*} obj Quantity
 */
export function addProductToCart(ean, obj) {
  return axiosApi('cart/gift/'+ean, obj, 'put')
}

/**
 * GET USER CART
 */
export function getCart() {
  return axiosApi('cart', {}, 'get')  
}

/**
 * REMOVE PRODUCT TO CART
 * @param {*} ean EAN Product
 * @param {*} obj Quantity
 */
export function removeProductToCart(ean, obj) {
  return axiosApi('cart/gift/'+ean, obj, 'delete')
}

/**
 * SEND THE ORDER
 */
export function sendUserOrder(obj) {
  return axiosApi('order', obj, 'post')
}

/**
 * GET CATEGORIES
 */
export function getCategories() {
  return axiosApi('categories', {}, 'get')
}

/**
 * SET CATEGORIES
 */
export function setFilter(obj) {
  return axiosApi('gifts/filtering', obj, 'get')
}


export function groupProduct(groupId) {
  return axiosApi('product-catalog/usergroup/'+groupId, {}, 'get')
}