import './Contact.scss'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import InsInput from '../../../components/InsInput/InsInput'
import { connect } from 'react-redux'
import InsButton from '../../../components/InsButton/InsButton'
import InsSelect from '../../../components/InsSelect/InsSelect'
import images from '../../../ressources/images';
import { sendContactMail } from '../../../api/contact'
import { Link } from "react-router-dom"

export class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMsg: {},
      profileDatas: {},
      contact: {
        firstname: '',
        name: '',
        store: '',
        mail: '',
        object: '',
        subject: '',
      },
      error: true,
      contentHeight: window.innerHeight
    }

    this.error = true

    this.subject = [
      {value:'Commande',label:'Commande'},
      {value:'Changement de magasin',label:'Changement de magasin'},
      {value:'Formation / Concours',label:'Formation / Concours'},
      {value:'Autre',label:'Autre'}
    ]
  }

  componentDidMount() {
    this.getAccount()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }

  /**
   * Récupere les informations du compte
   */
  getAccount = () => {
    const {account} = this.props
    this.setState({
      profileDatas: {...account}
    }, () => {
      this.setState({
        contact: {
          name: account.lastname,
          firstname: account.firstname,
          store: account.store,
          type: "Commande",
          mail: account.email,
          object: '',
          subject: '',
          send: false
        }
      }, () => {        
        this.checkError()
      })
    })
  }

  handleChange = (params) => (event) => {
    const value = event.target.value
    this.setState(prevState => ({
      contact: {                   
          ...prevState.contact,    
          [params]: value
      },
      errorMsg: {
        ...prevState.errorMsg,
        [params]: value ? "" : "merci de renseigner cette information"
      }
    }), () => {this.checkError()})
  }

  // SET THE CATEGORY 
  handleSelectSubject = input => e => {
    let {contact} = this.state
    const value = e.target.value
    this.setState(prevState => ({
      contact: {                   
        ...prevState.contact,    
        type: value
      }
    }))
    contact.subject = e.target.value
    this.checkError()
  }

  checkError = () => {
    let {contact} = this.state
    if(contact.firstname
      && contact.name
      && contact.store
      && contact.mail
      && contact.object
      && contact.subject) {
        this.setState({error: false})
      }else {
        this.setState({error: true})
      }
  }

  sendMail = (e) => {
    let {contact} = this.state
    e.preventDefault();
    sendContactMail(contact).then(() => {
      this.setState({send: true})
    })
  }

  render() {

    const {history, t} = this.props
    const {contentHeight, error, errorMsg, profileDatas, send} = this.state
    const {email, firstname, lastname} = profileDatas;
    

    return (
      <div className='contact_page'>
        <div className="header_contact">
          <div className='back_link' onClick={(e) => history.goBack()}></div>
          <div className="title">
            {t('common:contact')}
          </div>
          <div className="img_contact">
            <img src={images.contact} alt=""/>
          </div>
        </div>

        <form className="contact_form" onSubmit={this.sendMail} style={{height: contentHeight - 175, overflow: "auto"}}>

        <InsInput     
            name="firstname"        
            type="text"
            placeholder={t("account:firstname")}
            defaultValue={firstname ? firstname : ''}
            onChange={this.handleChange('firstname')}
            onBlur={this.handleChange('firstname')}
            error={errorMsg.firstname}
          />

          <InsInput   
            name="name"           
            type="text"
            placeholder={t("account:name")}
            defaultValue={lastname ? lastname : ''}
            onChange={this.handleChange('name')}
            onBlur={this.handleChange('name')}
            error={errorMsg.name}   
          />

          <InsInput    
            name="store"
            type="text"
            placeholder={t("account:shop_name")}
            defaultValue={profileDatas.store ? profileDatas.store['name'] : ''}
            onChange={this.handleChange('store')}
            onBlur={this.handleChange('store')}
            error={errorMsg.store}
          />

          <InsInput    
            name="email"          
            type="text"
            placeholder={t("account:email")}
            defaultValue={email ? email : ''}
            onChange={this.handleChange('mail')}
            onBlur={this.handleChange('mail')}
            error={errorMsg.mail}
          />
          <div className="notice">{t('common:answer_email')}</div>

          <InsSelect
            className="subject_select"
            name="subject"
            onChange={this.handleSelectSubject()}
            values={this.subject}
            placeholder={t('common:contact_select_placeholder')}
          />

          <div className="ins_input mb20">
            <textarea 
              className='contact_msg mb20'
              rows="6"
              placeholder={t('common:contact_placeholder')}
              onFocus={(e) => e.target.placeholder = ""}
              onChange={this.handleChange('object')}
              onBlur={(e) => {this.handleChange('object');e.target.placeholder = t('common:contact_placeholder')}}
            />
            {errorMsg.object && <div className='error-msg'>{errorMsg.object}</div>}
          </div>

          <InsButton
            text={t('common:send')}
            type="default"
            disabled={error}
          />
          
          <input className="reset" type="reset" value={t('common:cancel')} onClick={() => this.props.history.goBack()}/>
        </form>

        {send &&
          <div className="send_confirm">
            <div className="img_contact">
              <img src={images.contact} alt=""/>
            </div>
            <div className="msg">
              {t('common:email_send')}
            </div>
            <Link to={{ pathname: '/', state: { transitionName: "slide_out" }}}>
              <InsButton
                text={t('connect:return_home')}
                type="default"
              />
            </Link>
          </div>
        }
      </div>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(Contact)))