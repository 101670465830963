import {axiosApi} from './helper'

export function createPostApi(obj) {
  return axiosApi('post', obj, 'post', {"Content-Type": false})
}

export function getAllThemes(id) {
  return axiosApi('themes/'+id, {}, 'get')
}

export function getProductsByThemes(obj) {
  return axiosApi('auto-suggest-product', obj, 'get')
}

export function getAllProducts(obj) {
  return axiosApi('products', obj, 'get')
}

export function getAllPosts(obj) {
  return axiosApi('posts', obj, 'get')
}

export function getPost(id) {
  return axiosApi('post/'+id, {}, 'get')
}

export function getPostWithFilter(obj) {
  return axiosApi('posts/filtering', obj, 'post')
}

export function getAllThemesList(obj) {
  return axiosApi('themes', obj, 'get')
}

export function deletePost(id) {
  return axiosApi('post/'+id+'/delete', {}, 'delete')
}

export function editPost(id, obj) {
  return axiosApi('post/'+id+'/edit', obj, 'put')
}

export function addLike(id) {
  return axiosApi('post/'+id+'/addlike', {}, 'post')
}

export function getPostComment(id) {
  return axiosApi('post/'+id+'/comments', {}, 'get')
}

export function addComment(id, obj) {
  return axiosApi('post/'+id+'/comment', obj, 'post')
}

export function replyComment(id, id_comment, obj) {
  return axiosApi('post/'+id+'/comment/'+id_comment+'/response', obj, 'post')
}