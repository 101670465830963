import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import images from '../../../../../ressources/images'
import './Group.scss'
import GroupsNav from '../../../../../components/Groups/GroupsNav'
import InsLoader from '../../../../../components/InsLoader/InsLoader'
import BrandVideosItem from '../../../../../components/Brands/BrandVideosItem/BrandVideosItem'
import { groupVideos } from '../../../../../api/account'

function GroupVideos(props) {
  const [videosList, setVideosList] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    const groupId = props.match.params.id
    groupVideos(groupId).then(data => {
      setVideosList(data)
      setLoad(false)
    })
  }, [props.match.params.id])

  return (
    <div className="bg_white">
      <div className="group_header">
        <Link className="backlink" to={{ pathname: '/profile/groups', state: { transitionName: "slide_out" }}}>
          <img src={images.arrow_left_black} alt="" />
        </Link>
        {props.history.location.state.groupName}
      </div>
      <GroupsNav 
        groupId={props.match.params.id}
        groupName={props.history.location.state.groupName}
      />
      <div className="group_content">
      { load ?
      <InsLoader/>
      :
      videosList.length > 0 
        ?
          videosList.map(
          (video, index) => 
            <BrandVideosItem
              key={index} 
              brandId={video.brand_id} 
              VideoId={video.id} 
              VideoPicture={video.picture} 
              VideoTitle={video.title} 
              VideoSubtitle={video.subtitle} 
              YoutubeId={video.youtube_id}
            />
          )
        :
        <div className="back_soon">
          <div>En rupture de stock…</div>
          <div>revenez bientôt </div>
          <span className="smile" role="img" aria-label="">&#128578;</span>
        </div>
      }
      </div>
    </div>
  )
}

export default GroupVideos
