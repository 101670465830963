export function getUrlParameter(query = window.location.search.substring(1)) {
  let vars = query.split("&");
  let query_string = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      let arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

export function getVideoPoster(videoSrc) {
  return new Promise(resolve => {
    let video = document.createElement('video')
    let canvas = document.createElement('canvas')
    let ios = isIos()
    video.src = videoSrc
    video.load()


    let i = 0;
    
    const newLocal = () => {
      i++;
      video.currentTime = 0;
      video.width = 150
      // Set canvas dimensions same as video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // document.getElementById('test').appendChild(canvas);
      let ctx = canvas.getContext('2d');
      // document.getElementById('test').appendChild(video);
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      if (i > 1 || ios) {
        video.removeEventListener('canplay', newLocal);
        resolve(canvas.toDataURL());
      }      
    };

    video.addEventListener('canplay', newLocal)
    
});
}

export function isIos() {  
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}