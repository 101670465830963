import * as types from "./actionsTypes";

export function setScormValue(key, value, id) {
  return {
    type: types.SET_SCORM,
    data: {key, value, id}
  }
}

export function refreshScormValue(id) {
  return {
    type: types.REFRESH_SCORM,
    data: {id}
  }
}
