import React, { Component } from 'react'
import CreateHeader from './Create/CreateHeader'
import CreateInputFiles from './Create/CreateInputFiles'
import InsInput from '../InsInput/InsInput'
import InsAutocomplete from '../InsAutocomplete/InsAutocomplete'
import { getBrandsList } from '../../api/brands'
import { getAllThemes, getProductsByThemes, getAllProducts } from '../../api/wall'
import * as loadImage from 'blueimp-load-image'

export class WallForm extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      brandsList: [],
      themesList: [],
      files: [],
      filesUpload: [],
      autocomplete: [],
      acFocus: '',
      brand: '',
      product_name: '',
      theme: ''
    }
  }
  

  componentDidMount() {
    this.getAllbrands()
  }
  _handleChange = input => e => {
    const value = e.target.value

    this.setState({
      [input] : value
    }, () => {
      if(this.state.brand && !this.state.theme) {
        this.getBrandThemes()
      }
    })
  }

  _handleChangeProduct = input => e => {
    const value = e.target.value

    this.setState({
      [input] : value
    }, () => {
      if(this.state.product_name.length >= 5) {  
        if(this.handleChangeTimer){
          clearTimeout(this.handleChangeTimer)
        }
        this.handleChangeTimer = setTimeout(() => {        
          this.autocompleteRequest()
        }, 500);
      }
    })
  }

  /**
   * Recuperation des fichiers selectionnés
   */
  _handleInputFiles = async (e) => {
    const {files} = this.state;
    if(e.target.files.length + files.length <= 10) {
      if(e.target.files.length > 10) {
        for(let i=0; i<10 ;i++){
          files.push(e.target.files[i]);
        }
        this.setState({
          files
        }, () => {
          this.setState({filesUpload: []})
          this.loadImageUrl(files);
        })
      } else {
        for(let i=0; i<e.target.files.length ;i++){
          files.push(e.target.files[i]);
        }
        this.setState({
          files
        }, () => {
          this.setState({filesUpload: []})
          this.loadImageUrl(files);
        })
      }
    }
  }

  loadImageUrl = (files) => {
    files.forEach(file => {
      if(file.type !== 'image/jpeg' && file.type !== 'image/gif' && file.type !== 'image/png') {
        this.setState(prevState =>({
          filesUpload: [...prevState.filesUpload, file]
        }))
      } else {
        loadImage(
          file,
          (image) => {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const base64 = canvas.toDataURL();

            this.setState(prevState =>({
              filesUpload: [...prevState.filesUpload, base64]
            }))
          },
          { 
            orientation: true,
            maxWidth: 1000,
            maxHeight: 1000
          }
        );
      }
    })
  }

    /**
   * Affichage autocomplétion
   */
  showAutocomplete = name => () => {
    clearTimeout(this.setTimeOutAutoComplete)
    this.setState({acFocus: name})
  }

  _onClickAutocomplete = input => value => {
    this.setState({
      [input] : value,
      acAddress: false
    }, () => {      
      this.autocompleteRequest(true)
      this.showAutocomplete('') 
    })
  }

  /**
   * Récupere toutes les marques
   */
  getAllbrands() {
    getBrandsList().then((data) =>{
      this.setState({brandsList: data})
    })
  }

  getBrandThemes() {    
    const {brand} = this.state
    getAllThemes(brand).then((data) => {
      this.setState({themesList: data})
    })
  }

  /**
   * Recuperation des produits 
   */
  autocompleteRequest = (generation = false) => {
    const { product_name, brand } = this.state
    
    let obj = {}

    if(brand) {
      obj.brand_id = brand
      if(product_name.length)
        obj.name_display = product_name
  
      getProductsByThemes(obj).then((data) => {
        this.setState({autocomplete: data})
        if(generation) {
          Object.keys(data).forEach(item => {
              if(data[item].length === 1) {
                this.setState({[item]: data[item][0]})
              }
          })
        }
      })
    } else {
      if(product_name.length)
      obj.name_display = product_name

      getAllProducts(obj).then((data) => {
        this.setState({autocomplete: data})
        if(generation) {
          Object.keys(data).forEach(item => {
              if(data[item].length === 1) {
                this.setState({[item]: data[item][0]})
              }
          })
        }
      })
    }
  }
  
  /**
   * Valide le formulaire de l'étape
   */
  _validateFormStep = () => {
    const { files, brand } = this.state  
    return (
      files.length > 0 &&
      brand !== ''
    )
  }

  render() {
    const {brand, legend, theme,brandsList,themesList,acFocus,autocomplete, product_name,filesUpload} = this.state
    const {loadCreate, filesPreview, history,location,t} = this.props

    return (
      <div>
        <CreateHeader 
          loadCreate={loadCreate}
          history={history} 
          location={location} 
          validate={this._validateFormStep()} 
          t={t} 
          submitPost={() => this.props.submitPost(brand, filesUpload, product_name, legend, theme)}
        /> 
        <div className="content">
          <CreateInputFiles filesPreview={filesPreview} files={filesUpload} handleInput={this._handleInputFiles}/>
          <textarea placeholder={t("wall:legend")} name="legend" onChange={this._handleChange('legend')} maxLength="2200"></textarea>
        </div>
        <div className="select_container">

          <div className="select_wrapper">
            <i className="arrow down"></i>
            <select 
              name="brand" 
              id="brand_select" 
              onChange={(e) => { this._handleChange('brand')(e)}}
              defaultValue={t("wall:brand")}
            >
              <option value={t("wall:brand")} disabled>{t("wall:brand")}</option>
              {brandsList.map((brand, index) => (
                <option key={index} value={brand.id}>{brand.name}</option>
              ))}
            </select>
          </div>

          <div className="select_wrapper">
            <i className="arrow down"></i>
            <select 
              name="theme" 
              id="theme_select"
              onChange={(e) => { this._handleChange('theme')(e)}}
              defaultValue={t("wall:theme")}
            >
              <option value={t("wall:theme")} disabled>{t("wall:theme")}</option>
              {themesList.map((theme, index) => (
                <option key={index} value={theme.id}>{theme.name}</option>
              ))}
            </select>
          </div>

        </div>

        <div className="product_name input_container">
          <InsInput
            name="product_name"
            value={product_name}
            onChange={this._handleChangeProduct('product_name')}
            onFocus={this.showAutocomplete('product_name')}
            onBlur={() => {this.setTimeOutAutoComplete = setTimeout(() => {this.showAutocomplete('')()}, 150 )}}
            type="text"
            placeholder={t("wall:product_name")}
          />
          <InsAutocomplete 
            visible={acFocus === 'product_name'} 
            values={autocomplete}
            onClick={this._onClickAutocomplete('product_name')}
          />
        </div>

        <div className="lol">
          L'équipe We are L'Oréal Luxe se réserve le droit de modérer les contenus de cette rubrique et de supprimer un post inapproprié. 
        </div>
      </div>
    )
  }
}

export default WallForm
