import * as types from "../actions/actionsTypes";

let initialState = {
  lastUpdated: 0,
  active: false,
  address: null,
  birthdate: "JJ/MM/AAAA",
  city: null,
  email: "",
  firstname: "",
  gender: "",
  id: 1,
  isForwork: true,
  lastname: "",
  phone: "",
  picture: "/backend/uploads/user/picture/",
  points: 0,
  postal_code: null,
  store: null,
  isValidSector: true,
  ordersCount: 2,
  shopState: null,
  hasOrderLessOneHour: false
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACCOUNT_SET_DATAS:
      return({
        ...state,
        ...action.data,
        lastUpdated: Date.now()
      })
      
    case types.ACCOUNT_DEL_DATAS:
      return initialState

    case "persist/REHYDRATE":
      if(action.payload && action.payload.account) {
        return({
          ...action.payload.account,
          lastUpdated: 0
        })
      } else {
        return state
      }    
  
    default:
      return state
  }
}

export default accountReducer;