import React, { Component } from 'react'
import { news } from '../../../../api/news';
import './News.scss'
import { addLike } from '../../../../api/news';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import InsComments from '../../../../components/InsComments/InsComments';
import FormationItem from '../../../../components/FormationItem/FormationItem';
import InsLoader from '../../../../components/InsLoader/InsLoader';
import BrandArticlesItem  from '../../../../components/Brands/BrandArticlesItem/BrandArticlesItem';
import { statArticles } from '../../../../api/brands';
import Modal from 'react-modal';

export class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      articleId: this.props.match.params.id,
      news: {},
      loading: true,
      isOpen: false
    }
  }

    componentDidUpdate(){
        var el = document.querySelectorAll("button[data-url]");
        var _this = this;
        for(var i =0; i < el.length; i++) {
            el[i].onclick = function() {
                _this.openModal();
                document.getElementById('modal-content').innerHTML = '<iframe src="' + this.getAttribute('data-url') + '" style="width: 100%; height: 100%;"></iframe>';
            };
        }
    }



    componentDidMount() {
    const {articleId} = this.state
    statArticles(this.props.match.params.id)
    news(articleId).then((data) =>{
        this.setState({news: data, loading: false})      
    })
  }

   /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history, state } = this.props;
    history.replace("/", {transitionName: "zoom_out",...state});
  }

  addLike = () => {
    let {articleId} = this.state
    addLike(articleId).then((data) => {
      this.setState(prevState => ({
        news: {
            ...prevState.news,
            likes: data.like_count,
            currently_liked: data.user_liked
        }
      }))
    })
  }

  openModal () {
      this.setState({
          isOpen: true
      });

      var _this = this;
      var close = document.querySelectorAll(".close-modal");
      console.log(close);
      for(var i =0; i < close.length; i++) {
          close[i].onclick = function() {
              _this.hideModal();
          };
      }

  };

  hideModal () {
      console.log(this);
      this.setState({
          isOpen: false
      });
  };

  render() {
    const {account, t} = this.props
    const { articleId, loading, news } = this.state
    const {associated_article, associated_course, currently_liked, likes} = news
    const styleImg = {
      backgroundImage: 'url('+process.env.REACT_APP_URL+news.picture+')',
    }
    return (
      <div className="page_wrapper">
        {loading ?
          <InsLoader/>
        :
          <div className="news_wrapper">
            <div className='back_link_news' onClick={this._goBack}></div>
            <div className="news_likes">
              <div className={currently_liked ? 'like_icon liked' : 'like_icon'} onClick={this.addLike}></div>   
              <div className="like_count">{likes}</div>
            </div>
            <div className="item_news_img_wrapper">
              <div className="item_news_img" style={styleImg}></div>
            </div>
            <div className="article_content">
              <p className="content_brand">{news.brand}</p>
              <p className="content_title">{news.title}</p>
              <p className="content_subtitle">{news.subtitle}</p>            
              <p className="main_separator"></p>
              <div className="content--bo">
                <div dangerouslySetInnerHTML={{ __html: news.content }} />
                <Modal isOpen={this.state.isOpen} size='modal-lg'>
                  <div className="close-modal">x</div>
                  <div id="modal-content"></div>
                 </Modal>
              </div>
            </div>
            <div className="article_footer">
              <div className="comments">
                <InsComments
                  currentUserAvatar={process.env.REACT_APP_URL + account.picture}
                  contentId={articleId}
                  callApi="articles"
                />
              </div>
              {associated_article || associated_course ? <p className="separator"></p> : null}
              <div className="cross">
                {associated_article.length > 0 &&
                  <>
                    <div className="title">{t('common:cross_univers')}</div>
                    {associated_article.map((article) =>
                      <div key={article.id}>
                         <BrandArticlesItem
                          key={article.id}
                          id={article.id}
                          title={article.title}
                          subtitle={article.subtitle}
                          picture={article.picture}
                          content={article.content}
                          brandId={article.brand_id}
                        />
                      </div>
                    )}
                  </>
                }
                {associated_course.length > 0 &&
                  <>
                    <div className="title">{t('common:cross_course')}</div>
                    {associated_course.map((course) =>
                      <div key={course.id}>
                        <FormationItem 
                          brand={course.brand} 
                          image={course.picture} 
                          title={course.title} 
                          formation={course.type} 
                          time={course.time} 
                          points={course.points} 
                          idFormation={course.id} 
                          pourcent={course.pourcent} 
                        />
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
          </div>        
        }
      </div>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(News)))
