import './ProfileOrders.scss'
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { getUserOrders } from '../../../../api/account'
import { Link } from "react-router-dom"
import images from '../../../../ressources/images'
import InsLoader from '../../../../components/InsLoader/InsLoader'
import InsButton from '../../../../components/InsButton/InsButton'

const ProfileOrders = (props) => {
  const { t } = props
  const [orders, setOrders] = useState([])
  const [loader, setLoader] = useState(true)
  const [nbrPage, setNbrPage] = useState(1)
  const [seeMore, setSeeMore] = useState(true)
  const [contentHeight, setContentHeight] = useState(window.innerHeight)

  let contentStyle = { minHeight: contentHeight - 247 }

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }

  const closeOrders = () => {
    const { history, location } = props;
    if (location.state && location.state.goBack) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }

  const loadmore = () => {
    setNbrPage(nbrPage + 1)
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    const getOrders = () => {
      getUserOrders(nbrPage, 6).then(data => {
        setOrders(prevOrders => ([...prevOrders, ...data]))
        setLoader(false)

        if (data.length < 6) {
          setSeeMore(false)
        }
      })
    }
    getOrders()
  }, [nbrPage])

  return (
    <div className='orders_page'>
      <div className="header">
        <div className="close" onClick={closeOrders}>
          <img src={images.closeCross} alt="" />
        </div>
        <div className="title">
          {t('account:my_orders')}
        </div>
        <img src={images.orders} className='orders' alt="" />
      </div>

      <div className="orders_container" id="orders_container" style={contentStyle}>
        {loader ?
          <InsLoader />
          :
          <table id='orders_table'>
            <thead>
              <tr>
                <td>{t('account:order')}</td>
                <td>{t('account:nbr_tracking')}</td>
              </tr>
            </thead>
            <tbody>
              {
                orders.length ?
                  orders.map(order => (
                    <tr key={order.number}>
                      <td>
                        <Link to={{ pathname: '/profile/order/' + order.number, state: { transitionName: "slide_in" } }}>
                          <div className="order_nbr">N° {order.number}</div>
                          <div className="order_date">{order.date}</div>
                        </Link>
                      </td>
                      <td>
                        <div className="num_tracking">
                          {
                            order.trackingNumber
                              ?
                              <a href={order.trackingUrl} target="_blank" rel="noopener noreferrer">{order.trackingNumber}</a>
                              :
                              t("account:waiting")
                          }
                        </div>
                        <Link to={{ pathname: '/profile/order/' + order.number, state: { transitionName: "slide_in" } }}>
                          <div className="right_arrow"></div>
                        </Link>
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td className="no_orders">
                      {t('account:no_orders')}
                    </td>
                  </tr>
              }
            </tbody>
          </table>
        }
      </div>
      {seeMore &&
        <InsButton
          onClick={loadmore}
          text={t('account:see_more')}
          type="default"
        />
      }

    </div>
  )
}

export default withTranslation()(ProfileOrders)
