import React, { Component } from 'react'
import { Link } from "react-router-dom"
import slugme from 'slugme'
import InsLoader from '../../../../components/InsLoader/InsLoader';
import parse from 'html-react-parser';
import InsHeaderFormationsIndex from '../../../../components/InsHeaderFormationsIndex/InsHeaderFormationsIndex';

import './FormationIndex.scss'
import { getTraining } from '../../../../api/training';
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar';
import InsComments from '../../../../components/InsComments/InsComments';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { addLike } from '../../../../api/training';

export class FormationIndex extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      loader: true,
      formationInfos: {},
      courseId: this.getLast(this.props.match.params.id.split('-'))
    }
  }
  
  UNSAFE_componentWillMount() {
    this.getFormation()
  }

  // Recupére le dernier element d'un tableau
  getLast = (array) => array[array.length - 1]

  /**
   * Récupere les informations de la formation
   */
  getFormation() {
    const {courseId} = this.state
    getTraining(courseId).then((data) =>{
      this.setState({
        formationInfos: data,
        loader: false,
        alreadyLink: null
      })
    })
  }

  addCourseLike = () => {
    const {courseId} = this.state
    addLike(courseId).then((data) => {
      this.setState(prevState => ({
        formationInfos: {
            ...prevState.formationInfos,
            likes: data.like_count,
            currently_liked: data.user_liked
        }
      }))
    })
  }

  alreadyDone(e,course){
    e.preventDefault();
    this.setState({alreadyLink: course})

  }

  render() {    
    const {account, match} = this.props
    const { formationInfos } = this.state
    const { currently_liked, picture, brand, points, likes, title, description, module_groups } = formationInfos
    const style = {
      backgroundImage: 'url('+process.env.REACT_APP_URL+picture+')'
    }

    if(this.state.loader) {
      return (
          <div className="formation_desc">
            <InsLoader/>
          </div>
        )
    }

    return (
      <>
        <InsHeaderFormationsIndex 
          currently_liked={currently_liked}
          likes={likes}
          addLike={this.addCourseLike}
          return={this.props?.location?.state?.return }
        />
        <div className="formation_desc">
          <div className="formation_desc-img" style={style}></div>
          <div className="formation_infos">
            {points && <div className="formation_points">{'+'+points}</div>}
            <div className="formation_brand">{brand}</div>
            <div className="formation_title">{title}</div>
            <div className="formation_short_desc">{parse(description)}</div>
          </div>
          <div className="formation_modules">
            {module_groups.map((module, index) => 
              
              {
              const moduleSlug = slugme(module.name)+'-'+module.id
              return (
                <Link
                key={index}
                className='module_link'
                to={{
                  pathname: match.url+'/'+moduleSlug,
                  state: { transitionName: "slide_in" }
                }}
                onClick={ module.correct && module.checked ? (e) => this.alreadyDone(e,moduleSlug) : null }
                >
                  <div className={'module module'+module.id}>
                    <div className={module.correct && module.checked ? "module_name checked" : "module_name"}>
                      {module.name}
                    </div>
                    {module.type !== 'scorm' &&
                      <div className="module_time">
                        <div className="time">
                        {module.time ? module.time : 0}
                        </div>
                      </div>
                    }
                  </div>
                </Link>
              )}
            )}
          </div>
          <div className="comments">
            <InsComments
              currentUserAvatar={process.env.REACT_APP_URL + account.picture}
              contentId={formationInfos.id}
              callApi="formations"
            />
          </div>
          <InsCGUBar/>
          <div className={this.state.alreadyLink === null ? "already_done" : "already_done active"}>
            <div className="already_done--wrapper">
              <p className="already_done_title">Formation déjà complétée</p>
              <p>Vous avez déjà complété cette formation. La re-valider ne vous permettra pas de re-gagner les points associés.</p>
              <p>Voulez-vous continuer ?</p>
              <div className="already_done_chooser">
                <Link
                to={{
                  pathname: match.url+'/'+this.state.alreadyLink,
                  state: { transitionName: "slide_in" }
                }}
                >
                  <div className="already_done_chooser--btn">Oui</div>
                </Link>
                <div className="already_done_chooser--btn" onClick={() => this.setState({alreadyLink:null})}>Non</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(FormationIndex)))