import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import InsButton from '../../../../components/InsButton/InsButton';
import './Step0.scss'
import { getUrlParameter } from '../../../../ressources/helper';


class Step0 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sponsorshipCode: ''
    }
  }
  static propTypes = {
    onValidate: PropTypes.func
  }

  componentDidMount() {
    let sponsorship = getUrlParameter().code_parrainage
    this.setState({sponsorshipCode: sponsorship})
  }

  _chooseCode = (code) => {
    const { onValidate } = this.props
    const {sponsorshipCode} = this.state
    const userInfo = {
      hasCode: code,
      code_parrainage: sponsorshipCode
    }
    onValidate(userInfo);
  }

  render() {
    const { t } = this.props
    
    return (
      <form className="default-container col-flex" onSubmit={(e) => e.preventDefault()}>
        <p className="bold-label centered mb30">
          {t("connect:do_you_own")}
        </p>
        <p>
          <InsButton
            text={t("common:yes")}
            type='outline'
            onClick={() =>{ this._chooseCode("yes") }}
          />
        </p>
        <p>
          <InsButton
            text={t("common:no")}
            type='outline'
            onClick={() =>{ this._chooseCode("no") }}
          />
        </p>
      </form>
    )
  }
}

export default withTranslation()(Step0)