import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './BrandsListItem.scss'

export class BrandsListItem extends Component {
  static propTypes = {
    name: PropTypes.string,
    logo: PropTypes.string,
    picture: PropTypes.string
  }

  render() {
    let {
      logo,
      picture
    } = this.props
    return(
      <>
        <div className="brands-list-item" style={{backgroundImage: 'url(' + process.env.REACT_APP_URL+picture + ')'}}>
          <img src={process.env.REACT_APP_URL+logo} alt="" />
        </div>
      </>
    )
  }
}

export default BrandsListItem 
