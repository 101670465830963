import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import ConnectRouter from './pages/Connect/ConnectRouter';
// import { store } from './store/configureStore';
import ScrollRestauration from './components/ScrollRestauration/ScrollRestauration';
import AppRouter from './pages/App/AppRouter';
import CguRouter from './pages/App/Cgu/CguRouter';
import Maintenance from './pages/App/Maintenance/Maintenance';
import PreviewRouter from './pages/Preview/PreviewRouter';

class RootRouter extends Component {
	render() {
		const {auth, maintenance} = this.props
		return (
			<Router>
				<ScrollRestauration>
						{maintenance ?
							<Route path="/" component={Maintenance}/>
						:
							<Switch>
								<ConnectedRoute path="/connect" component={ConnectRouter} auth={auth} redirect="/" logged={false}/>
								<ConnectedRoute path="/conditions-generales-utilisation" component={CguRouter} auth={auth} redirect="/conditions-generales-utilisation" logged={false} />
								<ConnectedRoute path="/course/preview/:idPreview" component={PreviewRouter} auth={auth} />
								<ConnectedRoute component={AppRouter} auth={auth} redirect="/connect" logged={true} />
							</Switch>
						}
				</ScrollRestauration>
			</Router>
		)
	}
}


const ConnectedRoute = ({ 
		component: Component,
		auth,
		redirect,
		logged,
		...rest 
	}) => {
	const isConnected = () => {
		const { access_token } = auth
		return (access_token) ? true : false
	}

	return (
		<Route 
			{...rest}
			render={props => 
				(logged === undefined || isConnected() === logged) ? (
					<Component {...props} />
				) : (
					<Redirect
            to={{
              pathname: redirect,
              state: { from: props.location }
            }}
          />
				)
			}
  	/>
	)
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RootRouter)

