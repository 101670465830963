import React from 'react'
import Modal from 'react-responsive-modal';
import Strings from '../../translations/translations';
import InsButton from '../InsButton/InsButton';




const ModalError = ({ 
  title = Strings('connect:email_send'),
  text = Strings('connect:email_send'),
  btnText = Strings('connect:email_send'),
  ...rest
}) => {
  return (
    <Modal center {...rest}>
      <h2 className="modal-title">{title}</h2>
      <div className="modal-content">{text}</div>
        <InsButton 
          text={btnText}
          onClick={rest.onClose}
        />
    </Modal>
  )
};

export default ModalError