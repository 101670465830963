import './FormationQuizz.scss'
import React, { Component } from 'react'
import Affinite from '../../../../components/Quizz/Affinite/Affinite';
import Qcm from '../../../../components/Quizz/Qcm/Qcm';
import { getModuleGroup, coursePreview } from '../../../../api/training';
import InsLoader from '../../../../components/InsLoader/InsLoader';
import QcmMultiple from '../../../../components/Quizz/QcmMultiple/QcmMultiple';
import QcmPictures from '../../../../components/Quizz/QcmPictures/QcmPictures';
import ProgressBar from '../../../../components/Quizz/ProgressBar/ProgressBar';
import Content from '../../../../components/Quizz/Content/Content';
import Video from '../../../../components/Quizz/Video/Video';
import Scorm from '../../../../components/Quizz/Scorm/Scorm';

export class FormationQuizz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      formationName: this.props.match.params.id,
      moduleGroupeId: this.props.match.params.idPreview ? this.props.match.params.idPreview : this.getLast(this.props.match.params.moduleId.split('-')),
      moduleId: null,
      modules: [],
      learningName: '',
      step: 0,
      quizz: [],
      type: "",
      isPreview: this.props.match.params.idPreview ? true : false,
      loader: true,      
      contentHeight: window.innerHeight
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillMount() {
    this.getQuizz()    
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }

  // Recupére le dernier element d'un tableau
  getLast = (array) => array[array.length - 1]

  /**
   * Récupere les informations du mini-jeu 
   */
  getQuizz() {
    const {isPreview, moduleGroupeId, step} = this.state
    if(isPreview) {
      coursePreview(moduleGroupeId).then(data => {
        console.log('data:', data)
        this.setState({
          data: data,
          modules: data.modules,
          moduleId: data.modules[step].id,
          learningName: data.course_name,
          quizz: !data.modules[step].checked && data.modules[step] ,
          loader: false,
          type: data.modules[step].type
        })
      })
    } else {
      getModuleGroup(moduleGroupeId).then((data) =>{
      	//go to next quizz if there isn't content
        if (data.modules.length > 0) {
          this.setState({
            data: data,
            modules: data.modules ? data.modules : null,
            moduleId: data.modules[step].id ? data.modules[step].id : null,
            learningName: data.course_name,
            quizz: !data.modules[step].checked && data.modules[step] ,
            loader: false,
            type: data.modules[step].type
          })
        } else {
          this.nextQuizz();
        }
      })
    }
  }

  /**
   * Passe au mini-jeu suivant
   */
  nextQuizz = () => {
    const { isPreview, modules,step } = this.state
    if( step < (this.state.modules.length - 1) ) {
      this.setState({
        step: step+1,
        type: modules[step+1].type,
        modules: modules,
        moduleId: modules[step+1].id,
        quizz: modules[step+1],
        loader: false        
      })
    } else {   
      const {formationName, moduleGroupeId} = this.state
      const moduleName = this.props.match.params.moduleId
      if(isPreview) {
        this.props.history.replace('/course/preview/'+moduleGroupeId+'/result')
      } else {
        this.props.history.replace('/formations/'+formationName+'/'+moduleName+'/result')
      }
    }
  }

  /**
   * Passe à la question suivante
   */
  nextQuestion = (stepQuestion) => {
    const {modules, step} = this.state

    window.scroll(0, 0)

    // On check les questions qui sont complétées
    const checkQuestion = modules.map((item, index) => {
      if(index !== step) {
        return item
      } else {        
        const newQuestion = item.questions.map((el, i) => {
          if (i !== stepQuestion) {
            return el
          } else {            
            return {...el, check: true}
          }
        })
        return {...item, questions: newQuestion}
      }
    })

    this.setState({
      modules: checkQuestion
    })
  }

  /**
   * Quitte le quizz et revient à la page index de la formation
   */
  closeQuizz = () => {
    const formationName = this.props.match.params.id
    this.props.history.replace('/formations/'+formationName)
  }

  render() {
    let {contentHeight, data, isPreview, type, quizz, learningName, loader, modules, moduleId} = this.state

    if(loader) {
      return (
          <div className="formation_desc">
            <InsLoader/>
          </div>
        )
    }

    switch (type) {
      case 'trueFalse':
        return (
          <>
            <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
              {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
              <div className="formation">
                <div className="title">{learningName}</div>
                <Affinite
                  questions={quizz.questions}   
                  nextQuestion={this.nextQuestion} 
                  nextQuizz={this.nextQuizz}  
                  moduleId={moduleId}
                  isPreview={isPreview}        
                />
              </div>
            </div>
          </>
        )
      case 'singleQuizz':
        return (
          <>
          <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
            {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
            <div className="title">{learningName}</div>
            <Qcm
              questions={quizz.questions}   
              nextQuestion={this.nextQuestion} 
              nextQuizz={this.nextQuizz}  
              moduleId={moduleId}   
              isPreview={isPreview}     
            />
          </div>
          </>
        )

      case 'multipleQuizz':
        return (
          <>
            <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
              {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
            <div className="title">{learningName}</div>
            <QcmMultiple
              questions={quizz.questions}   
              nextQuestion={this.nextQuestion} 
              nextQuizz={this.nextQuizz}  
              moduleId={moduleId}     
              isPreview={isPreview}   
            />
          </div>
          </>
        )

      case 'pictureQuizz':
        return (
          <>
            <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
              {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
              <div className="title">{learningName}</div>
              <QcmPictures
                questions={quizz.questions}   
                nextQuestion={this.nextQuestion} 
                nextQuizz={this.nextQuizz}  
                moduleId={moduleId}   
                isPreview={isPreview}     
              />
            </div>
          </>
        )

      case 'content':
        return (
          <>
            <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
              {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
              <div className="title">{learningName}</div>
              <Content
                questions={quizz.questions}
                nextQuestion={this.nextQuestion}
                nextQuizz={this.nextQuizz}
                moduleId={moduleId}
                isPreview={isPreview}
              />
            </div>
          </>
        )

      case 'video':
        return (
          <>
            <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
              {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
              <div className="title">{learningName}</div>
              <Video
                questions={quizz.questions}
                nextQuestion={this.nextQuestion} 
                nextQuizz={this.nextQuizz}  
                moduleId={moduleId} 
                isPreview={isPreview}
              />
            </div>
          </>
        )

      case 'scorm':
        return (
          <>
          <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
            <div className="title">{learningName}</div>
            <Scorm
              close={this.closeQuizz}
              scorm_path={quizz.scorm_path}
              nextQuestion={this.nextQuestion} 
              nextQuizz={this.nextQuizz}
              idScorm={data.id}
              isPreview={isPreview}
            />
          </div>
          </>
        )
      
    
      default:
        return (
          <>
          <div className="quizz_wrapper" style={{minHeight: contentHeight}}>
            {!isPreview && <ProgressBar modules={modules} close={this.closeQuizz}/>}
            <div className="formation">
              <div className="title">{learningName}</div>
              <Qcm
                questions={quizz.questions}  
                nextQuestion={this.nextQuestion}  
                nextQuizz={this.nextQuizz} 
                moduleId={moduleId}  
                isPreview={isPreview}       
              />
            </div>
          </div>
          </>
        )

    }
  }
}

export default FormationQuizz
