import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import images from '../../../../../ressources/images'
import './Group.scss'
import GroupsNav from '../../../../../components/Groups/GroupsNav'
import FormationItem from '../../../../../components/FormationItem/FormationItem'
import { groupCourses } from '../../../../../api/training'
import InsLoader from '../../../../../components/InsLoader/InsLoader'

function GroupFormation(props) {
  const [coursesList, setCoursesList] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    const groupId = props.match.params.id
    groupCourses(groupId).then(data => {
      setCoursesList(data)
      setLoad(false)
    })
  }, [props.match.params.id])

  return (
    <div className="bg_white">
      <div className="group_header">
        <Link className="backlink" to={{ pathname: '/profile/groups', state: { transitionName: "slide_out" }}}>
          <img src={images.arrow_left_black} alt="" />
        </Link>
        {props.history.location.state.groupName}
      </div>
      <GroupsNav 
        groupId={props.match.params.id}
        groupName={props.history.location.state.groupName}
      />
      <div className="group_content">
        {load ?
          <InsLoader/>
        :
          coursesList.length > 0 ?
            coursesList.map((item, index)  => 
              <FormationItem 
                key={index} 
                idFormation={item.id}
                image={item.picture} 
                title={item.title} 
                brand={item.brand}
                points={item.points}
                time={item.time}
                formation={item.type}
                pourcent={item.pourcent}
              />
            )
          :
            <div className="back_soon">
              <div>Professeur absent…</div>
              <div>revenez bientôt </div>
              <span className="smile" role="img" aria-label="">&#128578;</span>
            </div>
        }
      </div>
    </div>
  )
}

export default GroupFormation
