import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import ProfileGroups from './ProfileGroups/ProfileGroups';
import GroupFormation from './Group/GroupFormation';
import GroupNews from './Group/GroupNews';
import GroupShop from './Group/GroupShop';
import GroupVideos from './Group/GroupVideos';

export class GroupsRouter extends Component {
  render() {
    const { match, location } = this.props
    
    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}`} component={ProfileGroups} />
          <Route exact path={`${match.path}/:id/courses`} component={GroupFormation} />
          <Route exact path={`${match.path}/:id/news`} component={GroupNews} />
          <Route exact path={`${match.path}/:id/shop`} component={GroupShop} />
          <Route exact path={`${match.path}/:id/videos`} component={GroupVideos} />
          <Route component={ProfileGroups}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(GroupsRouter)
