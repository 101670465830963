import React, { Component } from 'react'
import './BrandContent.scss'
import { Link } from "react-router-dom"
import BrandlistMenu from '../../../../components/Brands/BrandlistMenu/BrandlistMenu';
import { getBrandById } from '../../../../api/brands';
import { getProductsByBrand } from '../../../../api/brands';
import images from '../../../../ressources/images';
import BrandProductsItem from '../../../../components/Brands/BrandProductsItem/BrandProductsItem';
import InsNavigation from '../../../../components/InsNavigation/InsNavigation';
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar';

export class BrandProduits extends Component {

    constructor(props) {
      super(props);
      this.state = { 
        whoActive: 'produits',
        idFormation:0,
        dataBrand: [],
        dataProductByBrand: [],
        contentHeight: window.innerHeight
      };
    }

    UNSAFE_componentWillMount(){
      let brandId = document.location.href.split('/');
      brandId = brandId[brandId.length-1];
      this.setState({idFormation:brandId});
    }

    componentDidMount(){
      this.getBrandByIdFront();
      this.getBrandByProductFront()
    }

    getBrandByIdFront = () => {
      const {idFormation} = this.state
      getBrandById(idFormation).then((data) =>{
        this.setState({dataBrand: data})
      })
    }

    getBrandByProductFront = () => {
      const {idFormation} = this.state
      getProductsByBrand(idFormation).then((data) =>{
        this.setState({dataProductByBrand: data})
      })
    }

  render() {
    const {contentHeight, dataBrand, dataProductByBrand, idFormation, whoActive} = this.state
    return (
      <>
      <div className="white--wrapper">
          <Link className='brandlist_backlink' to={{ pathname: '/brands', state: { transitionName: "slide_in" }}}>
            <img src={images.arrow_left_black} alt="" />
          </Link>
          <div className="brandlist_content">
            <div className='flex-row-center'>
              <div className="brand_logo">
                <img className="brandlist_logo" src={process.env.REACT_APP_URL+dataBrand.logoNoir} alt="logo" />
              </div>
            </div>
            <BrandlistMenu whoActive={whoActive} brandId={idFormation}/>
            <div className="brand_contentlist" style={{height: contentHeight - 210}}>
              { dataProductByBrand.length > 0 ?
                dataProductByBrand.map(
                (data) => 
                <BrandProductsItem 
                  key={data.id}
                  title={data.name} 
                  img={process.env.REACT_APP_URL+data.picture} 
                  pdf={data.file}
                  link={data.link}
                />
                )
                :
                <div className="back_soon">
                  <div>En rupture de stock…</div>
                  <div>revenez bientôt </div>
                  <span className="smile" role="img" aria-label="">&#128578;</span>
                </div>
              }
              <InsCGUBar/>
            </div>
            <InsNavigation/>
          </div>
      </div>
      </>
    )
  }
}

export default BrandProduits
