import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import parse from 'html-react-parser';

import './Content.scss'
import { checkQuestion } from '../../../api/training';

export class Content extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allQuestions: [],   // Toutes les questions
      allContent: '',  // Contenu du module
      step: 0
    }
  }

  componentDidMount() {
    this.getDatas()
  }

  /**
   * Récupere le contenu
   */
  getDatas = () => {
    const {step} = this.state

    const dataContents = this.props.questions
    var data = '';
    var question = '';
    if (typeof this.props.questions !== 'undefined') {
      data = this.props.questions[step].content;
      question = this.props.questions[step].id;
    }
    const dataContent = data;
    const questionId = question;

    this.setState({
      allQuestions: dataContents,
      allContent: dataContent,
      questionId
    })
  }

   /**
   * Passe au mini-jeu suivant
   */
  nextStep = () => {
    const {step, questionId} = this.state
    const { isPreview, moduleId } = this.props
    //questionId is required to check question
    if (questionId) {
      !isPreview && checkQuestion(moduleId, questionId, ["compris"]).then((data) =>{
        if (typeof this.state.allQuestions !== 'undefined' && step < (this.state.allQuestions.length - 1)) {
          this.props.nextQuestion(step)
          this.setState({
            step: step+1,
          }, () => {this.getDatas()})
        } else {
          this.props.nextQuizz()
          this.setState({
            step: 0
          }, () => {this.getDatas()})
          this.props.nextQuestion(step)
        }
      })
    }
  }

  render() {
    const {allContent} = this.state
    const {t} = this.props
    return (
      <div className="content_wrapper">
        <div className="content_container course">
          {parse(allContent)}
        </div>
        <div className="btn_verif" onClick={this.nextStep}>          
          {t("common:understood")} ! 
        </div>
      </div>
    )
  }
}

export default withTranslation()(Content)
