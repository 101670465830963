import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import BrandsList from './BrandsList/BrandsList';
import BrandFormations from './BrandsContent/BrandFormations';
import BrandArticles from './BrandsContent/BrandArticles';
import BrandProduits from './BrandsContent/BrandProduits';
import BrandVideos from './BrandsContent/BrandVideos';
import YoutubeVideo from './BrandsVideo/YoutubeVideo';
// import BrandArticlesItem from '../../../components/Brands/BrandArticlesItem/BrandArticlesItem';
class BrandsRouter extends Component {

  UNSAFE_componentWillMount() {
    document.body.classList.add('brands');
  }
  componentWillUnmount() {
    document.body.classList.remove('brands');
  }
  render() {
    const { match, location } = this.props

    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}`} component={BrandsList} />
          <Route path={`${match.path}/formations/:brandId`} component={BrandFormations}/>
          <Route path={`${match.path}/articles/:brandId`} component={BrandArticles}/>
          <Route path={`${match.path}/produits/:brandId`} component={BrandProduits}/>
          <Route path={`${match.path}/videos/:brandId/watch=:youtube_id`} component={YoutubeVideo}/>
          <Route path={`${match.path}/videos/:brandId`} component={BrandVideos}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(BrandsRouter)