import './Result.scss'
import React, { Component } from 'react'
import ResultGood from './ResultGood';
import ResultBad from './ResultBad';
import { checkModule } from '../../../api/training';
import {  refreshAccountData } from '../../../api/account'
import InsLoader from '../../InsLoader/InsLoader';

export class Result extends Component {
  constructor(props) {
    super(props)
    this.state = {
      result: null,
      formationName: this.props.match.params.id,
      moduleId: this.getLast(this.props.match.params.moduleId.split('-')),
      moduleName: this.props.match.params.moduleId,
      courseCorrect : null,
      alreadyDone: null,
      points: null,
      loader: true
    }
  }

  componentDidMount() {
    this.getDatas()
  }

  /**
   * Recupere le resultat final du module
   */
  getDatas = () => {
    const {moduleId} = this.state
    checkModule(moduleId).then((data) =>{
      this.setState({
        result: data.response,
        courseCorrect: data.courseCorrect,
        alreadyDone: data.alreadyDone,
        points: data.points,
        loader: false
      }, () => {
        if(this.state.result !== false) {
          refreshAccountData()
        }        
      }) 
    })
  }

  // Recupére le dernier element d'un tableau
  getLast = (array) => array[array.length - 1]

  render() {
    const {alreadyDone, courseCorrect, points, result, formationName, loader, moduleName, moduleId} = this.state
    return (
      <div className="course_result">
        {loader ? 
          <InsLoader/>
        :
          result ?
            <ResultGood 
              formation={formationName}
              moduleName={moduleName}
              moduleId={moduleId}
              courseCompleted={courseCorrect}
              alreadyDone={alreadyDone}
              points={points}
            />
          :      
            <ResultBad 
              formation={formationName}
              moduleId={moduleId}
              moduleName={moduleName}
            />
        }
      </div>
    )
  }
}

export default Result
