import React, { Component } from 'react'
import InsHeader from '../../../../components/InsHeader/InsHeader';
import InsNavigation from '../../../../components/InsNavigation/InsNavigation';
import BrandsListItem from '../../../../components/Brands/BrandsListItem/BrandsListItem';
import { getBrandsList } from '../../../../api/brands';
import { Link } from "react-router-dom"
import './BrandsList.scss'
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar';

export class BrandsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      brandsList: [],
      contentHeight: window.innerHeight
    }
  }

  componentDidMount() {
    this.getAllFormation()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }

  /**
   * Récupere toutes les marques disponible
   */
  getAllFormation() {
    getBrandsList().then((data) =>{
      this.setState({brandsList: data})
    })
  }

  render() {
    const {contentHeight} = this.state
    return (
      <>
        <InsHeader />
        <div className="page_container" style={{height: contentHeight - 120}}>
          <div className="brandslist">
            {this.state.brandsList.map((brand, index) => (brand.visibility == 1) ? (brand.id == 29) ?
              <Link
              key={index}
              className='brandlist_link'
              to={{
                pathname: '/brands/articles/'+brand.id,
                state: { transitionName: "slide_in" }
              }} 
              >
              <BrandsListItem name={brand.name} logo={brand.logo} picture={brand.picture} />
              </Link> 
                    :
              <Link
                  key={index}
                  className='brandlist_link'
                  to={{
                    pathname: '/brands/formations/'+brand.id,
                    state: { transitionName: "slide_in" }
                  }}
              >
                <BrandsListItem name={brand.name} logo={brand.logo} picture={brand.picture} />
              </Link>
                : ''
            )}
          </div>
          <InsCGUBar/>
        </div>
        <InsNavigation />
      </>
    )
  }
}

export default BrandsList
