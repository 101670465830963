import React from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const CartHeader = (props) => {
  const {goBack, t} = props

  return (
    <div className="cart_header">
      <Link to={{ pathname: '/shop', state: { transitionName: "slide_out" }}}>
        <div className='back_link' onClick={goBack}></div>
      </Link>
      <div className="cart_title">{t('shop:my_cart')}</div>
    </div>
  )
}

export default withTranslation()(CartHeader)
