import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import Cgu from './Cgu';

class CguRouter extends Component {

  render() {
    const { match, location } = this.props

    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}/conditions-generales-utilisation`} component={Cgu} />
          <Route component={Cgu}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(CguRouter)