import * as types from "./actionsTypes";

export function setFilters(filters) {
  return {
    type: types.SET_FILTERS,
    data: filters
  }
}

export function resetFilters() {
  return {
    type: types.RESET_FILTERS
  }
}