import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import images from '../../../../../ressources/images'
import './Group.scss'
import GroupsNav from '../../../../../components/Groups/GroupsNav'
import BrandArticlesItem from '../../../../../components/Brands/BrandArticlesItem/BrandArticlesItem'
import { groupNews } from '../../../../../api/news'
import InsLoader from '../../../../../components/InsLoader/InsLoader'

function GroupNews(props) {
  const [newsList, setNewsList] = useState([])
  const [load, setLoad] = useState(true)

  useEffect(() => {
    const groupId = props.match.params.id
    groupNews(groupId).then(data => {
      setNewsList(data)
      setLoad(false)
    })
  }, [props.match.params.id])

  return (
    <div className="bg_white">
      <div className="group_header">
        <Link className="backlink" to={{ pathname: '/profile/groups', state: { transitionName: "slide_out" }}}>
          <img src={images.arrow_left_black} alt="" />
        </Link>
        {props.history.location.state.groupName}
      </div>
      <GroupsNav 
        groupId={props.match.params.id}
        groupName={props.history.location.state.groupName}
      />
      <div className="group_content">
        {load ?
          <InsLoader/>
        :
          newsList.length > 0 ?
            newsList.map((article) => 
              <BrandArticlesItem
                key={article.id}
                id={article.id}
                title={article.title}
                subtitle={article.subtitle}
                picture={article.picture}
                content={article.content}
                brandId={article.brand_id}
              />
            )
          :
            <div className="back_soon">
              <div>Pigiste en vacances…</div>
              <div>revenez bientôt </div>
              <span className="smile" role="img" aria-label="">&#128578;</span>
            </div>
        }
      </div>
    </div>
  )
}

export default GroupNews
