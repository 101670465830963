import React, {useState, useEffect} from 'react'

const CartItem = (props) => {
  const {picture, brand, name, points, totalCart, quantity, userPoints} = props
  const [canAdd, setCanAdd] = useState(false)

  const canAddItem = () => {
    if((userPoints - totalCart) >= points) {
      setCanAdd(true)
    } else{
      setCanAdd(false)
    }
  }

  useEffect(
    () => {
      canAddItem()
    }
  )
  return (
    <div className="cart_item">
      <div className="product_picture">
        <img src={process.env.REACT_APP_URL + picture} alt=""/>
      </div>

      <div className="product_infos">
        <div className="product_brand">{brand}</div>
        <div className="product_name">{name}</div>
        <div className="product_price">{points} points</div>
      </div>

      <div className="product_qty">
        <div className={canAdd ? "add_one" : "add_one hide"} onClick={canAdd ? props.addOne : null}></div>
        <div className="qty">x{quantity}</div>
        <div className="remove_one" onClick={props.removeOne}></div>
      </div>

      <div className="product_del" onClick={props.removeProduct}></div>
    </div>
  )
}

export default CartItem
