import React, { Component } from 'react'
import { withTranslation, Trans } from 'react-i18next';
import { addComment, getPostComment, replyComment } from '../../api/wall';
import './InsComments.scss'
import { addArticleComment, getArticleComments, replyArticleComment } from '../../api/news';
import images from '../../ressources/images';
import { addCourseComment, getCourseComments, replyCourseComment } from '../../api/training';

class InsComments extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       comments: [],
       comment: '',
       showComments: false,
       reply: false,
       countRepliedComments: 0,
       contentHeight: window.innerHeight
    }

    this.contentId = this.props.contentId
  }

  
  componentDidMount() {
    this.getAllComments()
    window.addEventListener("resize", this.updateDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  
  getAllComments = () => {
    const {callApi} = this.props
    if(callApi === "wall") {
      getPostComment(this.contentId).then((data) => {
        this.setState({
          comments: data
        }, () => {
          this.countComments()
        })
      })
    } else if(callApi === "articles") {
      getArticleComments(this.contentId).then((data) => {
        this.setState({
          comments: data
        }, () => {
          this.countComments()
        })
      })
    } else if (callApi === "formations") {
      getCourseComments(this.contentId).then((data) => {
        this.setState({
          comments: data
        }, () => {
          this.countComments()
        })
      })
    }
  }
  
  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }
  
  countComments = () => {
    const {comments} = this.state
    let responses = 0
    comments.forEach(comment => {
      responses += comment.reponses.length
    })
    this.setState({
      countRepliedComments: responses
    })
  }
  
  
  _handleChange = input => e => {    
    const value = e.target.value
  
    this.setState({
      [input] : value
    })
  }
  
  _handleChangeComment = ref => (e) => {
    this.setState({
      comment : e.target.value
    }, () => {
      this.refs[ref].style.height = ''
      const scrollHeight = this.refs[ref].scrollHeight
      this.refs[ref].style.height = scrollHeight+'px'
    })
    
  }
  
  addComment = (e) => {
    const {comment} = this.state
    const {callApi} = this.props
    let obj = {}
    obj.content = comment
    obj.visible = 1
  
    e.preventDefault()    
    if(comment) {
      this.refs['comment_form'].reset()  
      if(callApi === "wall") {
        addComment(this.contentId, obj).then(() => {
          this.getAllComments()
          this.openComments()
        })
      } else if (callApi === "articles") {
        addArticleComment(this.contentId, obj).then(() => {
          this.getAllComments()
          this.openComments()
        })
      } else if (callApi === "formations") {
        addCourseComment(this.contentId, obj).then(() => {
          this.getAllComments()
          this.openComments()
        })
      }
    }
  }
  
  reply = (e) => {
    this.setState({
      reply: true,
      comment_id: e.target.getAttribute('data-id')
    }, () => {
      this.refs['reply_comment'].focus()
    })
  }
  
  replyComment = (e) => {    
    const {comment, comment_id} = this.state
    const {callApi} = this.props
    let obj = {}
    obj.content = comment
    obj.visible = 1
  
    e.preventDefault()    
    if(comment) {
      this.refs['reply_comment_form'].reset()
      if(callApi === "wall") {
        replyComment(this.contentId, comment_id, obj).then(() => {
          this.getAllComments() 
          this.setState({
            reply: false
          }, () => {
            const commentContainer = this.refs['comment_container']
            const comment = this.refs['comment_'+comment_id]
            commentContainer.scrollTo(0, comment.offsetTop - 10)
          })
        })
      } else if (callApi === "articles") {
        replyArticleComment(this.contentId, comment_id, obj).then(() => {
          this.getAllComments() 
          this.setState({
            reply: false
          }, () => {
            const commentContainer = this.refs['comment_container']
            const comment = this.refs['comment_'+comment_id]
            commentContainer.scrollTo(0, comment.offsetTop - 10)
          })
        })
      } else if (callApi === "formations") {
        replyCourseComment(this.contentId, comment_id, obj).then(() => {
          this.getAllComments() 
          this.setState({
            reply: false
          }, () => {
            const commentContainer = this.refs['comment_container']
            const comment = this.refs['comment_'+comment_id]
            commentContainer.scrollTo(0, comment.offsetTop - 10)
          })
        })
      }
    }
  }

  onFocus= (e) => {
    this.setState({focus: true})
  }
  
  onBlur = () => {
    this.setState({
      focus: false,
      reply: false
    })
  }

  openComments = () => {
    this.getAllComments()
    this.setState({
      showComments: true
    })
    window.scroll(0, 0)
    document.body.style.overflow = "hidden";
  }

  closeComments = () => {
    this.setState({
      showComments: false,
      reply: false
    })
    document.body.style.overflow = "auto";
  }

  render() {    
    const {currentUserAvatar, isWall, legend, user, userPostPicture, t} = this.props
    const{contentHeight, comments, countRepliedComments, focus, reply, showComments} = this.state

    return (
      <div className="comments_container">
        <div className="show_comment">
          <div className="comments_count">
            {comments.length > 0 ?
              comments.length === 1 ?
              <div onClick={this.openComments}>
                <Trans i18nKey="wall:show_comment" count={comments.length + countRepliedComments}>
                  See the comment
                </Trans>
              </div>
              :
              <div onClick={this.openComments}>
                <Trans i18nKey="wall:show_comments" count={comments.length + countRepliedComments}>
                  See the comments
                </Trans>
              </div>
            :
              'Aucun commentaire'
            }
          </div>
        </div>
        <div className="add_comment">
          <div className="avatar">
            <div>
              <img src={currentUserAvatar} alt=""/>
            </div>
          </div>
          <form 
            className={focus ? 'focus' : null} 
            ref='comment_form'
            onSubmit={this.addComment.bind(this)}
          >
            <textarea 
              id="add_comment" 
              ref="add_comment" 
              onFocus={this.onFocus} 
              onChange={this._handleChangeComment('add_comment')} 
              placeholder={t('wall:add_comment')}
            />
            {focus && <button type="submit" className="publish_comment" >Publier</button>}
          </form>
        </div>

        {/* MODAL COMMENTAIRES */}
        {showComments &&
          <div className="show_comments-container">
            <div className={isWall ? "show_comments-header wall" : "show_comments-header article" } >
              <div className="back_link" onClick={this.closeComments}></div>
              <span>{t('wall:comments')}</span>
            </div>
            <div className="all_comments" ref="comment_container" style={{height: contentHeight - 40}}>
              {isWall &&
                <div className="post">
                  <div className="user_avatar">
                    <div>
                      <img src={userPostPicture} alt='avatar'/>
                    </div>
                  </div>
                  <div className="user_comment">
                    <span className="username">{user}</span>
                    {legend !== "undefined" &&              
                      <span dangerouslySetInnerHTML={{ __html: legend }} />
                    }
                  </div>
                </div>
              }
              {/* AFFICHE TOUS LES COMMENTAIRES */}
              {comments.map((comment, index) => (
                <div className="comment_wrapper" key={index}>
                  <div className="comment" ref={'comment_'+comment.id}>
                    <div className="user_avatar">
                      <div>
                        <img src={comment.user_picture ? process.env.REACT_APP_URL+comment.user_picture : images.icon_user} alt={comment.user + ' avatar'}/>
                      </div>
                    </div>
                    <div className="user_comment">
                      <div className="user_info">
                        <span className="username">{comment.user}</span>
                        <span className="team">{comment.user_team}</span>
                      </div>
                      <div className="comment_text">
                        {comment.content}
                      </div>
                    </div>
                    {/* AFFICHE LES REPONSES S'IL Y EN A */}
                    {comment.reponses &&
                      <div className="responses_container">
                        {comment.reponses.map((response, index) => (
                          <div className="response" key={index}>
                            <div className="user_avatar response_user_avatar">
                              <div>
                                <img src={response.user_picture ? process.env.REACT_APP_URL+response.user_picture : images.icon_user} alt={response.user + ' avatar'}/>
                              </div>
                            </div>
                            <div className="response_comment">
                              <div className="user_info">
                                <span className="username">{response.user}</span>
                                <span className="team">{response.user_team}</span>
                              </div>
                              <div className="comment_text">
                                {response.content}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                  <div className="comment_options" data-id={comment.id} onClick={this.reply.bind(this)}>
                    {t('wall:reply')}
                  </div>
                </div>
              ))}

              {/* INPUTE REPONSE COMMENTAIRE */}
              {reply &&
                  <div className="add_comment reply">
                    <div className="avatar">
                      <img src={currentUserAvatar} alt=""/>
                    </div>
                    <form className={focus ? 'focus' : null} ref='reply_comment_form' onSubmit={this.replyComment.bind(this)}>
                      <textarea 
                        id="reply_comment" 
                        ref="reply_comment" 
                        onFocus={this.onFocus} 
                        onChange={this._handleChangeComment('reply_comment')} 
                        placeholder={t('wall:add_comment')}
                      />
                      {focus && <button type="submit" className="publish_comment" >Publier</button>}
                    </form>
                  </div>
                }
            </div>
          </div>
        } 
      </div>
    )
  }
}

export default withTranslation()(InsComments)
