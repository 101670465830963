import React from 'react'
import { withTranslation } from 'react-i18next';
import Validate from '../../ressources/validation';



const ValidatePassword = ({ 
  value,
  activeColor = "#fff", 
  styles = {},
  t, 
  children
}) => {
  const checkLength = !Validate('passwordLength', value)
  const checkMaj = !Validate('passwordMaj', value)
  const checkMin = !Validate('passwordMin', value)
  const checkSpecial = !Validate('passwordSpecial', value)

  return (
    <div>
      <div>
        {children}
      </div>
      <ul className="verif_mdp">
        <li style={{...styles.item , color: (checkLength) ? activeColor : '#707070'}}>
          {t("connect:validate_pwd.length")}
        </li>
        <li style={{...styles.item , color: (checkMaj) ? activeColor : '#707070'}}>
          {t("connect:validate_pwd.maj")}
        </li>
        <li style={{...styles.item , color: (checkMin) ? activeColor : '#707070'}}>
          {t("connect:validate_pwd.min")}
        </li>
        <li style={{...styles.item , color: (checkSpecial) ? activeColor : '#707070'}}>
          {t("connect:validate_pwd.special")}
        </li>
      </ul>
    </div>
  )
}

export default withTranslation()(ValidatePassword)