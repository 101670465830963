import './WallCreate.scss'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next';
import { createPostApi } from '../../../../api/wall';
import WallForm from '../../../../components/Wall/WallForm';

function WallCreate(props) {

  const [loadCreate, setLoadCreate] = useState(false)
  let filesPreview = []


  const updatePreviews = (dataFilesPreview) => {
    filesPreview = dataFilesPreview
  }

  /**
   * Creation du poste
   */
  const createPost = (brand, files, productName, legend, theme) => {
    let formData = new FormData();
    setLoadCreate(true)
    formData.append('brand', brand)
    formData.append('product', productName)
    formData.append('content', legend ? legend : null)
    formData.append('theme', theme)
    for (let i = 0; i < files.length; i++) {
      formData.append("files["+i+"]", files[i])
    }
    for (let i = 0; i < filesPreview.length; i++) {
      // TODO GERER SI INDEX VIDE
      formData.append("thumbnails["+i+"]", filesPreview[i])
    }

    createPostApi(formData).then(() => {
      setLoadCreate(false)
      props.history.replace('/wall')
    })
  }

  const { history, location, t } = props
  
  return (
    <div className='bg_white wall_create'>
      <WallForm 
        loadCreate={loadCreate}
        submitPost={createPost}
        history={history} 
        location={location} 
        filesPreview={updatePreviews}
        t={t}
      />
    </div>
  )
}

export default withTranslation()(WallCreate)
