import React, { Component } from 'react'
import './ArticlePage.scss'
import { getArticleById } from '../../../src/api/brands';
import images from '../../../src/ressources/images'
import InsComments from '../InsComments/InsComments';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { addLike } from '../../api/news';
import FormationItem from '../FormationItem/FormationItem';
import BrandArticlesItem from '../Brands/BrandArticlesItem/BrandArticlesItem';
import InsLoader from '../InsLoader/InsLoader';
import Modal from 'react-modal';

export class ArticlePage extends Component {
  constructor(props) {
  super(props);
     this.state = {
      content: '',
      articleId : this.props.match.params.id,
      dataArticle: {},
      loading: true,
      isOpen: false
    };
  }


  UNSAFE_componentWillMount(){
    let currentIdArticle = document.location.href.split('/');
    currentIdArticle = currentIdArticle[currentIdArticle.length-1];
    this.setState({articleId: currentIdArticle});
  }

  componentDidUpdate(){
      var el = document.querySelectorAll("button[data-url]");
      var _this = this;
      for(var i =0; i < el.length; i++) {
          el[i].onclick = function() {
              _this.openModal();
              document.getElementById('modal-content').innerHTML = '<iframe src="' + this.getAttribute('data-url') + '" style="width: 100%; height: 100%;"></iframe>';
          };
      }
  }

  componentDidMount(){
    this.getArticleByIdFront();
  }

  addLike = () => {
    let {articleId} = this.state
    addLike(articleId).then((data) => {
      this.setState(prevState => ({
        dataArticle: {
            ...prevState.dataArticle,
            likes: data.like_count,
            currently_liked: data.user_liked
        }
      }))
    })
  }

  getArticleByIdFront = () => {
    const {articleId} = this.state
    getArticleById(articleId).then((data) =>{
      this.setState({dataArticle: data, loading: false})
    })
  }

  /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { history, location, state } = this.props;
    console.log('history:', history)
    let currentBrandId = document.location.href.split('/');
    currentBrandId = currentBrandId[currentBrandId.length-2];

    if(location.state && location.state.transitionName){ 
      history.goBack();
    } else {
      history.replace('/brands/articles/'+currentBrandId, {transitionName: "slide_out",...state});
    }
  }

    openModal () {
        this.setState({
            isOpen: true
        });

        var _this = this;
        var close = document.querySelectorAll(".close-modal");
        console.log(close);
        for(var i =0; i < close.length; i++) {
            close[i].onclick = function() {
                _this.hideModal();
            };
        }

    };

    hideModal () {
        console.log(this);
        this.setState({
            isOpen: false
        });
    };

    render() {
    const {articleId, dataArticle, loading} = this.state
    const {account, t} = this.props
    const {associated_article, associated_course,brand, content, title, subtitle, picture, currently_liked, likes} = dataArticle
    
    return(
      <div className="page_wrapper">
       {loading ?
          <InsLoader/>
        :
        <div className="content_container bg_white">
            <div className="back_link-article" onClick={this._goBack}>
            <img className="white-arrow--left" src={images.arrow_left_white} alt=""/>
          </div>
          <div className="news_likes">
            <div className={currently_liked ? 'like_icon liked' : 'like_icon'} onClick={this.addLike}></div>   
            <div className="like_count">{likes}</div>
          </div>
          {/* <img className="love-icon--white" src={images.love_icon_white} alt="" /> */}
          {
            picture === null ?
            <img className="content_picture" src={images.bg_none} alt="" /> :
            <img className="content_picture" src={picture} alt="" />
          }
          <div className="article_content">
            <p className="content_brand">{brand}</p>
            <p className="content_title">{title}</p>
            <p className="content_subtitle">{subtitle}</p>
            <p className="main_separator"></p>
            <div className="content--bo">
              <div dangerouslySetInnerHTML={{ __html: content }} />
                <Modal isOpen={this.state.isOpen} size='modal-lg'>
                <div className="close-modal">x</div>
                <div id="modal-content"></div>
                </Modal>
              </div>
          </div>
          <div className="article_footer">
              <div className="comments">
                <InsComments
                  currentUserAvatar={process.env.REACT_APP_URL + account.picture}
                  contentId={articleId}
                  callApi="articles"
                />
              </div>
              {associated_article || associated_course ? <p className="separator"></p> : null}
              <div className="cross">
                {associated_article.length > 0 &&
                  <>
                    <div className="title">{t('common:cross_univers')}</div>
                    {associated_article.map((article) =>
                      <div key={article.id}>
                        <BrandArticlesItem
                          key={article.id}
                          id={article.id}
                          title={article.title}
                          subtitle={article.subtitle}
                          picture={article.picture}
                          content={article.content}
                          brandId={article.brand_id}
                        />
                      </div>
                    )}
                  </>
                }
                {associated_course.length > 0 &&
                  <>
                    <div className="title">{t('common:cross_course')}</div>
                    {associated_course.map((course) =>
                      <div key={course.id}>
                        <FormationItem 
                          brand={course.brand} 
                          image={course.picture} 
                          title={course.title} 
                          formation={course.type} 
                          time={course.time} 
                          points={course.points} 
                          idFormation={course.id} 
                          pourcent={course.pourcent} 
                        />
                      </div>
                    )}
                  </>
                }
              </div>
            </div>
        </div>
        }
      </div>
    )
    
  }
}
//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account,  
  navigation: state.navigation
})

export default (connect(mapStateToProps)(withTranslation()(ArticlePage)))
