import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/wallActions';
//components
import InsHeader from '../../../../components/InsHeader/InsHeader';
//style
import './WallFilter.scss';
//api
import { getAllThemesList } from '../../../../api/wall'; 
import { getBrandsList } from '../../../../api/brands';
//images
import images from '../../../../ressources/images'

function WallFilter(props) {
  const [choosed,setChoosed] = useState('brands')
  const [brandsList,setBrandsList] = useState(null)
  const [themesList,setThemesList] = useState(null)
  const [filtersApplied, setFiltersApplied] = useState(props.wallFiltersList);
  const [contentHeight,setContentHeight] = useState(window.innerHeight);
  //va chercher les données
  function getData(){
    getBrandsList().then(data => {
      setBrandsList(data);
    });
    getAllThemesList().then(data => {
      setThemesList(data);
    });
  }

  //envoie formulaire donc envoie les filtres choisis a redux
  function handleSubmit(e){
    e.preventDefault();
    //on envoie a redux les filtres
    props.setFilters(filtersApplied);
    props.history.replace('/wall')
  }

  function handleReset(e){
    e.preventDefault()
    setFiltersApplied({brands:[], themes:[]})
    props.resetFilters()
  }

  function interactArray(elmt){
    if(filtersApplied[choosed].indexOf(elmt.target.name) !== -1) {
      let array = [...filtersApplied[choosed]]; // make a separate copy of the array
      let index = array.indexOf(elmt.target.name)
      if (index !== -1) {
        array.splice(index, 1);
        setFiltersApplied({...filtersApplied, [choosed]: array});
      }
    }
    else {
      setFiltersApplied( {...filtersApplied, [choosed]: [...filtersApplied[choosed], elmt.target.name]})
    }
  }

  function updateDimensions() {
    setContentHeight(window.innerHeight)
  }

  //Lance getData au chargement du composant
  useEffect(
    () => {
      window.addEventListener("resize", updateDimensions);
      getData();
    },
    [] //Afin d'éviter les boucles infini
  );
  
        return (
          <>
            <InsHeader/>
            <div className='wall_filter_content'  style={{height: contentHeight - 60}}>
              <div className='wall_filter_title'>
                <Link to={{ pathname: '/wall', state: { transitionName: "slide_out" }}}>
                  <img src={images.closeCross} alt='' />
                </Link>
                <h2>{props.t('common:filter')}</h2>
              </div>
              <div className='wall_filter_chooser'>
                <div className={choosed === 'brands' ? 'chooser is--choosed' : 'chooser'} onClick={() => {setChoosed('brands')}}>{props.t('wall:brand')}</div>
                <div className={choosed === 'themes' ? 'chooser is--choosed' : 'chooser'} onClick={() => {setChoosed('themes')}}>{props.t('wall:Theme')}</div>
              </div>
              <form onReset={handleReset} onSubmit={handleSubmit}>
                <div className='wall_filters_list'>
                  {
                    brandsList && choosed === 'brands' &&
                    brandsList.map((brand) =>
                      <div key={brand.id}>
                        <input
                        checked={filtersApplied['brands'].includes(""+brand.id)}
                        type="checkbox"
                        id={brand.name}
                        name={brand.id}
                        onChange={(e) => {interactArray(e)}}/>
                        <label className='one_filter' htmlFor={brand.name}>{brand.name}
                          <img src={images.checked_black} alt='' />
                        </label>
                      </div>
                    )
                  }
                  {
                    themesList && choosed === 'themes' &&
                    themesList.map((theme) =>
                      <div key={theme.id}>
                        <input
                        type="checkbox"
                        id={theme.name}
                        name={theme.name}
                        checked={filtersApplied['themes'].includes(theme.name)}
                        onChange={(e) => {interactArray(e)}}/>
                        <label className='one_filter' htmlFor={theme.name}>{theme.name}
                          <img src={images.checked_black} alt='' />
                        </label> 
                      </div>
                    )
                  }
                </div>
                <div className={filtersApplied['brands'].length !== 0 || filtersApplied['themes'].length !== 0 ? 'wall_filter_end_form can--send' : 'wall_filter_end_form'}>
                  <input type="submit" value={props.t('wall:Validate')} />
                  <input type="reset" value={props.t('wall:Reset')} />
                </div>
              </form>
            </div>
          </>
        );

}

//extract data from the redux store
const mapStateToProps = (state) => ({
  wallFiltersList: state.wallFilters
})

//Le tableau est modifié on le modifie dans le store
const mapDispatchToProps  = (dispatch) => {
  return {
    setFilters: (filtersApplied) => { dispatch(actions.setFilters(filtersApplied)) },
    resetFilters: () => {dispatch(actions.resetFilters())}
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WallFilter))
