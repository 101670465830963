import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import Step0 from './Step0/Step0';
import Step1Code from './Step1Code/Step1Code';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import * as actions from '../../../store/actions/createAccountAction';
import { createAccountAPI } from '../../../api/account';
import { getUrlParameter } from '../../../ressources/helper';
import './Step3/Step3.scss'

class Create extends Component {
  constructor(props){
    super(props);
    const locationState = props.location.state
    const { userInfo } = props.createAccount

    let step = locationState ? locationState.step || 0 : 0;
    step = !Object.keys(userInfo).length ? 0 : step
    this.state = {
      step: step,
      error: '',
      sponsorship: false,
      sponsorshipCode: ''
    }
  }

  UNSAFE_componentWillMount() {
    let sponsorship = getUrlParameter().code_parrainage
    if(sponsorship) {
      this.setState({
        sponsorship: true,
        sponsorshipCode: sponsorship
      })
    }
  }

  componentDidMount() {
    const {sponsorship, sponsorshipCode} = this.state
    if(sponsorship) {
      this._changeStep({hasCode: 'no', code_parrainage: sponsorshipCode})
    }
  }

  _renderSteps = () => {
    const { step } = this.state
    const { userInfo } = this.props.createAccount

    if(!Object.keys(userInfo).length){
      return <Step0 onValidate={this._changeStep} />
    }

    switch (step) {
      case 1:
        switch (userInfo.hasCode) {
          case "yes":
            return <Step1Code onValidate={this._changeStep} userInfo={userInfo} />

          case "no":
            return <Step1 onValidate={this._changeStep} userInfo={userInfo} />

          default:
          break;
        }
      break;
    
      case 2:
        return <Step2 onValidate={this._changeStep} userInfo={userInfo} />
        
      case 3:
        return <Step3 onValidate={this._createAccount} userInfo={userInfo}/>
      
      case 0:
      default:
        return <Step0 onValidate={this._changeStep} />
    }
  }

  _changeStep = (newUserInfo) => {
    const { step } = this.state
    let locationState = {
      goBack: true,
      step: step + 1,
    }
    this.props.updateInfos(newUserInfo)
    this.props.history.push('/connect/create', locationState)
  }

  _createAccount = (newUserInfo) => {
    const { userInfo } = this.props.createAccount
    let obj = {
      ...userInfo,
      ...newUserInfo
    }

    this.props.updateInfos(newUserInfo)

    createAccountAPI(obj).then((data) => {
      //value a changer pour la bonne data
      let value = data.active
      this.props.history.replace('/connect/create-success', {isCreated: value})

    }, (_error) => {
      console.log('erreur requete api : '+_error)
      this.setState({
        error: _error
      })
    })
  }

  render() {
    return (
      <Fragment>
        <div className="step3-error-validate-account">
          {this.state.error}
        </div>
        {this._renderSteps()}
      </Fragment>
    )
  }
}




const mapStateToProps = (state) => ({
  createAccount: state.createAccount
})

const mapDispatchToProps  = (dispatch) => {
  return {
    updateInfos: (infos) => { dispatch(actions.updateInfos(infos)) },
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Create))
