import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import InsMenu from '../InsMenu/InsMenu'
import './InsHeader.scss'
import images from '../../ressources/images'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/notificationsActions';
import Notifications from '../Notifications/Notifications'
import { getNotifications } from '../../api/notifications'

function InsHeader(props) {
  const [openMenu, setOpenMenu] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const { account, notifications } = props
  const {notificationsNotSeen: notifNbr} = notifications

  let style = {
    backgroundImage: 'url('+process.env.REACT_APP_URL+account.picture+')',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  }

  // Recupere toutes les notifications
  const getAllNotifications = () => {
    getNotifications().then(data => {
      props.setNotifications(data)
    })
  }

  // Lance getAllNotifications
  useEffect(
    () => {
      getAllNotifications()
      const interval = setInterval(getAllNotifications, 60000);
      return () => {clearInterval(interval)}
    },
    [] //Afin d'éviter les boucles infini
  );

  return (
    <div className='header'>
      <header className='ins_header'>
        <div className="left_part">
          <div className={"menu "+openMenu} onClick={() => setOpenMenu(true)}>
            <div className="menu--line"></div>
            <div className="menu--line"></div>
            <div className="menu--line"></div>
          </div>
          {/* <div className="search"></div>*/}
          <div className="notifications" onClick={() => setOpenNotifications(!openNotifications)}>
            <img className="bell-svg" src={openNotifications ? images.notifications_or : images.notifications} alt=""/>
            {notifNbr > 0 ? <div className="notifications_nbr">{notifNbr}</div> : null}
          </div>
          <div className="header_rs">
            <a href="https://www.instagram.com/la__beautyroom?igsh=aWc5NzRwbGhhdDFo" target="_blank" title="Nouvel onglet - lien vers Instagram L'Oreal">
              <img src={images.instagram} alt="Instagram" />
            </a>
            <a href="https://www.tiktok.com/@la_beautyroom?_t=8qmgebBcLzd&_r=1" target="_blank" title="Nouvel onglet - lien vers TikTok L'Oreal">
              <img src={images.tiktok} alt="TikTok" />
            </a>
          </div>
        </div>
        <div className="middle_part">
          <Link to='/'>
            <div className="logo">
              <img src={images.we_are_lol_white} alt="logo white"/>
            </div>
          </Link>
        </div>
        <div className="right_part">
          <div className="points">
            <div className="nbr_points">{account.points != null ? account.points : 0}</div>
            <div className="txt_points">points</div>
          </div>
          <Link to='/profile'>
          <div className="avatar" style={style}></div>
          </Link>
        </div>
      </header>
      <div className="fix_height_header"></div>
      <InsMenu open={openMenu} close={() => setOpenMenu(false)} sap={account.isForwork}/>
      <Notifications
        class={openNotifications ? 'open' : 'close'}
        close={() => setOpenNotifications(false)}
        refresh={() => getAllNotifications()}
      />
    </div>
  )
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account,
  notifications: state.notifications
})

const mapDispatchToProps  = (dispatch) => ({
  setNotifications: (notifications) => { dispatch(actions.setNotifications(notifications)) }
})

export default (connect(mapStateToProps, mapDispatchToProps)(InsHeader))
