import './BrandsVideo.scss'
import React, { Component } from 'react'
import images from '../../../../ressources/images'
import { statVideo } from '../../../../api/brands'

export class YoutubeVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      youtubeId: this.props.match.params.youtube_id,
      backLink: ''
    }
  }
  componentDidMount() {
    let videoId = this.props.location == undefined ? this.props.location.state.id : this.props.location.search.split('=')[1];
    statVideo(videoId)
  }

  UNSAFE_componentWillMount() {
    let brandId = this.props.match.params.brandId
    this.setState({backLink: '/brands/videos/'+brandId})
  }

  /**
   * Retour à la page précédente
   */
  _goBack = () => {
    const { backLink, history, location, state } = this.props;
    if(location.state && location.state.transitionName){ 
      history.goBack();
    } else {
      history.replace(backLink, {transitionName: "slide_out",...state});
    }
  }

  render() {
    let {youtubeId} = this.state
    
    return (
      <>
        <div className="video_back_link" onClick={this._goBack}>
          <img src={images.arrow_left_white} alt=""/>
        </div>
        <div className="brand_video">
          <iframe id="video" width="100%" title={youtubeId} src={"https://www.youtube.com/embed/"+youtubeId+"?&autoplay=1&cc_load_policy=1&rel=0&showinfo=0"} frameBorder="0" allowFullScreen></iframe>
        </div>
      </>
    )
  }
}

export default YoutubeVideo
