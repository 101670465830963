import React from 'react'
import './InsRadio.scss'

const InsRadio = ({ 
  value,
  label,
  name,
  checked,
  onChange
}) => (
  <div className="radio-button">
    <input
      type="radio" 
      id={name}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={name}>{label}</label>
  </div>
)

export default InsRadio
