import React, { useState, useEffect } from "react"
import images from "../../../ressources/images"
import { withTranslation } from "react-i18next"
import InputRange from "react-input-range"
import { setFilter } from "../../../api/shop"
import InsButton from "../../InsButton/InsButton"
import InsLoader from "../../InsLoader/InsLoader"
// import 'react-input-range/lib/css/index.css'

function ShopFilter(props) {
  const [selectFilter, setSelectFilter] = useState("points")
  const [pointsValue, setPointsValue] = useState({ min: 1, max: 40000 })
  const [category, setCategory] = useState("")
  const [contentHeight, setContentHeight] = useState(window.innerHeight)
  const { categories, loader, t } = props

  const chooseFilter = (value) => {
    setSelectFilter(value)
  }

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }

  const selectCat = (name) => {
    setCategory(name)
  }

  const submitFilter = (resetFilters = false) => {
    let obj = {}
    if (resetFilters === true) {
      obj.min = 1
      obj.max = 40000
      obj.category = ""
    } else {
      obj.min = pointsValue.min
      obj.max = pointsValue.max
      if (category) {
        obj.category = category
      }
    }
    setFilter(obj).then((data) => {
      props.updatedProducts(data)
    })
  }

  const resetFilter = () => {
    let catRadio = document.getElementsByName("category")
    for (let i = 0; i < catRadio.length; i++) {
      if (catRadio[i].checked) {
        catRadio[i].checked = false
      }
    }
    setPointsValue({ min: 1, max: 40000 })
    setCategory("")
    submitFilter(true)
  }

  const onChangePoints = (value) => {
    if (value.min < 1) {
      value.min = 1
    }
    if (value.max > 40000) {
      value.max = 40000
    }
    setPointsValue(value)
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
  }, [])

  let contentStyle = { height: contentHeight - 120 }

  return loader ? (
    <InsLoader />
  ) : (
    <div className={props.className} style={contentStyle}>
      <div className="header_filter">
        <div className="title">{t("common:filter")}</div>
        <div className="close_filter" onClick={props.filter}>
          <img src={images.closeCross} alt="" />
        </div>
      </div>
      <div className="select_filter">
        <div
          className={selectFilter === "points" ? "select_points selected" : "select_points"}
          onClick={() => chooseFilter("points")}
        >
          {t("shop:points")}
        </div>
        <div
          className={selectFilter === "cat" ? "select_cat selected" : "select_cat"}
          onClick={() => chooseFilter("cat")}
        >
          {t("shop:categories")}
        </div>
      </div>
      <div className="filter_container">
        <div className={selectFilter === "points" ? "filter_points show" : "filter_points"}>
          <div className="range_selector">
            <div className="range">
              <div className="min">1 pts</div>
              <div className="max">40 000 pts</div>
            </div>
            <InputRange minValue={1} maxValue={40000} value={pointsValue} onChange={(value) => onChangePoints(value)} />
          </div>

          <div className="range_select">
            <span className="min">{pointsValue.min}</span>
            {t("common:to")}
            <span className="max">{pointsValue.max}</span>
            points
          </div>
        </div>
        <div className={selectFilter === "cat" ? "filter_cat show" : "filter_cat"}>
          {categories.categories.map((category) => {
            return (
              <div key={category.id} className="category_wrapper">
                <input type="radio" id={category.name} name="category" className="category" />
                <label htmlFor={category.name} onClick={() => selectCat(category.name)}>
                  {category.name}
                </label>
              </div>
            )
          })}
        </div>

        <InsButton text={t("common:validate")} onClick={() => submitFilter()} />
        <p className="reset_filter" onClick={resetFilter}>
          {t("shop:reset_filter")}
        </p>
      </div>
    </div>
  )
}

export default withTranslation()(ShopFilter)
