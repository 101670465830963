import './ProfileOrder.scss'
import React, {useState, useEffect} from 'react'
import InsLoader from '../../../../components/InsLoader/InsLoader'
import { getUserOrder } from '../../../../api/account'
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import OrderItem from '../../../../components/Profile/OrderItem'
import images from '../../../../ressources/images'

const ProfileOrder = (props) => {
  const [contentHeight,setContentHeight] = useState(window.innerHeight);
  const [loader, setLoader] = useState(true)
  const [order, setOrder] = useState({})  
  const orderNbr = props.match.params.id
  const {t} = props

  const updateDimensions = () => {
    setContentHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    const getOrder = () => {
      
      getUserOrder(orderNbr).then(order => {
        setOrder(order)
        setLoader(false)
      })
    }
    getOrder()
  }, [orderNbr])

  let contentStyle = {height: contentHeight - 60}
  return (
    <div className="order_page">
      <div className="header">
      <Link className='back_link' to={{ pathname: '/profile/orders', state: { transitionName: "slide_out" }}}></Link>
        {t('account:order')} N° {orderNbr}
      </div>

      <div className="order_container" style={contentStyle}>
        {loader?
          <InsLoader/>
        :
          <>
            <div className="order_wrapper">
              {order.elements.products.map(product => (
                <OrderItem
                  key={product.ean}
                  brand={product.brand}
                  name={product.name}
                  points={product.unitCost}
                  picture={product.picture ? product.picture.name.name_333 : images.we_are_lol_black}
                  quantity={product.quantity}
                />
              ))}
            </div>
  
            <div className="bottom_content">
            <div className="cart_total">TOTAL : {order.elements.totalCost} points</div>
  
            <div className="delivery_address">
              <div className="title">{t('shop:my_delivery_address')}</div>
              <div className="delivery_address_container">
                <div className="delivery_infos">
                  <div className="name">{order.address.firstname} {order.address.lastname}</div>
                  <div className="address">
                    {order.address.address} - {order.address.postal_code + ' ' + order.address.city}
                    <div className="additional">
                      {order.address.addres2 ? order.address.addres2 : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default withTranslation()(ProfileOrder)
