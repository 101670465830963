import {axiosApi} from './helper'

/**
 * GET ALL NOTIFICATIONS
 */
export function getNotifications() {
  return axiosApi('notifications', {}, 'get')
}

/**
 * NOTIFICATION SEEN
 */
export function seenNotification(id) {
  return axiosApi('notification/'+ id, {}, 'post')
}
