import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next';
import InsButton from '../../../../components/InsButton/InsButton';
import InsInput from '../../../../components/InsInput/InsInput';
import Validate from '../../../../ressources/validation';
import ValidatePassword from '../../../../components/ValidatePassword/ValidatePassword';
import StepCount from '../../../../components/StepCount/StepCount';
import { ReCaptcha } from 'react-recaptcha-v3'
import './Step3.scss'

class Step3 extends Component {
  constructor(props){
    super(props)
    this.state = {
      sponsorship: '',
      sponsorshipError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
      passwordConfirm: '',
      passwordConfirmError: '',
      cgu: false,
      captcha : ''
    }
  }
  static propTypes = {
    onValidate: PropTypes.func
  }

  componentDidMount() {
    this.getCodeSponsorship()
  }

  getCodeSponsorship = () => {
    const {userInfo} = this.props
    const {code_parrainage} = userInfo

    if(code_parrainage) {
      this.setState({sponsorship: code_parrainage})
    }
  }

  _handleChange = input => e => {
    this.setState({[input] : e.target.value})
  }

   /**
    * Valide le champ email
    */
  _validateEmail = () => {
    const error = Validate('email', this.state.email);
    this.setState({
      emailError: error
    })
    return !error;
  }

  /**
   * Valide le champ parrainage
   */
  _validateSponsorship = () => {
    const error = Validate('sponsorship', this.state.sponsorship);
    this.setState({
      sponsorshipError: error
    })
    return !error;
  }
  
  /**
   * Valide le champ password
   */
  _validatePassword = () => {
      const error = Validate('password', this.state.password);
      this.setState({
          passwordError: error
      })
      return !error;
  }

  /**
   * Valide la confirmation du champ password
   */
  _validatePasswordConfirm = () => {
    const { password, passwordConfirm } = this.state
    const { t } = this.props
    if( password !== passwordConfirm && !Validate('password', this.state.password)) {
      this.setState({
        passwordConfirmError: t('error:validation.password.not_same')
      })
    } else {
      this.setState({
        passwordConfirmError: ''
      })
    }
    return (password === passwordConfirm);
  }

  /**
   * Vailde le formulaire de l'étape
   */
  _validateFormStep = () => {
    const { password, passwordConfirm, email, sponsorship } = this.state    
    return (
      !Validate('sponsorship', sponsorship) &&
      !Validate('password', password) &&
      !Validate('email', email) &&
      password === passwordConfirm
    )
  }

  /**
   * CAPTCHA
   */
  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({captcha: recaptchaToken})
  }

  _onValidate = () => {
    this._cgu();
    const { onValidate } = this.props
    const { sponsorship, captcha, email, password } = this.state
    const userInfo = {
      email: email,
      plainPassword: password,
      captcha : captcha,
      code_parrainage: sponsorship
    }
    onValidate(userInfo);
  }

  /**
   * CGU
   */
  _cgu = () => {
    this.setState({cgu: !this.state.cgu})
  }
  
  render() {
    const { userInfo, t } = this.props
    const {sponsorship} = this.state
    const {hasCode} = userInfo
    
    
    const { cgu, emailError, passwordError, passwordConfirmError, password, sponsorshipError } = this.state
    return (
      <>
        <div className={cgu ? "cgu-modal--overlay is--open" : "cgu-modal--overlay is--close"}></div>
        <section className={cgu ? "cgu-modal is--open" : "cgu-modal is--close"}>
          <div className="cgu-modal--delete" onClick={this._cgu}></div>
          <p className="cgu-modal--title">Conditions générales</p>
          <p className="cgu-modal--text">
            <Trans i18nKey="connect:cgu">
              cgu <a href={process.env.REACT_APP_URL + '/backend/uploads/WALL_Privacy_Policy_FR.pdf'} target="_blank">cgu link</a>.
            </Trans>
          </p>
          <InsButton
              text="Accepter les conditions"
              onClick={this._onValidate}
            />
        </section>
        <form className="default-container col-flex" onSubmit={(e) => e.preventDefault()}>
          <StepCount step={3}/>
          <p className="bold-label centered mb50">
            {t("connect:step3")}
          </p>
          <div>
            <InsInput
              name="email"
              type="email"
              placeholder={t("connect:email_address")}
              onChange={this._handleChange('email')}
              onBlur={this._validateEmail}
              error={emailError}
            />
          </div>
          <div>
            <InsInput 
              name="password"
              type="password"
              placeholder={t("connect:password")}
              onChange={this._handleChange('password')}
              error={passwordError}
            />
          </div>
          <div>
            <InsInput 
              name="passwordConfirm"
              type="password"
              placeholder={t("connect:password_confirm")}
              onChange={this._handleChange('passwordConfirm')}
              onBlur={this._validatePasswordConfirm}
              error={passwordConfirmError}
            />
          </div>
          <div className="step3-validate-password">
            <ValidatePassword 
              value={password} 
            >
              {t("connect:validate_pwd.help")}
            </ValidatePassword>
          </div>
          <div className="bottom-container">
            {!this._validateFormStep() &&
              <div className="alert_msg mb10">
                {t('connect:required_only')}
              </div> 
            }
            <ReCaptcha
              sitekey="6Ldg5rQUAAAAAGd82vPYHsmf6gRaPdFGNHMnGcCQ"
              action='action_name'
              verifyCallback={this.verifyCallback}
            />
            <InsButton
              text={t("common:next")}
              onClick={this._cgu}
              disabled={!this._validateFormStep()}
            />
          </div>
        </form>
      </>
    )
  }
}

export default withTranslation()(Step3)