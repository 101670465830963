import React from "react";
import { Link } from "react-router-dom"

//styling modules
import './InsWallHeader.scss';

import images from '../../ressources/images'
function WallHeader(){
  return(
    <div className="InsWallHeader">
      <div>
        <Link className='InsWallHeader_link' to={{ pathname: '/wall', state: { transitionName: "slide_in" }}}>
            <img src={images.wall_icon_list_selected} alt="" />
        </Link>
      </div>
      <div>
        <Link className='InsWallHeader_link' to={{ pathname: '/wall/filter', state: { transitionName: "slide_in" }}}>
            <img src={images.wall_icon_filter} alt="" />
        </Link>
      </div>
    </div>
  )
}

export default WallHeader;

