import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import InsButton from '../../../components/InsButton/InsButton';
import InsHeaderConnect from '../../../components/InsHeaderConnect/InsHeaderConnect';
import InsInput from '../../../components/InsInput/InsInput';
import Validate from '../../../ressources/validation';
import ValidatePassword from '../../../components/ValidatePassword/ValidatePassword';
import { NewPasswords } from '../../../api/account';
import './NewPassword.scss'

export class NewPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
        password: '',
        passwordConfirm: '',
        passwordError: '',
        passwordConfirmError: '',
        token: this.props.match.params.token
    }
  }

  handleChange = input => e => {
    this.setState({[input] : e.target.value}) 
  }

  /**
   * Valide la confirmation du champ password
   */
  _validatePasswordConfirm = () => {
    const { password, passwordConfirm } = this.state
    const { t } = this.props
    if( password !== passwordConfirm && !Validate('password', this.state.password)) {
      this.setState({
        passwordConfirmError: t('error:validation.password.not_same')
      })
    } else {
      this.setState({
        passwordConfirmError: ''
      })
    }
    return (password === passwordConfirm);
  }

  /**
   * Vailde le formulaire de l'étape
   */
  _validateFormStep = () => {
    const { password, passwordConfirm } = this.state
    return (
      !Validate('password', password) &&
      password === passwordConfirm
    )
  }

  /**
  * Valide le champ password
  */
  _validatePassword= () => {
    const error = Validate('password', this.state.password);
    this.setState({
        passwordError: error
    })
    return !error;
  }

  /**
   * Envoie le nouveau password
   */
  changePassword = () => {
    const {history} = this.props
    const {password, passwordConfirm} = this.state
    const token = history.location.state.token
    let obj = {
      password: password,
      passwordConfirm: passwordConfirm
    }
    NewPasswords(obj, token).then(() => {
      history.replace("/connect/login")
    }, (error) => {
      this.setState({passwordConfirmError: error})
    })
  }

  continue = () => e =>{
    const { password, passwordConfirm } = this.state    
    password === passwordConfirm ? this.changePassword() : this._validateFormStep()
  }

  render() {
    const { password } = this.state
    const { t } = this.props
    return (
      <div>
        <InsHeaderConnect title={t("connect:new_password")} />
        <div className="default-container col-flex">
          <div className="msg_new_pwd">Une nouvelle politique de mot de passe a été mise en place, veuillez le mettre à jour.</div>
          <ValidatePassword value={password}
            >
              <div className='bold-label mt50'>
                {t("connect:new_password_title")}
              </div>
          </ValidatePassword>
          <InsInput 
              onChange={this.handleChange('password')} 
              type="password" 
              name="new_password" 
              placeholder={t("connect:new_password")} 
          />
          <InsInput 
              onChange={this.handleChange('passwordConfirm')} 
              type="password" 
              name="verif_password"
              onBlur={this._validatePasswordConfirm} 
              placeholder={t("connect:new_password")} 
              error={this.state.passwordConfirmError}
          />
          <div className="bottom-container">
            <InsButton 
              text={t("common:validate")}
              onClick={this.continue()}
              disabled={!this._validateFormStep()}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(NewPassword)
