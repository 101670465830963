import React from 'react'
import images from '../../../ressources/images'
import { Link } from "react-router-dom"

function ShopHeader(props) {
  const {account, cart} = props
  const {points} = account

  return (  
    <div className="shop_header">
      <div className='shop_header-link filter' onClick={props.filter}>
          <img src={images.wall_icon_filter} alt="" />
      </div>
      <div className="shop_header-points">
        <div className="points">
          <span className="nbr_points">{points && Number.isInteger(cart.total) ? points - cart.total : points}</span>
          <span className="txt_points">points</span>
        </div>
      </div>
      <Link className='shop_header-link cart' to={{ pathname: '/shop/cart', state: { transitionName: "slide_in" }}}>
        {cart.count > 0 && <span className='count'>{cart.count}</span>}
      </Link>
    </div>
  )
}


export default ShopHeader
