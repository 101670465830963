import * as types from "./actionsTypes";

export function updatePath(path) {
  return {
    type: types.NAVIGATION_SET_PATH,
    data: path
  }
}

export function updatePathBrand(bool) {  
  return {
    type: types.NAVIGATION_SET_PATHBRAND,
    data: bool
  }
}
