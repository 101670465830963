import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Swipeable from "react-swipy";
import { checkQuestion } from '../../../api/training';
import Card from "../../../components/Card";
import ModalQuizz from '../ModalQuizz/ModalQuizz';

import './Affinite.scss'
import images from '../../../ressources/images';

export class Affinite extends Component {
  static propTypes = {
    questions: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      allQuestions: [],   // Toutes les questions
      allResponses: [],   // Toutes les bonnes reponses
      allChoices: [],     // Tous les choix possible
      question: [],       // Question en cours
      questionId: null,   // Id de la question en cours
      choice: [],         // Choix de la question en cours
      userChoice: [],     // Choix de l'utilisateur sur la question en cours
      response: [],       // Bonne réponse de la question en cours
      step: 0,            // Numéro de la question
      modal: false,
      result: null,
      indication: false,
      indicationIcon : null,
      ready: false,
    }

    this.swipeStep = 0
  }

  componentDidMount() {
    this.getDatas()  
    window.addEventListener("touchmove", this.onDragMove);
    window.addEventListener("touchend", this.onDragEnd);
  }

  componentWillUnmount() {
    window.removeEventListener("touchmove", this.onDragMove);
    window.removeEventListener("touchend", this.onDragEnd); 
  }

  /**
   * Récupere les questions et choix du QCM
   */
  getDatas = () => {
    // Recupere les questions
    const dataQuestions = this.props.questions.map((elem) => (!elem.question.checked && elem.question))

    // Recupere les ids des questions
    const dataIdQuestions = this.props.questions.map((elem) => (elem.id))

    const tempResponses = this.props.questions.map((elem) => (elem.responses))
    
    // Récupere les choix possibles
    let dataChoix = []
    tempResponses.forEach(response => {
      dataChoix.push(response.map(elem => elem.response))
    });

    let dataResponses = []
    tempResponses.forEach(response => {
      response.forEach(element => {
        if(element.valid){
          dataResponses.push(element.response)
        }
      });
    });
    
    this.setState({
      allChoices: dataChoix,
      allQuestions: dataQuestions,
      allResponses: dataResponses,
      question: dataQuestions[this.state.step],
      questionId: dataIdQuestions[this.state.step],
      choice: dataChoix[this.state.step],
    })
  }

  /**
   * Affiche l'icone sur la carte
   */
  onDragMove = () => {   
    this.setState({
      indication: true,      
      indicationIcon: this.swipeRef && this.swipeRef.state.offset > 0 ? true : false
    })
    
  }

  /**
   * Masque l'icone sur la carte
   */
  onDragEnd = () => {    
    this.setState({
      indication: false,
      indicationIcon: null
    })
    
  }

  /**
   * Supprime les cards apres le swipe
   */
  remove = () => {
    this.setState(({ choice }) => ({ 
      choice: choice.length > 1 ? choice.slice(1)  : choice
    }));
  }

  // Recupére le dernier element d'un tableau
  getLast = (array) => array[array.length - 1]

  /**
   * Mets a jour le tableau de reponse quand swipe droite (vrai)
   */
  swipeRight = () => {
    const {choice} = this.state
    
    this.setState({
      userChoice: [...this.state.userChoice, choice[0]],
      indicationIcon: false
    })
  }

    /**
   * Récupere la réponse true/false
   */
  _onSwipe = (direction) => { 
    direction !== "left" && this.swipeRight()
    this.setState({
      indication: false,
    })
  }
  
  /**
   * Vérifie les réponses de l'utilisateur et affiche le resultat
   */
  checkResponse = () => {
    const { userChoice, questionId, step } = this.state
    const { isPreview, moduleId } = this.props    

    this.props.nextQuestion(step)
    if(isPreview) {
      this.nextStep()
    } else {
      checkQuestion(moduleId, questionId, userChoice).then((data) =>{
        this.setState({
          modal: true,
          result: data.response,
          response: data.good_reponse,
          checked: data[0] || false
        })
      })
    }
  }

   /**
   * Passe à la question suivante apres chaque swipe
   */
  nextCard = () => {
    let { step, allChoices } = this.state

    // On passe la question a check dans le parent
    this.props.nextQuestion(step)

    this.swipeStep++
    this.remove()

    if(this.swipeStep === allChoices[step].length) {
      this.checkResponse()
    }
  }

  /**
   * Passe au mini-jeu suivant
   */
  nextStep = () => {
    const { step } = this.state
    if( step < (this.state.allQuestions.length - 1) ) {
      this.swipeStep = 0
      this.setState({
        userChoice: [],
        step: step+1,
        modal: false,
        result: null,
        
      }, () => {this.getDatas()})
    } else {
      this.props.nextQuizz()      
      this.swipeStep = 0
      this.setState({
        userChoice: [],
        question: this.state.allQuestions[step],
        choice: this.state.allChoices[step],
        response: this.state.allResponses[step],
        step: 0,
        modal: false,
      })
    }
  }

  render() {  
    const {t} = this.props
    const {question, choice, modal, result, response, indication, indicationIcon, ready, step} = this.state

    return (
      <div className="affinite_wrapper"> 
          <div className='question'>
            {question}
          </div> 
        {!ready ?
          <>
            <div className="affinite_gif">
              <img src={images.truefalse} alt=""/>
            </div>
            <button className="btn_verif" onClick={() => {this.setState({ready: true})}}>{t("common:ready")}</button>
          </>
        :
          choice.length > 0 && (
            <div className="affinite_container">
              <Swipeable
                ref={(ref) => this.swipeRef = ref}
                onAfterSwipe={this.nextCard}
                onSwipe={this._onSwipe}
                limit={120}
                min={180}
              >
                <Card backgroundImage={'url('+process.env.REACT_APP_URL+choice[0]+')'}/>
              </Swipeable>
              {indication && <div className='indication'>{indicationIcon ? <img src={images.indicationLike} alt="vrai"/> : <img src={images.indicationDislike} alt="faux"/> }</div>}
              {choice.length > 1 && <Card zIndex={0} backgroundImage={'url('+process.env.REACT_APP_URL+choice[1]+')'}/>}
              {choice.length <= 1 && <Card zIndex={-2}></Card>}
            </div>
          )
        }        
        <ModalQuizz reveal={modal} result={result} type="truefalse" response={response} step={step} nextStep={this.nextStep}/>
      </div>
    )
  }
}

export default withTranslation()(Affinite)