import './WallPost.scss';
import React,{ Component } from 'react'
import InsHeader from '../../../../components/InsHeader/InsHeader';
import InsNavigation from '../../../../components/InsNavigation/InsNavigation';
import NewPostBtn from '../../../../components/Wall/NewPostBtn';
import InsLoader from '../../../../components/InsLoader/InsLoader';
import { withTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { getPost, addLike } from '../../../../api/wall';
import PostOptions from '../../../../components/Wall/Post/PostOptions';
import InsComments from '../../../../components/InsComments/InsComments';
import { connect } from 'react-redux'
import images from '../../../../ressources/images';

export class WallPost extends Component {
  constructor(props) {
    super(props)
    this.state = {
      post: [],
      loader: true,
      legendHeight: 47,
      legendBtn: true,
      showBtn: false,
      contentHeight: window.innerHeight
    }

    this.postId = this.props.match.params.id
  }

  componentDidMount() {      
    this.getPostDatas()
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  getPostDatas = () => {
    getPost(this.postId).then((data) => {
      this.setState({
        post: data,
        loader: false,
      }, () => {      
        this.legendHeight()
      })
    })  
  }

  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }

  addLike = () => {
    
    addLike(this.postId).then((data) => {
      this.setState(prevState => ({
        post: {
            ...prevState.post,    
            likes: data.like_count,
            currently_liked: data.user_liked
        }
      }))
    })
  }

  legendHeight = () => {
    if(this.refs["content"]) {
      const scrollHeight = this.refs["content"].scrollHeight
      const clientHeight = this.refs["content"].clientHeight
      
      if(scrollHeight > clientHeight) {
        this.setState({showBtn: true})
      }
    }
  }

  showContent = () => {
    const {legendHeight} = this.state
    const scrollHeight = this.refs["content"].scrollHeight
    this.setState({
      legendHeight: legendHeight === scrollHeight ? 47 : scrollHeight,
      legendBtn: !this.state.legendBtn
    })
  }

  focusComment = () => {
    const commentArea = document.getElementById('add_comment')
    commentArea.scrollIntoView()
    commentArea.focus()
  }

  videoControls = (index, item) => {
    let videos = [this.refs['video_blur_'+index], this.refs['video_not_blur_'+index]];
    if(videos[0]) {
      this.toggleVideos(videos)
    }
  }

  onChangeCarousel = (index, item) => {
    // let videosSelected = document.querySelector('.selected').querySelectorAll('video')
    // videosSelected.forEach(video => {
    //     video.play()
    // })
    this.pauseCurrentVideos()
  }

  playVideos = (videos) => {    
    this.currentVideos = videos
    videos.forEach(video => {
      video.play();
      video.classList.add('playing');
    })
  }
  pauseVideos = (videos) => {
    videos.forEach(video => {
      video.pause();
      video.classList.remove('playing');
    })
  }

  toggleVideos = (videos) => {
    if(videos[0].paused) {
      this.playVideos(videos)
    } else {
      this.pauseVideos(videos)
    }
  }

  pauseCurrentVideos = () => {
    if(this.currentVideos) {
      this.pauseVideos(this.currentVideos)
    }
  }

  _goBack = () => {
    const { history, location, state } = this.props;
    if(location.state && location.state.transitionName){ 
      history.goBack();
    } else {
      history.replace('/wall', {transitionName: "slide_out",...state});
    }
  }

  render() {
    const {account, t, history} = this.props
    const {contentHeight, legendHeight, legendBtn, loader, post, showBtn } = this.state
    const {currently_liked, user, files} = post
    let contentStyle = {height: contentHeight - 120}
    let legendStyle = {height: legendHeight}

    return (
      <div id="page_post">
        <InsHeader/>

        <div className="content post" style={contentStyle}>
        {loader ?
          <InsLoader/>
          :          
            <>
              <div className='back_link_wall' onClick={this._goBack}>
                <img src={images.arrow_left_white} alt=""/>
                <span>{t('common:back')}</span>
              </div>
              <section className="header_post">

  
                <div className="avatar">
                  <img src={user.picture ? process.env.REACT_APP_URL + user.picture : images.icon_user} alt=""/>
                </div>
  
                <div className="username">
                  {user.firstname +' '+ user.lastname}
                </div>
  
                {post.mine &&
                  <PostOptions history={history} postId={this.postId}/>
                }
  
              </section>
  
              <div className="container">
  
                <div className="media">
                  {files.length === 1 ?
                    <div className="no-carousel">
                      <div className="media_wrapper">
                        <div className="media">
                          {files.map((file, index) => (
                            file.type === "video" ?
                              <div 
                                key={index} 
                                onClick={() => this.videoControls(index)}
                              >
                                <video ref={"video_blur_"+index} className="blur media_video" poster={process.env.REACT_APP_URL + file.name.thumbnail} src={process.env.REACT_APP_URL + file.name.name} alt="" playsInline preload="auto"/>
                                <video ref={"video_not_blur_"+index} className="not-blur media_video" poster={process.env.REACT_APP_URL + file.name.thumbnail} src={process.env.REACT_APP_URL + file.name.name} alt="" playsInline preload="auto"/>
                                <img className="play_icon" src={images.icon_play_white} alt='' />
                              </div>
                            :
                              <img src={process.env.REACT_APP_URL + file.name.name} alt="" key={index}/>  
                          ))}
                        </div>
                      </div>
                    </div>
                  :
                    <Carousel
                      className="carousel"
                      showArrows={false}
                      showThumbs={false}
                      showStatus={files.length > 1}
                      statusFormatter={(current, total) => current + ' / ' + total}
                      showIndicators={files.length > 1}
                      emulateTouch={true}
                      onClickItem={this.videoControls}
                      onChange={this.onChangeCarousel}
                      verticalSwipe="natural"
                    >
                      {files.map((file, index) => (
                          file.type === "video" ?
                          <div className="video_preview" key={index}>
                            <video ref={"video_blur_"+index} className="blur media_video" src={process.env.REACT_APP_URL + file.name.name} alt="" playsInline preload="auto"/>
                            <video ref={"video_not_blur_"+index} className="not-blur media_video" src={process.env.REACT_APP_URL + file.name.name} alt="" playsInline preload="auto"/>
                            <img className="play_icon" src={images.icon_play_white} alt='' />
                        </div>
                          :
                          <img src={process.env.REACT_APP_URL + file.name.name} alt="" key={index}/>  
                      ))}
                    </Carousel>                  
                  }
                </div>
  
                <section className="btn_container">
                  <div className={currently_liked ? 'like_icon liked' : 'like_icon'} onClick={this.addLike}></div>
                  <div className="comment_icon" onClick={this.focusComment}></div>
                  <NewPostBtn top="50%" position="absolute"/>
                </section>
  
                <div className="likes">
                  {post.likes ? post.likes : 0} {t('wall:likes')}
                </div>
  
                {post.content && post.content !== "undefined" ?             
                  <div>
                    <div className="legend"  style={legendStyle} ref="content">
                      <div className="content_post">
                        <span className="username">{user.firstname +' '+ user.lastname}</span>
                        {post.content !== "undefined" &&  <span dangerouslySetInnerHTML={{ __html: post.content }} />}
                      </div>
                    </div>
                    {showBtn && <div className="content_btn" onClick={this.showContent}> {legendBtn ? 'Suite' : 'Moins'}</div>}
                  </div>
                  :
                  null
                }            
              </div>
  
              <InsComments 
                user={user.firstname +' '+ user.lastname} 
                userPostPicture={user.picture ? process.env.REACT_APP_URL + user.picture : images.icon_user} 
                legend={post.content} 
                currentUserAvatar={account.picture ? process.env.REACT_APP_URL + account.picture : images.icon_user} 
                contentId={this.postId}
                callApi="wall"
              />
            </>
          }   
          </div>

        <InsNavigation/>
      </div>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  account: state.account
})

export default (connect(mapStateToProps)(withTranslation()(WallPost)))
