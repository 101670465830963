import * as types from "../actions/actionsTypes";

let initialState = {
  access_token: null,
  refresh_token: null,
  remember_email: '',
  remember_password: '',
  expire_date: 0
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_SET_TOKEN:
    return ({
      ...state, 
      access_token: action.data.access_token, 
      refresh_token: action.data.refresh_token, 
      expire_date: action.data.expire_date, 
    })
    
    case types.AUTH_DEL_TOKEN:
    return ({
      ...state, 
      access_token: null, 
      refresh_token: null, 
      expire_date: 0
    })

    case types.AUTH_SET_REMEMBER_ME:
    return ({
      ...state, 
      remember_email: action.data.email, 
      remember_password: action.data.password, 
    })
    
    case types.AUTH_DEL_REMEMBER_ME:
    return ({
      ...state, 
      remember_email: '', 
      remember_password: '', 
    })

      
    default:
      return state;
  }
}

export default authReducer;