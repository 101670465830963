import React, { Component } from 'react'
import './BrandProductsItem.scss'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {getProductCatalogPDF} from "../../../api/brands";

export class BrandProductsItem extends Component {

  static propTypes = {
    title: PropTypes.string,
    img: PropTypes.string,
    pdf: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      iframe: false,
      contentHeight: window.innerHeight
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({contentHeight: window.innerHeight})
  }

  openIframe = () => {
    this.setState({iframe: true})
    document.getElementsByClassName('brand_contentlist')[0].scrollTo(0,0)
    document.getElementsByClassName('brand_contentlist')[0].style.overflow = "hidden"
  }

  render() {
    let mediaUrl = `${window.location.origin.toString()}`;
    if(mediaUrl.includes('://www.'))
      mediaUrl = mediaUrl.replace('://www.', '://media.') + '/';
    else
      mediaUrl = mediaUrl.replace('://', '://media.') + '/';
    const {contentHeight, iframe} = this.state
    const {title,img, link, pdf,t} = this.props
    let bgImg = {backgroundImage: 'url(' + img + ')'}
    const handleClick = async () => {
      try {
        getProductCatalogPDF(pdf);
      } catch (error) {
      }
    }
    return (
      <>
        {link ?
          <div className='productlist_link'>
            <div className='product_pdf--wrapper' style={bgImg} onClick={this.openIframe}>
              <div className='product_pdf--white'>
                <p className='product_pdf--title'>{title}</p>
                <p>{t('common:discover')} ></p>
              </div>
            </div>
            {iframe && <iframe src={link} id='catalog' style={{height: contentHeight - 210}} frameBorder="0" scrolling="yes" title="catalog"></iframe>}
          </div>
        :
          <Link onClick={handleClick} className='productlist_link'
            to={{
            pathname: mediaUrl+pdf,
            state: { transitionName: "slide_in" }
            }}
            target="_blank"
          >
            <div className='product_pdf--wrapper' style={bgImg}> 
              <div className='product_pdf--white'>
                <p className='product_pdf--title'>{title}</p>
                <p>{t('common:download_pdf')}</p> 
              </div>
            </div>
          </Link>
        }
      </>
    )    
  }
}

export default withTranslation()(BrandProductsItem)
