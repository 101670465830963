import React, {useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import images from '../../ressources/images';

function GroupsNav(props) {
  const [path, setPath] = useState('')
  const idGroup = props.groupId

  // Recupére le dernier element d'un tableau
  const getLast = (array) => array[array.length - 1]

  const getPath = () => {
    const path = getLast(window.location.pathname.split('/'))
    setPath(path)
  }

  useEffect(() => {
    getPath()
  })

  const {groupName, t} = props
  
  return (
    <div className="group_nav">
       <NavLink 
          exact 
          to={{
            pathname: "/profile/groups/"+idGroup+"/courses",
            state: { transitionName: "fade_app", groupName: groupName }
          }} 
          className={path === 'courses' ? 'link active' : 'link'}
        >
          {path === 'courses' ? <img src={images.formation_icon_black} alt=""/> : <img src={images.formation_icon_grey} alt=""/>}
          <div className="text">
            {t('common:courses')}
          </div>
        </NavLink>
       <NavLink 
          exact 
          to={{
            pathname: "/profile/groups/"+idGroup+"/news",
            state: { transitionName: "fade_app", groupName: groupName }
          }} 
          className={path === 'news' ? 'link active' : 'link'}
        >
          {path === 'news' ? <img src={images.article_icon_black} alt=""/> : <img src={images.article_icon_grey} alt=""/>}
          <div className="text">
            {t('common:news')}
          </div>
        </NavLink>
       <NavLink 
          exact 
          to={{
            pathname: "/profile/groups/"+idGroup+"/shop",
            state: { transitionName: "fade_app", groupName: groupName }
          }} 
          className={path === 'shop' ? 'link active' : 'link'}
        >
          {path === 'shop' ? <img src={images.tools_icon_black} alt=""/> : <img src={images.tools_icon_grey} alt=""/>}
          <div className="text">
            {t('common:tools')}
          </div>
        </NavLink>
       <NavLink 
          exact 
          to={{
            pathname: "/profile/groups/"+idGroup+"/videos",
            state: { transitionName: "fade_app", groupName: groupName }
          }} 
          className={path === 'videos' ? 'link active' : 'link'}
        >
          {path === 'videos' ? <img src={images.video_icon_black} alt=""/> : <img src={images.video_icon_grey} alt=""/>}
          <div className="text">
            {t('common:videos')}
          </div>
        </NavLink>
    </div>
  )
}

export default withTranslation()(GroupsNav)
