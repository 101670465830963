import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './InsCheckbox.scss'

class InsCheckbox extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  static defaultPropTypes = {
  }

  render() {
    const { 
      label,
      name,
      onClick
    } = this.props    
    let className = `ins_input ins_input_checkbox`

    return (
      <div className={className+' mb20'}>
        <input 
          id={name} 
          name={name}
          onClick={onClick}
          type='checkbox'
        />
        <label htmlFor={name}>
          {label}
        </label>
      </div>
    )
  }
}

export default InsCheckbox
