import i18next from 'i18next';
import account_fr from './fr/account.json'
import common_fr from './fr/common.json';
import connect_fr from './fr/connect.json';
import error_fr from './fr/error.json';
import cgu_fr from './fr/cgu.json'
import shop_fr from './fr/shop.json'
import wall_fr from './fr/wall.json'
import courses_fr from './fr/courses.json'

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'fr',
    resources: {
        fr: {
            account: account_fr,
            cgu: cgu_fr,
            common: common_fr,
            connect: connect_fr,
            courses: courses_fr,
            error: error_fr,
            shop: shop_fr,
            wall: wall_fr
        }
    },
});

// The method we'll use instead of a regular string
export default function Strings(name, params = {}) {
    return i18next.t(name, params);
};