import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as moment from "moment";
import images from "../../ressources/images";
import "./UpcomingFormationItem.scss";

const TYPE_WORDING = {
  VIRTUAL_COURSE: "Classe virtuelle",
  PHYSICAL_COURSE: "Formation présentielle",
  TRAINING_EVENING: "Soirée de formation",
  STORE_COURSE: "Formation en magasin",
  DIGITAL_COURSE: "Formation digitale",
  DIGITAL_EVENT: "Event digital",
};

function UpcomingFormationItem(props) {
  const { status, item } = props;
  const {
    session_id: idFormation,
    brand,
    point,
    course_type: type,
    session_schedule: date,
    validated_at_formatted,
    waiting_validation,
  } = item;

  const [firstDate, setFirstDate] = useState(null);

  const momentFormat = (a) => moment(a, "DD/MM/YYYY hh:mm:ss");

  useEffect(() => {
    let dt = null;
    if (status !== "succeed") {
      // Check if first period is used
      const diffAM = momentFormat(date[0].am[1]).diff(
        momentFormat(date[0].am[0])
      );

      console.log(diffAM);

      if (diffAM !== 0) {
        dt = date[0].am[0];
      } else {
        // If first period is not used, we consider second period to be used, even tho it might not be the case
        dt = date[0].pm[0];
      }

      if (TYPE_WORDING[type] === "Formation digitale") {
        setFirstDate(momentFormat(dt).format("dddd DD MMMM YYYY"));
      } else {
        setFirstDate(momentFormat(dt).format("LLLL"));
      }
    } else {
      setFirstDate(
        momentFormat(validated_at_formatted).format("dddd DD MMMM YYYY")
      );
    }
  }, [date]);

  const background = {
    backgroundImage: "url(" + images["bg_formation_" + type] + ")",
  };

  return (
    <Link
      className="learning_link"
      to={{
        pathname: "/profile/course/" + idFormation,
        state: {
          transitionName: "slide_in",
          dataFormation: item,
          return: props.nav,
        },
      }}
    >
      <div className="learning_container" style={background}>
        <div className="learning_item-top">
          <div
            className={`learning_points ${
              status === "succeed" ? "completed" : ""
            }`}
          >
            +{point}
          </div>
          <div className="learning_item-content">
            <img src={images.picto_formation} alt="" />
            <div className="learning_brand">{brand}</div>
            <div className="learning_type">{TYPE_WORDING[type]}</div>
            {firstDate && <div className="learning_date">{firstDate}</div>}
          </div>
          {status === "upcoming" && waiting_validation && (
            <div className={`learning_completed started`}>
              En cours de validation
            </div>
          )}
          {(status === "succeed" || status === "started") && (
            <div className={`learning_completed ${status}`}>
              {status === "succeed" ? "Réalisée" : "En cours"}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default withTranslation()(UpcomingFormationItem);
