import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getVideoPoster } from '../../../ressources/helper';

export class CreateInputFiles extends Component {
  constructor(props) {
    super(props)
  
    this.filePreview = []
  }
  
  componentDidUpdate() {
    const {files, filesPreview} = this.props
    this.filePreview = []
    files.forEach((file, index) => {
      if(file.type === "video/mp4" || file.type === "video/mpeg" || file.type === "video/quicktime") {
        getVideoPoster(URL.createObjectURL(file)).then((url) => {
          
          this.filePreview[index] = url
          let thumbnail = this.refs['thumbnail_'+index]
          let thumbnail2 = this.refs['thumbnail2_'+index]
          thumbnail.src = url
          thumbnail2.src = url
          filesPreview(this.filePreview)
        })
      }
    });
  }

  _selectFiles = () => {
    document.getElementById('select_files').click()
  }

  render() {
    const{files, handleInput} = this.props
    
    return (
      <div className="create_input_files">
        <input type="file" multiple id="select_files" onChange={handleInput}/>
        <div className={files.length === 0 ? "preview no_files" : "preview files"} onClick={this._selectFiles}>
        {files.length > 0 &&
          <div className="media">
            <Carousel
              className="carousel"
              showArrows={false}
              showThumbs={false}
              statusFormatter={files.length > 1 ? (current, total) => current + ' / ' + total : (current, total) => ''}
              showIndicators={false}              
              emulateTouch={true}
              // showIndicators={files.length > 1}
            >
              {files.map((file, index) => {
                return (
                  file.type === "video/mp4" || file.type === "video/mpeg" || file.type === "video/quicktime" ?
                    <div className="video_preview" key={index}>
                      {/* <video className="not-blur" preload="auto" playsInline src={URL.createObjectURL(file)} alt=""/> */}
                      <img ref={"thumbnail_"+index} className="blur" src="" alt=""/>
                      <img ref={"thumbnail2_"+index} className="not-blur" src="" alt=""/>
                    </div>
                  :
                    <img src={file} alt="" key={index}/>
              )})}
            </Carousel>
          </div>
        }
        </div>
      </div>
    )
  }
}

export default CreateInputFiles
