import React from 'react'
import './StepCount.scss'


const StepCount = ({step}) =>  (
    (step !== 0 &&
        <div className="mt100">          
            <div className='step-count'>
                <div className={step === 1 ? 'square active' : step > 1 ? 'square passed' : 'square'}>1</div>
                <div className={step === 2 ? 'square active' : step > 2 ? 'square passed' : 'square'}>2</div>
                <div className={step === 3 ? 'square active' : 'square'}>3</div>
            </div>
        </div>  
    )
);

export default StepCount