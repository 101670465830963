import * as types from "../actions/actionsTypes";

let initialState = {
  path: null,
  fromBrand: false,
}

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NAVIGATION_SET_PATH:
      return {
        ...state,
        path: action.data
      }

    case types.NAVIGATION_SET_PATHBRAND:
      return {
        ...state,
        fromBrand: action.data
      }
    
    default:
      return state;
  }
}


export default navigationReducer;