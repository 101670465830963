//src/redux/reducers.js

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import createAccountReducer from './createAccountReducer';
import navigationReducer from './navigationReducer';
import scormReducer from './scormReducer';
import wallReducer from './wallReducer'
import accountReducer from './accountReducer';
import accountCoursesFilter from './accountCoursesFilter';
import notificationReducer from './notificationReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    account: accountReducer,
    createAccount: createAccountReducer,
    navigation: navigationReducer,
    scorm: scormReducer,
    wallFilters: wallReducer,
    accountFilters : accountCoursesFilter,
    notifications: notificationReducer
});

export default rootReducer