import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { logout } from '../../api/helper';
import { withTranslation } from 'react-i18next'
import './InsMenu.scss'
import images from '../../ressources/images';

export class InsMenu extends Component {
  static propTypes = {
    open: PropTypes.bool
  }

  constructor(props){
    super(props)
    this.sideMenuClosing = React.createRef();
    this.state = {
      closeMenu: false
    }
  }

  _logout = () => {
    logout()
  }

  closeMenu = () => {
    // this.setState({closeMenu: !this.state.closeMenu})
    this.props.close()
    this.sideMenuClosing.current.className += "close";
  }

  render() {    
    const {sap, open, t} = this.props
    const isOpen = open ? 'open ' : 'close '
    const className = 'sidemenu '+isOpen
    return (
      <div ref={this.sideMenuClosing} className={className}>
        <div className="side-menu--wrapper">
          <div className="menu2">
                <div className="menu--line2"></div>
                <div className="menu--line2"></div>
                <div className="menu--line2"></div>
          </div>
          <div className="menu3" onClick={this.closeMenu}>
                <div className="menu--line3"></div>
                <div className="menu--line3"></div>
                <div className="menu--line3"></div>
          </div>
        </div>
        
        <ul>
          <li>
            <Link to='/profile'>
              {t('account:acces_to_account')}
            </Link>
            
          </li>
          <li>
            <Link to='/profile/courses'>
              {t('account:my_training')}
            </Link>            
          </li>
          <li>
            <Link to='/profile/groups'>
              {t('account:my_groups')}
            </Link>            
          </li>
          {sap &&
            <>
              <li>
                <Link to='/profile/orders'>
                  {t('account:my_orders')}
                </Link>              
              </li>
              {/*<li>*/}
              {/*  <Link to='/parrainage'>*/}
              {/*    {t('account:sponsorship')}*/}
              {/*  </Link>*/}
              {/*  <img src={images.icon_sponsorship} alt=""/>*/}
              {/*  */}
              {/*</li>*/}
            </>
          }
          <li>
            <Link to='/contact'>
              {t('common:contact')}
            </Link>
            
          </li>
          <li onClick={this._logout}>{t('connect:disconnection')}</li>
        </ul>
      </div> 
    )
  }
}

export default withTranslation()(InsMenu)
