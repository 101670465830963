import React , { Component, Fragment } from 'react'
import { Route, Switch } from "react-router-dom"
import { withTranslation } from 'react-i18next';
import FormationsList from './FormationsList/FormationsList';
import FormationIndex from './FormationIndex/FormationIndex';
import FormationQuizz from './FormationQuizz/FormationQuizz';
import Result from '../../../components/Quizz/Result/Result';

class FormationsRouter extends Component {

  UNSAFE_componentWillMount() {
    document.body.classList.add('formation');
  }
  componentWillUnmount() {
    document.body.classList.remove('formation');
  }
  render() {
    const { match, location } = this.props

    return (
      <Fragment>
        <Switch location={location}>
          <Route exact path={`${match.path}`} component={FormationsList} />
          <Route exact path={`${match.path}/:id`} component={FormationIndex} />
          <Route exact path={`${match.path}/:id/:moduleId`} component={FormationQuizz} />
          <Route exact path={`${match.path}/:id/:moduleId/result`} component={Result} />
          <Route component={FormationsList}/>
        </Switch>
      </Fragment>
    )
  }
}

export default withTranslation()(FormationsRouter)