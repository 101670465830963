import React, {Component, Fragment} from 'react'
import { Route, Switch } from "react-router-dom"
import Homepage from './Homepage/Homepage';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import FormationsRouter from './Formations/FormationRouter'
import { withTranslation } from 'react-i18next';
import News from './Homepage/News/News';
import BrandsRouter from './Brands/BrandsRouter';
import ArticlePage from '../../../src/components/ArticlePage/ArticlePage'
import Contact from './Contact/Contact';
import Parrainage from './Parrainage/Parrainage';
import WallRouter from './Wall/WallRouter';
import ProfilRouter from './Profil/ProfilRouter';
import ShopRouter from './Shop/ShopRouter';
import { accountData } from '../../api/account';
import ReactGA from 'react-ga'

class AppRouter extends Component {
  constructor(props){
    super(props)
    this.currentCat = this._getCurrentCat()
    this.oldAnimation = this._getTransition()
    this.setInterval = null
  }

  
	initializeReactGA() {
    ReactGA.initialize('UA-151651639-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  
  _getCurrentCat = () => (
    this.props.location.pathname.split('/').filter(s => s !== "" )[0]
  )

  _sameCat = () => {
    return this.currentCat === this._getCurrentCat()
  }

  _getTransition = () => {
    let type = "fade";

    switch (this.props.history.action) {
      case "POP":
        switch (this.oldAnimation) {
          case "slide_in":
            type="slide_out"
            break;
          
          case "slide_out":
            type="slide_in"
            break;

          case "zoom_in":
            type="zoom_out"
            break;
        
          case "zoom_out":
            type="zoom_in"
            break;
          
            default:
            break;
        }
        break;



      case "PUSH":
      case "REPLACE":
      default:
        if(this.props.location.state && this.props.location.state.transitionName)
          type = this.props.location.state.transitionName
        break;
    }    
    return type
  }

  componentDidMount() {
    this.initializeReactGA()
    this.setInterval = setInterval(() => {
      accountData()      
    }, 60000);
    accountData()
  }

  componentWillUnmount() {
    clearInterval(this.setInterval)    
  }

  componentDidUpdate() {
    this.initializeReactGA()
    this.currentCat = this._getCurrentCat();
    accountData()
  }

  _onEntered = () => {
    this.oldAnimation = this._getTransition()
  }
  _onExit = (html) => {
    let newTransition = this._getTransition()
    html.classList.replace(`${this.oldAnimation}-exit`, `${newTransition}-exit`);
    html.classList.replace(`${this.oldAnimation}-exit-active`, `${newTransition}-exit-active`);
    html.classList.replace(`${this.oldAnimation}-exit-done`, `${newTransition}-exit-done`);
  }

  _renderSwitch() {
    const { match,location } = this.props
    return (
      <section className="route-section">
        <div className="route-section-inner">
          <Switch location={location}>
            <Route exact path={`${match.path}`} component={Homepage} />
            <Route exact path={`${match.path}news/:id`} component={News} />
            <Route path={`${match.path}brands`} component={BrandsRouter} />
            <Route path={`${match.path}formations`} component={FormationsRouter} />
            <Route path={`${match.path}wall`} component={WallRouter} />
            <Route path={`${match.path}profile`} component={ProfilRouter} />
            <Route path={`${match.path}contact`} component={Contact} />
            <Route path={`${match.path}shop`} component={ShopRouter} />
            <Route exact path={`${match.path}articles/:brandId/:articleId`} component={ArticlePage} />
            <Route exact path={`${match.path}parrainage`} component={Parrainage} />
            <Route component={Homepage} />
          </Switch>  
        </div>
      </section>
    )
  }

	render() {
    const { location } = this.props
    const transitionType = this._getTransition()
    return(
      <Fragment>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            classNames={transitionType}
            timeout={250}
            onEntered={this._onEntered}
            onExit={this._onExit}
            onExiting={this._onExit}
            onExited={this._onExit}
          >
            {this._renderSwitch()}
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    )
  }
}

export default withTranslation()(AppRouter)