import React, { Component } from 'react'
import './BrandContent.scss'
import { Link } from "react-router-dom"
import BrandlistMenu from '../../../../components/Brands/BrandlistMenu/BrandlistMenu';
import { getArticlesByBrands } from '../../../../api/brands';
import BrandArticlesItem from '../../../../components/Brands/BrandArticlesItem/BrandArticlesItem';
import { getBrandById } from '../../../../api/brands';
import images from '../../../../ressources/images';
import InsNavigation from '../../../../components/InsNavigation/InsNavigation';
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar';

export class BrandArticles extends Component {
    
    constructor(props) {
      super(props);
      this.state = { 
        whoActive: 'articles',
        brandId: 0,
        dataArticles: [],
        dataBrand: []
      };
    }

    UNSAFE_componentWillMount(){
      let brandId = this.props.match.params.brandId
      this.setState({brandId:brandId});
    }

    componentDidMount(){      
      this.getAllArticlesByBrands();
      this.getBrandByIdFront();
    }

    /**
   * Récupere tout les articles disponibles
   */
  getAllArticlesByBrands = () => { 
    const {brandId} = this.state
    getArticlesByBrands(brandId).then((data) =>{
      this.setState({dataArticles: data})
    })
  }

  getBrandByIdFront = () => {
    const {brandId} = this.state
    getBrandById(brandId).then((data) =>{
      this.setState({dataBrand: data})
    })
  }

  render() {
    const {dataArticles, dataBrand, brandId, whoActive} = this.state
    return (
      <>
      <div className="white--wrapper">
          <Link className='brandlist_backlink' to={{ pathname: '/brands', state: { transitionName: "slide_out" }}}>
            <img src={images.arrow_left_black} alt="" />
          </Link>
          <div className="brandlist_content">
            <div className='flex-row-center'>
              <div className="brand_logo">
                <img className="brandlist_logo" src={process.env.REACT_APP_URL+dataBrand.logoNoir} alt="logo" />
              </div>
            </div>
            <BrandlistMenu whoActive={whoActive} brandId={brandId} />
            <div className="brand_contentlist">
              { dataArticles.length > 0 ?
                dataArticles.map((article) => 
                  <BrandArticlesItem
                  key={article.id}
                  id={article.id}
                  title={article.title}
                  subtitle={article.subtitle}
                  picture={article.picture}
                  content={article.content}
                  brandId={brandId}
                  />
                )
                :
                <div className="back_soon">
                  <div>Pigiste en vacances…</div>
                  <div>revenez bientôt </div>
                  <span className="smile" role="img" aria-label="">&#128578;</span>
                </div>
              }
              <InsCGUBar/>
            </div>
            <InsNavigation/>
          </div>
      </div>
      </>
    )
  }
}

export default BrandArticles
