import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom"
import { connect } from 'react-redux'
import * as actions from '../../store/actions/navigationActions'
import './InsNavigation.scss'

class InsNavigation extends Component {
  _isActive = (match, location, to ) => {
    
    // verif si match (match: link actuel == link active)
    if (match) {
      // maj redux persistor (garde en memoire le lien)
      this.props.updatePath(match.url)
      return true
    }
    //si aucune des conditions précédentes est rempli on verif l'ancien lien en mémoire (old redux)
    return((!this.props.navigation.path && to === '/' ) || this._checkPathNameRedux(to))
  }

  _checkPathNameRedux = (pathname) => {
    const  {path} = this.props.navigation;
    if(path === "/"){
      return false
    }
    return(`/${pathname.split('/')[1]}` === path)

  }

  render() {
    const { account,t } = this.props
    return (
      <div className={(account.isForwork || account.isValidSector) ? 'ins_navigation five' : 'ins_navigation four'}>
        <NavLink 
          exact 
          to={{
            pathname: "/",
            state: { transitionName: "fade_app" }
          }} 
          className={window.location.pathname === '/' ? 'home_link active' : 'home_link'}
        >
          home
        </NavLink>
        <NavLink 
          to={{
            pathname: "/brands",
            state: { transitionName: "fade_app" }
          }} 
          className={window.location.pathname === '/brands' ? 'ins_brands active' : 'ins_brands'}
          onClick={() => {this.props.updatePathBrand(true)}} 
        >
          {t('common:brands')}
        </NavLink>
        <NavLink 
          to={{
            pathname: "/formations",
            state: { transitionName: "fade_app" }
          }}
          className={window.location.pathname === '/formations' ? 'ins_formations active' : 'ins_formations'}
          onClick={() => {this.props.updatePathBrand(false)}}   
        >
          {t('common:formations')}
        </NavLink>
        <NavLink 
          to={{
            pathname: "/wall",
            state: { transitionName: "fade_app" }
          }} 
          className={window.location.pathname === '/wall' ? 'ins_wall active' : 'ins_wall'}
        >
          wall
        </NavLink>

        {
            (account.isForwork || account.isValidSector) &&
          <NavLink 
            to={{
              pathname: "/shop",
              state: { transitionName: "fade_app" }
            }} 
            className={window.location.pathname === '/shop' ? 'ins_shop active' : 'ins_shop'}
            >
            vitrine
          </NavLink>
        }
        
      </div>
    )
  }
}

//extract data from the redux store
const mapStateToProps = (state) => ({
  navigation: state.navigation,
  account: state.account
})

//Au changement de path on envoie l'action du updataPath avec le path actuel au store redux
const mapDispatchToProps  = (dispatch) => {
  return {
    updatePath: (path) => { dispatch(actions.updatePath(path)) },
    updatePathBrand: (bool) => { dispatch(actions.updatePathBrand(bool)) }, 
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InsNavigation))
