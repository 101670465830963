import React, { Component } from 'react'
import images from '../../ressources/images'
import { withTranslation } from 'react-i18next'

class NotConnected extends Component {
  render() {
    const style = {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "70%",
      height: "100vh",
      textAlign: "center"  
    }

    const textStyle = {
      fontSize: "2rem"
    }

    const {t} = this.props

    return (
      <div style={style}>
        <img src={images.we_are_lol_logo_event} alt=""/>
        <p style={textStyle}>{t('common:not_connected')}</p>
      </div>
    )
  }
}

export default withTranslation()(NotConnected)
