import React, { Component, useEffect, useState } from "react";

export default class LazyLoadBackground extends Component {
  state = {
    src: null,
  };

  componentDidMount() {
    const { src } = this.props;

    const imageObserver = new IntersectionObserver((entries, observer) => {
      const target = entries[0];

      if (target.isIntersecting) {
        const imageLoader = new Image();

        imageLoader.src = src;
        imageLoader.onload = () => {
          this.setState({ src });
        };
        imageObserver.unobserve(this.loadingRef);
      }
    });

    imageObserver.observe(this.loadingRef);
  }

  render() {
    return (
      <div
        ref={(loadingRef) => (this.loadingRef = loadingRef)}
        {...this.props}
        style={{
          height: this.props.height,
          backgroundImage: `url(${this.state.src || ""})`,
        }}
      ></div>
    );
  }
}
