import React, { Component } from 'react'
import './BrandContent.scss'
import { Link } from "react-router-dom"
import BrandlistMenu from '../../../../components/Brands/BrandlistMenu/BrandlistMenu';
import FormationItem from '../../../../components/FormationItem/FormationItem';
import InsNavigation from '../../../../components/InsNavigation/InsNavigation';
import { getCourseByBrands } from '../../../../api/brands';
import { getBrandById } from '../../../../api/brands';
import PropTypes from 'prop-types'
import images from '../../../../ressources/images';
import InsCGUBar from '../../../../components/InsCGUBar/InsCGUBar';

export class BrandFormation extends Component {

  static propTypes = {
    logo: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.state = { 
      whoActive: 'formations',
      dataFormation: [],
      idFormation: 0,
      dataBrand: []
    };
  }

  UNSAFE_componentWillMount(){
    let brandId = document.location.href.split('/');
    brandId = brandId[brandId.length-1];
    this.setState({idFormation:brandId});
  }

  componentDidMount(){
    this.getAllCourseByBrands();
    this.getBrandByIdFront();
  }

  /**
   * Récupere toutes les marques disponible
   */
  getAllCourseByBrands = () => {
    const {idFormation} = this.state
    getCourseByBrands(idFormation).then((data) =>{
      this.setState({dataFormation: data})
    })
  }

  /**
   * Récupere la marque par id
   */
  getBrandByIdFront = () => {
    const {idFormation} = this.state
    getBrandById(idFormation).then((data) =>{
      this.setState({dataBrand: data})
    })
  }

  

  render() {
    const {dataBrand, dataFormation, idFormation, whoActive} = this.state
    return (
      <>
      <div className="white--wrapper"> 
        <div>
          <Link className='brandlist_backlink' to={{ pathname: '/brands', state: { transitionName: "slide_in" }}}>
            <img src={images.arrow_left_black} alt="" />
          </Link>
          <div className="brandlist_content">
            <div className='flex-row-center'>
              <div className="brand_logo">
                <img className="brandlist_logo" src={process.env.REACT_APP_URL+dataBrand.logoNoir} alt="logo" />
              </div>
            </div>
            <BrandlistMenu whoActive={whoActive} brandId={idFormation}/>
            <div className="brand_contentlist">
              
              { dataFormation.length > 0 ?
                dataFormation.map((course,index) =>
                  <FormationItem key={index} brand={course.brand} image={course.picture} title={course.title} formation={course.type} time={course.time} points={course.points} idFormation={course.id} pourcent={course.pourcent} />
                ) :
                <div className="back_soon">
                  <div>Professeur absent…</div>
                  <div>revenez bientôt </div>
                  <span className="smile" role="img" aria-label="">&#128578;</span>
                </div>
              }
              <InsCGUBar/>
            </div>
            <InsNavigation/>
            
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default BrandFormation
